import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import {
  // createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

import firebase from './firebase'
import AuthRoute from './utils/AuthRoute'
import NotLoginLayout from './components/NotLoginLayout'
import ProjectListLayout from './components/ProjectListLayout'
import ProjectLayout from './components/ProjectLayout'
import LoginPage from './pages/LoginPage'
import ForgotPassPage from './pages/ForgotPassPage'
import NewPass from './pages/NewPass'
import Project from './pages/Project'
import PageDetail from './pages/PageDetail'
import ErrorPage from './pages/ErrorPage'
import NoProject from './pages/List/NoProject'
import CreateProjectList from './pages/ProjectList/CreateProjectList/CreateProjectList'
import EditProjectList from './pages/ProjectList/EditProjectList'
import List from './pages/List'
import ListTable from './pages/ProjectList/ListTable'
import ProjectPageTable from './pages/ProjectPageTable/ProjectPageTable'
import CreateFormPage from './pages/CreateFormPage'
import * as userAction from './redux/actions/user.actions'
import FirebaseUser from './utils/FirebaseUser/FirebaseUser'
import './App.scss'
import UserManagement from './pages/UserManagementPage/UserManagement'
import MediaPage from './pages/MediaPage'
import Categories from './pages/Categories'
import tagList from './pages/TagList'

const font = "'DM Sans', sans-serif"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fc4c02',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: font,
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#000000',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
  },
})

function App() {
  const dispatch = useDispatch()

  const initAuthListener = () => {
    firebase.auth().onAuthStateChanged((userData) => {
      if (userData) {
        FirebaseUser.getCurrentUser(userData.uid)
          .then((result) => {
            dispatch(userAction.loginSuccess(userData, result))
          }).catch((err) => {
            dispatch(userAction.getErrorMessageUser(err))
          })
      } else {
        dispatch(userAction.logout('Not found user login fail'))
      }
    })
  }

  useEffect(() => {
    initAuthListener()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <AuthRoute exact path="/" layout={ProjectListLayout} page={List} loginRequired />
          <AuthRoute exact path="/user-management" layout={ProjectListLayout} page={UserManagement} loginRequired />
          <AuthRoute exact path="/user-management/:tab" layout={ProjectListLayout} page={UserManagement} loginRequired />
          <AuthRoute exact path="/project/:projectId/page/:pageId" layout={ProjectLayout} page={PageDetail} loginRequired />
          <AuthRoute exact path="/project/:projectId/page/:pageId/edit" layout={ProjectLayout} page={CreateFormPage} loginRequired />
          <AuthRoute exact path="/project/:projectId/list/:listId" layout={ProjectLayout} page={ListTable} loginRequired />
          <AuthRoute exact path="/project/:projectId/list/:listId/edit" layout={ProjectLayout} page={CreateFormPage} loginRequired />
          <AuthRoute exact path="/project/:projectId" layout={ProjectLayout} page={Project} loginRequired />
          <AuthRoute exact path="/project/:projectId/page-table" layout={ProjectLayout} page={ProjectPageTable} loginRequired />
          <AuthRoute exact path="/project/:projectId/create-form" layout={ProjectLayout} page={CreateFormPage} loginRequired />
          <AuthRoute exact path="/project/:projectId/categories" layout={ProjectLayout} page={Categories} loginRequired />
          <AuthRoute exact path="/project/:projectId/:type" layout={ProjectLayout} page={MediaPage} loginRequired />
          <AuthRoute exact path="/project/:projectId/tags/:listId" layout={ProjectLayout} page={tagList} loginRequired />
          <AuthRoute exact path="/project/:projectId/list/:listId/create" layout={ProjectLayout} page={CreateProjectList} loginRequired />
          <AuthRoute exact path="/project/:projectId/list/:listId/edit/:uid" layout={ProjectLayout} page={EditProjectList} loginRequired />
          <AuthRoute exact path="/forgotpass" layout={NotLoginLayout} page={ForgotPassPage} loginRequired={false} />
          <AuthRoute exact path="/newpass" layout={NotLoginLayout} page={NewPass} loginRequired={false} />
          <AuthRoute exact path="/login" layout={NotLoginLayout} page={LoginPage} loginRequired={false} />
          <AuthRoute exact path="/no-project" layout={NotLoginLayout} page={NoProject} loginRequired />
          <Route path="*" exact component={() => (<NotLoginLayout><ErrorPage /></NotLoginLayout>)} />
          {/* 404 is cannot change path after login make code in function form */}
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App
