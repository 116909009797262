import React, { useState } from 'react'
import _map from 'lodash/map'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import DeleteIcon from '@material-ui/icons/Delete'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import _isEmpty from 'lodash/isEmpty'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { v1 as uuidv1 } from 'uuid'
import imageCompression from 'browser-image-compression'

import firebase from '../../../firebase'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  media: {
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
  },
  bgImg: {
    maxWidth: '100%',
    maxHeight: '720px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  actionArea: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      gap: '8px',
    },
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalCard: {
    overflow: 'scroll',
    position: 'relative',
    width: '80vw',
    minHeight: '320px',
    height: '100%',
  },
  modalCardMedia: {
    height: '150px',
    width: '100%',
    borderRadius: '4px',
    '&:hover': {
      opacity: '0.8',
    },
  },
  cardNavBar: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    background: '#fff',
    borderBottom: '1px solid lightgray',
  },
  modalContent: {
    maxHeight: '50vh',
    overflowY: 'scroll',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
    width: '100%',
    background: '#fff',
    borderTop: '1px solid lightgray',
  },
}))

async function postImage(projectId, file) {
  const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 4000,
    useWebWorker: true,
  }

  const storageRef = firebase.storage().ref()
  const name = file.name.split('.').slice(0, -1)[0]
  const extension = file.name.split('.').slice(-1)[0]
  const filename = `${_kebabCase(name)}.${extension}`

  const fileRef = storageRef.child(`${projectId}/${uuidv1()}-${filename}`)
  try {
    const compressedFile = await imageCompression(file, options)
    const snapshot = await fileRef.put(compressedFile)
    const downloadURL = snapshot.ref.getDownloadURL()
    return downloadURL
  } catch (error) {
    console.log(error)
    return ''
  }
}

function UploadImageEditor(props) {
  const {
    projectId,
    removeFunc,
    // updateUrl,
    placeholder,
    label,
    img,
    order,
    width,
    maxWidth,
    height,
    handleOnChange,
    disabled,
  } = props
  const classes = useStyles()

  // have to use redux instead
  const [url, setURL] = React.useState(img)
  const [imageLists, setImageLists] = useState([])
  const [modal, setModal] = useState(false)
  const [selectImg, setSelectImg] = useState('')

  const [loading, setLoading] = React.useState(false)

  const handleSelect = async (event) => {
    const file = event.target.files[0]
    setLoading(true)
    try {
      const imgUrl = await postImage(projectId, file)
      setURL(imgUrl)
      handleOnChange(imgUrl)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setURL('')
      setLoading(false)
    }
  }
  const handleSetImage = async () => {
    setLoading(true)
    try {
      setURL(selectImg)
      handleOnChange(selectImg)
      setLoading(false)
      setModal(false)
    } catch (error) {
      console.log(error)
      setURL('')
      setLoading(false)
    }
  }
  const handleOnRemove = () => {
    setURL('')
    removeFunc('')
  }

  const getAllImage = async () => {
    const storageRef = await firebase.storage().ref(`${projectId}`)
    const response = await storageRef.listAll()
    const imgList = []
    response.items.forEach((data) => {
      imgList.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          const mediaUrl = await data.getDownloadURL()
          const metadata = await data.getMetadata()
          resolve({
            url: mediaUrl,
            timeCreated: new Date(metadata?.timeCreated),
            fileType: metadata?.contentType,
            fileName: metadata?.name,
          })
        }),
      )
    })
    const result = await Promise.all(imgList)
    return result.sort((x, y) => y?.timeCreated - x?.timeCreated)
  }

  const handleChooseImages = async () => {
    setModal(true)
    if (imageLists?.length < 1) {
      const media = await getAllImage()
      setImageLists(media)
    }
  }

  return (
    <Box mb={3}>
      {!_isEmpty(label) && (
        <Box mb={1} className={classes.titleBox}>
          <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
          <Tooltip arrow title={placeholder} placement="right">
            <ErrorOutlineIcon color="disabled" />
          </Tooltip>
        </Box>
      )}
      <Box mb={2}>
        {url ? (
          <CardMedia
            className={classes.media}
            component="img"
            src={url}
            title={_startCase(_lowerCase(label))}
            style={{
              maxWidth: maxWidth || '100%',
            }}
          />
        ) : (
          <Box
            bgcolor="grey.300"
            width={width || 1}
            height={height}
            className={classes.bgImg}
          >
            <Typography variant="body1">
              {`${width} X ${height}`}
            </Typography>
          </Box>
        )}
      </Box>
      <CardActions className={classes.actionArea}>
        <label htmlFor={_camelCase(`${label}${order}`)}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={_camelCase(`${label}${order}`)}
            name={_camelCase(`${label}${order}`)}
            type="file"
            disabled={loading}
            onChange={handleSelect}
          />
          <Button
            variant="contained"
            color="default"
            component="div"
            endIcon={<CloudUploadIcon />}
            htmlFor={_camelCase(`${label}${order}`)}
            disabled={loading || disabled}
          >
            Upload New Picture
          </Button>
        </label>
        {projectId !== 'logoStore' && (
          <Button
            variant="contained"
            color="default"
            component="div"
            endIcon={<CloudUploadIcon />}
            htmlFor={_camelCase(`${label}${order}`)}
            onClick={handleChooseImages}
          >
            Choose from Media Library
          </Button>
        )}
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modal}>
            <Box m={5}>
              <Card className={classes.modalCard}>
                <Box
                  padding={2}
                  display="flex"
                  justifyContent="space-between"
                  className={classes.cardNavBar}
                >
                  <Typography variant="h5">Media</Typography>
                </Box>
                <Box
                  padding={3}
                  className={classes.modalContent}
                >
                  <Grid container spacing={2}>
                    {_map(imageLists, (item, i) => (
                      <Grid key={`media${i}`} item xs={12} sm={4} md={3} lg={2}>
                        <CardActionArea
                          onClick={() => setSelectImg(item?.url)}
                        >
                          <CardMedia
                            image={item?.url}
                            component="img"
                            className={classes.modalCardMedia}
                            style={{
                              outline: selectImg === item?.url && '3px solid #fc4c02',
                            }}
                          />
                        </CardActionArea>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box
                  padding={2}
                  display="flex"
                  justifyContent="flex-end"
                  className={classes.footer}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!selectImg}
                    onClick={handleSetImage}
                  >
                    Submit
                  </Button>
                </Box>
              </Card>
            </Box>
          </Fade>
        </Modal>
        {url && (
          <Button
            variant="contained"
            color="default"
            endIcon={<DeleteIcon />}
            disabled={loading || disabled}
            onClick={handleOnRemove}
          >
            Delete
          </Button>
        )}
      </CardActions>
    </Box>
  )
}

UploadImageEditor.propTypes = {
  label: PropTypes.string,
  img: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  removeFunc: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  order: PropTypes.number,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.number,
  placeholder: PropTypes.string,
}

UploadImageEditor.defaultProps = {
  order: 0,
  disabled: false,
  label: '',
  maxWidth: null,
  placeholder: 'Image Upload',
}

export default UploadImageEditor
