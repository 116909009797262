import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'
import _camelCase from 'lodash/camelCase'
import _size from 'lodash/size'
import Divider from '@material-ui/core/Divider'
import _get from 'lodash/get'
import _map from 'lodash/map'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import NotArrayFields from '../GenFieldForm/NotArrayFields'
import CreateDefaultInputValue from '../GenFieldForm/createDefaultInputValue'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
  },
  row: {
    backgroundColor: '#f1f1f1',
    borderRadius: 4,
  },
  divider: {
    marginLeft: -24,
    marginRight: -24,
    backgroundColor: 'rgba(0, 0, 0, 0.23)',
  },
  delete: {
    marginLeft: -12,
    marginTop: -12,
    marginBottom: 12,
  },
}))

function Array(props) {
  const classes = useStyles()

  const {
    projectId,
    lang,
    label,
    array,
    placeholder,
    values,
    handleOnChange,
    selectLang,
  } = props

  const fields = _get(array, 'schema')
  const defaultInputValue = CreateDefaultInputValue(fields, lang)

  const handleChangeObject = (data, objKey, fieldLabel, arrIndex) => {
    const newData = [...values]

    newData[arrIndex] = {
      ...newData[arrIndex],
      [fieldLabel]: {
        ...newData[arrIndex][fieldLabel],
        [objKey]: data,
      },
    }
    handleOnChange(newData, label)
  }

  const handleChangeString = (data, fieldLabel, arrIndex) => {
    const newData = [...values]

    newData[arrIndex] = {
      ...newData[arrIndex],
      [fieldLabel]: data,
    }
    handleOnChange(newData, label)
  }

  const handleChangeNumber = (data, fieldLabel, arrIndex) => {
    const newData = [...values]

    newData[arrIndex] = {
      ...newData[arrIndex],
      [fieldLabel]: data,
    }
    handleOnChange(newData, label)
  }

  const handleChangeBoolean = (data, fieldLabel, arrIndex) => {
    const newData = [...values]

    newData[arrIndex] = {
      ...newData[arrIndex],
      [fieldLabel]: data,
    }
    handleOnChange(newData, label)
  }

  const handleAdd = () => {
    const newData = [...values, defaultInputValue]
    handleOnChange(newData, label)
  }

  const handleDelete = (arrIndex) => {
    const newData = [...values.slice(0, arrIndex), ...values.slice(arrIndex + 1)]
    handleOnChange(newData, label)
  }

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <Box mb={1}>
        <Typography className={classes.title} variant="body1">{_startCase(placeholder)}</Typography>
      </Box>
      <Box px={{ xs: 2, md: 3 }} pt={{ xs: 1, md: 2 }} pb={1} className={classes.row}>
        {_map(values, (value, vindex) => (
          <React.Fragment key={`key${vindex}`}>
            {vindex > 0 && (
              <Box mb={3}>
                <Divider className={classes.divider} />
              </Box>
            )}
            {_map(fields, ({
              label: arrLabel,
              placeholder: arrPlaceholder,
              type,
              width,
              height,
              choices,
            }) => (
              <React.Fragment key={_camelCase(`${arrLabel}${vindex}`)}>
                {type !== 'array' && (
                  <NotArrayFields
                    projectId={projectId}
                    lang={lang}
                    value={_get(value, `${_camelCase(arrLabel)}`)}
                    order={vindex}
                    selectLang={selectLang}
                    handleChangeObject={(data, objKey, fieldLabel) => handleChangeObject(
                      data, objKey, _camelCase(fieldLabel), vindex,
                    )}
                    handleChangeString={(data, fieldLabel) => {
                      handleChangeString(data, _camelCase(fieldLabel), vindex)
                    }}
                    handleChangeNumber={(data, fieldLabel) => handleChangeNumber(
                      data, _camelCase(fieldLabel), vindex,
                    )}
                    handleChangeBoolean={(data, fieldLabel) => handleChangeBoolean(
                      data, _camelCase(fieldLabel), vindex,
                    )}
                    label={arrLabel}
                    placeholder={arrPlaceholder}
                    type={type}
                    width={width}
                    height={height}
                    choices={choices}
                  />
                )}
              </React.Fragment>
            ))}
            {_size(values) > 1 && (
              <Box className={classes.delete}>
                <IconButton onClick={() => handleDelete(vindex)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box mt={2}>
        <Button color="primary" variant="contained" onClick={handleAdd}>
          Add
        </Button>
      </Box>
    </Box>
  )
}

Array.propTypes = {
  label: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  lang: PropTypes.arrayOf(PropTypes.string).isRequired,
  array: PropTypes.objectOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  selectLang: PropTypes.string,
}

Array.defaultProps = {
  placeholder: '',
  selectLang: '',
}

export default Array
