import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
})

function DatePickerForm(props) {
  const {
    classes,
    label,
    value,
    handleOnChange,
  } = props
  const [selectedDate, setSelectedDate] = React.useState(value ? new Date(value) : new Date())

  const handleDateChange = (date) => {
    handleOnChange(date.getTime())
    setSelectedDate(date)
  }

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography
          className={classes.title}
          variant="h6"
        >
          {_startCase(_lowerCase(label))}
        </Typography>
      </Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          inputVariant="outlined"
          clearable
          value={selectedDate}
          onChange={handleDateChange}
          format="dd/MM/yyyy HH:mm"
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}

DatePickerForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(DatePickerForm)
