import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  dialogContent: {
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
}))

function CreateCompany(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    mode,
    onChange,
    data,
    loading,
    handleSubmit,
  } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5" component="div">
          {mode === 'create' ? 'Create Company' : 'Edit Company'}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mt={1} mb={2}>
          {loading ? (
            <Skeleton variant="text" height={56} />
          ) : (
            <TextField
              variant="outlined"
              label="Name"
              name="name"
              value={data.name}
              onChange={(e) => onChange(e.target.name, e.target.value)}
              className={classes.textField}
              required
              fullWidth
            />
          )}
        </Box>
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {loading ? <Skeleton width={50} height={15} variant="text" /> : 'Status'}
            </FormLabel>
            {loading ? <Skeleton width={100} height={40} variant="text" /> : (
              <FormControlLabel
                control={(
                  <Switch
                    checked={data.status}
                    onChange={(e) => onChange(e.target.name, e.target.checked)}
                    name="status"
                    color="primary"
                  />
                )}
                label="Active"
              />
            )}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading || _isEmpty(data.name)}
        >
          {mode === 'create' ? mode : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateCompany.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  mode: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

CreateCompany.defaultProps = {
  mode: 'create',
  loading: false,
}

export default CreateCompany
