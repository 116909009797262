import _get from 'lodash/get'
import _size from 'lodash/size'
import _kebabCase from 'lodash/kebabCase'
import _isEmpty from 'lodash/isEmpty'

export const SET_PROJECT = 'SET_PROJECT'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const LOADING = 'LOADING'

export function setProject(projectData, history, urlParams) {
  const projectId = _get(projectData, 'uid')
  const pages = _get(projectData, 'schema.page', [])
  const lists = _get(projectData, 'schema.list', [])

  const pageOrList = _size(pages) > 0 ? 'page' : 'list'

  const firstPageUrl = _kebabCase(pages?.[0]?.name)
  const firstListUrl = _kebabCase(lists?.[0]?.name)

  const finalURL = firstPageUrl || firstListUrl
  // URL params
  const pageId = _get(urlParams, 'pageId')
  const listId = _get(urlParams, 'listId')
  const isInProject = !_isEmpty(pageId) || !_isEmpty(listId)
  if (!isInProject) {
    if (urlParams?.type === 'media-page') {
      history.replace(`/project/${projectId}/media-page`)
    } else if (pages.length || lists.length) {
      history.replace(`/project/${projectId}/${pageOrList}/${finalURL}`)
    } else {
      history.replace(`/project/${projectId}/create-form`)
    }
  }
  return {
    type: SET_PROJECT,
    payload: {
      projectData,
    },
  }
}

export function getErrorMessageProject(errorMessage) {
  return {
    type: ERROR_MESSAGE,
    payload: {
      errorMessage,
    },
  }
}

export function updateStatusLoading(newLoading) {
  return {
    type: LOADING,
    payload: {
      newLoading,
    },
  }
}
