import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Logo from './logo.png'
import s from './Footer.module.scss'

function Copyright(props) {
  const { color } = props

  return (
    <div className={clsx(s.footer, color === 'white' ? s.white : s.black)}>
      <Typography variant="body2" align="center" className={s.text}>
        {`${new Date().getFullYear()} © Powered by `}
      </Typography>
      <a href="https://foxbith.com/" className={s.link} target="_blank" rel="noopener noreferrer">
        <img className={s.logo} src={Logo} alt="Foxbith Co., Ltd." />
      </a>
    </div>
  )
}

Copyright.propTypes = {
  color: PropTypes.string,
}

Copyright.defaultProps = {
  color: 'black',
}

export default Copyright
