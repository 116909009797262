/* eslint-disable no-use-before-define */
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
  },
}))

function Tags(props) {
  const classes = useStyles()

  const {
    label,
    placeholder,
    value,
    handleOnChange,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <div className={classes.root}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={[].map((option) => option)}
          value={value.map((option) => option)}
          freeSolo
          renderTags={(arr, getTagProps) => (
            arr.map((option, index) => (
              <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
            />
          )}
          onChange={(_, arrValue) => handleOnChange(arrValue)}
        />
      </div>
    </Box>
  )
}

Tags.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export default Tags
