import React, {
  useState,
  useEffect,
} from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import format from 'date-fns/format'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _startCase from 'lodash/startCase'
import Chip from '@material-ui/core/Chip'
import firebase from '../../../firebase'

const useStyles = makeStyles({
  active: {
    color: 'green',
    borderColor: 'green',
  },
  inactive: {
    color: 'red',
    borderColor: 'red',
  },
})

const useFetchAllCompany = (
  query, page, rowsperpage, order, orderBy, findex, lindex, direction,
) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [numberOfDocs, setNumberOfDocs] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        let ref = firebase.firestore()
          .collection('company')
          .orderBy(orderBy, order)
        if (!_isEmpty(direction) && !_isEmpty(findex) && !_isEmpty(lindex)) {
          const cursorId = direction === 'after' ? lindex : findex
          const cursor = await firebase.firestore().collection('company').doc(cursorId).get()
          ref = direction === 'after' ? (
            ref.startAfter(cursor).limit(rowsperpage)
          ) : (
            ref.endBefore(cursor).limitToLast(rowsperpage)
          )
        } else {
          ref = ref.limit(rowsperpage)
        }

        const snapshots = await ref.get()
        const output = _map(snapshots.docs, (item) => {
          const obj = item.data()
          const status = (
            <Chip
              label={_get(obj, 'status') ? 'Active' : 'Inactive'}
              variant="outlined"
              className={clsx(_get(obj, 'status') ? classes.active : classes.inactive)}
            />
          )

          const updateAt = format(
            new Date(
              _get(obj, 'updateAt.seconds') * 1000,
            ),
            'yyyy/MM/dd HH:mm',
          )

          return {
            uid: item.id,
            name: _startCase(_get(obj, 'name')),
            status,
            updateAt,
          }
        })

        let numberData = await firebase
          .firestore()
          .collection('collectionData')
          .doc('company')
          .get()
        if (numberData.exists) {
          numberData = numberData.data()
          numberData = _get(numberData, 'number')
          setNumberOfDocs(numberData)
        }

        setData(output)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }

    if (query) {
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    rowsperpage,
    order,
    orderBy,
    page,
  ])

  return {
    loading,
    rows: data,
    numberOfDocs,
  }
}

const useFetchACompany = (companyId) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: '',
    status: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const snapshot = await firebase.firestore()
          .collection('company')
          .doc(companyId)
          .get()

        let output = {
          name: '',
          status: false,
        }
        if (snapshot.exists) {
          const docData = snapshot.data()
          output = {
            name: _get(docData, 'name', ''),
            status: _get(docData, 'status', false),
          }
        }

        setData(output)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }

    if (_isEmpty(companyId)) {
      setData({
        name: '',
        status: false,
      })
    } else {
      fetchData()
    }
  }, [companyId])

  return {
    loadingCompany: loading,
    companyData: data,
    setCompanyData: setData,
  }
}

const useSaveACompany = (saveType, data, uid) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState({
    code: '',
    message: '',
  })

  useEffect(() => {
    if (_isEmpty(saveType)) return

    const saveData = async () => {
      setLoading(true)
      const ref = firebase.firestore()
        .collection('company')
      const saveObj = {
        ...data,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
      }

      try {
        if (saveType === 'create' && _isEmpty(uid)) {
          await ref.add({
            ...saveObj,
            createAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
        } else {
          await ref.doc(uid).set(saveObj, { merge: true })
        }
        setStatus({
          code: 200,
          message: 'Save successfully.',
        })
      } catch (error) {
        setStatus({
          code: 500,
          message: error,
        })
      } finally {
        setLoading(false)
      }

      setLoading(false)
    }
    saveData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveType])

  return {
    loadingSave: loading,
    saveStatus: status,
    setStatus,
  }
}

export {
  useFetchAllCompany,
  useFetchACompany,
  useSaveACompany,
}
