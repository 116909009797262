import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _toLower from 'lodash/toLower'
import _includes from 'lodash/includes'
import _orderBy from 'lodash/orderBy'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import TextFeild from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import AllCategories from '../../components/Categories'
import firebase from '../../firebase'

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px',
    height: '100%',
    minHeight: '400px',
    maxHeight: '800px',
  },
  gridContainer: {
    width: '100%',
    height: '100%',
  },
  centerBox: {
    height: '100%',
    padding: '16px',
  },
  typography: {
    marginBottom: '16px',
  },
  textfield: {
    marginBottom: '16px',
  },
  helpertext: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 0,
  },
}))

export default function Categories(props) {
  const classes = useStyles()
  const { computedMatch } = props
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [delCategory, setDelCategory] = useState('')
  const [duplicate, setDuplicate] = useState(false)
  const projectId = _get(computedMatch, 'params.projectId')
  const database = firebase.firestore()
  const categoriesRef = database.collection('projects').doc(projectId).collection('categories')

  const handleTextField = (value, type) => {
    switch (type) {
      case 'Name':
        setName(_toLower(value))
        break
      case 'Description':
        setDesc(value)
        break
      default:
        break
    }
  }

  const getCategories = async () => {
    const categories = []
    setLoading(true)
    try {
      const response = await categoriesRef.get()
      response.docs.forEach((res) => {
        categories.push({
          id: res?.id,
          name: res?.data().name,
          createdAt: res?.data().createdAt,
          description: res?.data().description,
        })
      })
      const sortedCategories = _orderBy(categories, 'createdAt', 'desc')
      setCategory(sortedCategories)
    } catch (error) {
      setCategory([])
      console.log('get categories error!', error)
    } finally {
      setLoading(false)
    }
  }

  const addCategory = async () => {
    setLoading(true)
    try {
      await categoriesRef.add({
        name,
        description: desc,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true })
      await getCategories()
      setName('')
      setDesc('')
    } catch (error) {
      console.log('add category error!', error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddCategory = () => {
    const categoryName = category.map((value) => value?.name)
    if (_includes(categoryName, `${name}`)) {
      setDuplicate(true)
    } else {
      addCategory()
    }
  }

  const deleteCategory = async () => {
    setLoading(true)
    try {
      await categoriesRef.doc(delCategory).delete()
    } catch (error) {
      console.log('delete category error!', error)
    } finally {
      setTimeout(() => {
        getCategories()
      }, 800)
    }
  }

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Typography variant="h4" className={classes.typography}>Categories</Typography>
      <Card className={classes.card}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item sm={4} md={5}>
            <Box className={classes.centerBox}>
              <Typography
                variant="h6"
                className={classes.typography}
              >
                Add New Category
              </Typography>
              <TextFeild
                fullWidth
                size="small"
                label="Name*"
                value={name}
                error={duplicate}
                variant="outlined"
                className={classes.textfield}
                inputProps={{ maxLength: 20 }}
                FormHelperTextProps={{ className: classes.helpertext }}
                onChange={(event) => handleTextField(event.target.value, 'Name')}
                helperText={duplicate ? 'Already exists' : `${name.length}/20 Characters`}
              />
              <TextFeild
                rows={3}
                multiline
                fullWidth
                value={desc}
                size="small"
                label="Description (Optional)"
                variant="outlined"
                className={classes.textfield}
                inputProps={{ maxLength: 50 }}
                helperText={`${desc.length}/50 Characters`}
                FormHelperTextProps={{ className: classes.helpertext }}
                onChange={(event) => handleTextField(event.target.value, 'Description')}
              />
              <Button
                size="small"
                color="primary"
                disabled={!name || loading}
                variant="contained"
                onClick={handleAddCategory}
              >
                Add New Category
              </Button>
            </Box>
          </Grid>
          <AllCategories
            projectId={projectId}
            categories={category}
            setDelCategory={setDelCategory}
            handleDelete={deleteCategory}
          />
        </Grid>
      </Card>
    </Box>
  )
}

Categories.propTypes = {
  computedMatch: PropTypes.shape({}),
}
Categories.defaultProps = {
  computedMatch: {},
}
