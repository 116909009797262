import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import _toString from 'lodash/toString'
import Lottie from 'lottie-react-web'
import {
  withStyles,
} from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Button,
  Box,
} from '@material-ui/core'
import loadingButton from '../../components/LottieFiles/loading_button.json'
import firebase from '../../firebase'
import * as alertAction from '../../redux/actions/alert.actions'
import AlertBar from '../../components/NoticeMessage/SnackBar'

import s from './NewPass.module.scss'

const CssTextField = withStyles({
  root: {
    '& label': {
      color: '#fff',
    },
    '& label.Mui-focused': {
      color: '#fc4c02',
    },
    '& input': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fc4c02',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fc4c02',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fc4c02',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField)

const CssButton = withStyles({
  root: {
    '&$disabled': {
      color: '#fff',
      background: '#fc4c02',
    },
  },
  disabled: {},
})(Button)

function NewPass() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  // const [submit, setSubmit] = useState('button')
  const [loading, setLoading] = useState(true)
  // const [disableInput, setDisableInput] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const actionCode = urlParams.get('oobCode')
  const dispatch = useDispatch()
  // console.log(actionCode)

  const newPassSubmit = async (e) => {
    e.preventDefault()
    setLoading(false)
    if (actionCode !== null) {
      if (password === confirmPassword) {
        try {
          await firebase.auth().confirmPasswordReset(actionCode, password)
          dispatch(alertAction.showMessage('success', 'Your password has been changed successfully. Please use it for logging in next time.'))
          // setDisableInput(true)
          setTimeout(() => {
            window.location = '/'
          }, 2500)
        } catch (error) {
          if (error) {
            dispatch(alertAction.showMessage('error', _toString(error)))
            document.getElementById('password').focus()
          }
        }
      } else {
        dispatch(alertAction.showMessage('error', 'Password not match !!!'))
        document.getElementById('password').focus()
      }
    } else {
      dispatch(alertAction.showMessage('error', 'You cant access this page please access in email'))
    }
    setLoading(true)
  }

  return (
    <div className={s.box}>
      <AlertBar />
      <Box mb={4}>
        <Typography variant="h5" className={s.title}>
          Create new password
        </Typography>
      </Box>
      <form onSubmit={newPassSubmit}>
        <CssTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          name="password"
          label="New password"
          type="password"
          autoFocus
          value={password}
          disabled={!loading}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CssTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm password"
          type="password"
          disabled={!loading}
          value={confirmPassword}
          // disabled={disableInput}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Box mt={4}>
          {loading
            ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={s.button}
              >
                Submit
              </Button>
            )
            : (
              <CssButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled
                className={s.button}
              >
                <Box mt={2}>
                  <Lottie
                    width="30%"
                    options={{
                      animationData: loadingButton,
                    }}
                  />
                </Box>
              </CssButton>
            )}
        </Box>
      </form>
    </div>
  )
}

export default NewPass
