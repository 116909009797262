import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DateFnsUtils from '@date-io/date-fns'
import isSameDay from 'date-fns/isSameDay'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateRangeIcon from '@material-ui/icons/DateRange'

function isValidDate(d) {
  return JSON.stringify(d) !== 'null'
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  textField: {
    margin: 0,
    '& .MuiInputBase-root': {
      paddingRight: 6,
      '& input': {
        height: 36,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
        },
      },
    },
    '& button': {
      padding: 4,
    },
  },
}))

export default function MyDateRangePicker(props) {
  const classes = useStyles()
  const [openEnd, setOpenEnd] = useState(false)
  const [openStart, setOpenStart] = useState(false)

  const handleOpenStart = () => {
    setOpenStart(true)
    setOpenEnd(false)
  }

  const handleOpenEnd = () => {
    setOpenEnd(true)
    setOpenStart(false)
  }

  const { onChange, value } = props

  const handleStartDateChange = (date) => {
    onChange({
      start: date,
      end: null,
      error: true,
    })
    setOpenStart(false)
  }

  const handleEndDateChange = (date) => {
    onChange({
      ...value,
      end: date,
    })
    setOpenEnd(false)
  }

  useEffect(() => {
    if (!isValidDate(value.start)) {
      onChange({
        ...value,
        error: true,
      })
    } else if (value.end === null) {
      setOpenEnd(true)
    } else if (!isValidDate(value.end)) {
      onChange({
        ...value,
        error: true,
      })
    } else if (value.start > value.end && !isSameDay(value.start, value.end)) {
      onChange({
        ...value,
        error: true,
      })
    } else {
      onChange({
        ...value,
        error: false,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.start, value.end])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.root}>
        <KeyboardDatePicker
          open={openStart}
          onOpen={handleOpenStart}
          onClose={() => setOpenStart(false)}
          variant="inline"
          fullWidth
          className={classes.textField}
          size="small"
          margin="normal"
          inputVariant="outlined"
          id="start-date"
          label="Start date"
          format="dd/MM/yyyy"
          value={value.start}
          onChange={handleStartDateChange}
          // onError={handleStartError}
          keyboardIcon={<DateRangeIcon />}
          KeyboardButtonProps={{
            'aria-label': 'change start date',
          }}
        />
        <Box mx={{ xs: 0, md: 2 }} my={{ xs: 1, md: 0 }}>
          <Typography variant="body2" color="textPrimary">
            to
          </Typography>
        </Box>
        <KeyboardDatePicker
          minDate={value.start}
          variant="inline"
          open={openEnd}
          onOpen={handleOpenEnd}
          onClose={() => setOpenEnd(false)}
          disabled={!isValidDate(value.start)}
          fullWidth
          className={classes.textField}
          size="small"
          margin="normal"
          inputVariant="outlined"
          id="end-date"
          label="End date"
          format="dd/MM/yyyy"
          value={value.end}
          onChange={handleEndDateChange}
          // onError={handleEndError}
          keyboardIcon={<DateRangeIcon />}
          KeyboardButtonProps={{
            'aria-label': 'change end date',
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

MyDateRangePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.objectOf(PropTypes.any),
}

MyDateRangePicker.defaultProps = {
  onChange: () => {},
  value: { start: null, end: null, error: false },
}
