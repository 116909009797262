import {
  GET_LIST_DATA,
  GET_ROW_DATA,
  LAST_VISIBLE,
  LOAD_TABLE_DATA,
  SET_PAGE,
  SET_VISIBLE_DATA,
  CLEAR_VISIBLE_DATA,
  DELETE_ONE_LIST,
} from '../actions/list.action'
import { ERROR_MESSAGE } from '../actions/user.actions'

const initialState = {
  listData: [],
  rowData: [],
  loadingList: true,
  loadingRow: true,
  loadingPage: true,
  page: 0,
  lastVisible: 0,
  visibleData: 0,
  arrayOfPagination: [],
  deleteDummy: 0,
  errorMessage: [],
}

function listReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TABLE_DATA: {
      return {
        ...state,
        loadingList: true,
        loadingRow: true,
        loadingPage: true,
        arrayOfPagination: [],
        page: 0,
      }
    }
    case GET_LIST_DATA: {
      return {
        ...state,
        listData: action.payload.listData,
        loadingList: false,
      }
    }
    case GET_ROW_DATA: {
      return {
        ...state,
        rowData: action.payload.rowData,
        id: action.payload.id,
        loadingRow: false,
      }
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload.page,
        loadingPage: false,
      }
    }
    case SET_VISIBLE_DATA: {
      return {
        ...state,
        visibleData: action.payload.visibleData,
        arrayOfPagination: [...state.arrayOfPagination, action.payload.visibleData],
      }
    }
    case LAST_VISIBLE: {
      return {
        ...state,
        lastVisible: action.payload.lastVisible,
      }
    }
    case CLEAR_VISIBLE_DATA: {
      return {
        ...state,
        lastVisible: 0,
        visibleData: 0,
        arrayOfPagination: [],
        page: 0,
      }
    }
    case DELETE_ONE_LIST: {
      return {
        ...state,
        deleteDummy: state.deleteDummy + 1,
      }
    }
    case ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: [...state.errorMessage, action.payload.errorMessage],
      }
    }
    default: {
      return state
    }
  }
}

export default listReducer
