import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import _get from 'lodash/get'
import _map from 'lodash/map'
import Switch from '@material-ui/core/Switch'
import PropTypes from 'prop-types'
import { setForm, setListData, setStoreData } from '../../redux/actions/createform.action'
import {
  getCreateForm, listData, storeData, updateForm,
} from '../../redux/selectors/createform.selector'
import Formcreate from './Formcreate'

const useStyles = makeStyles(() => ({
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  boxRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  lastBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textfield: {
    width: '80%',
  },
  box: {
    width: '100%',
  },
  boxSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  typeBox: {
    width: '100%',
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  lastButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const ArrObjfield = (props) => {
  const classes = useStyles()
  const { index } = props
  const [select, setSelect] = useState('simtext')
  const type = useSelector(getCreateForm)
  const isUpdate = useSelector(updateForm)
  const dataStore = useSelector(storeData)
  const listStore = useSelector(listData)
  const fieldData = _get(type, `[0].pl[0].fields[${index}].array.schema`)
  const fieldValue = _get(type, `[0].pl[0].fields[${index}]`)
  const typeForm = _get(type, '[0].pl[0]')
  const dispatch = useDispatch()
  const handleSelectedField = (e) => {
    setSelect(e.target.value)
  }
  const deleteItem = () => {
    const test = [...type]
    const store = [...dataStore]
    const dataList = [...listStore]
    if (isUpdate) {
      if (test[0].type === 'list') {
        dataList.map((x) => x.splice(index, 1))
        dispatch(setListData(dataList))
      } else {
        store.splice(index, 1)
        dispatch(setStoreData(store))
      }
      test[0].pl[0].fields.splice(index, 1)
      dispatch(setForm(test))
    } else {
      test[0].pl[0].fields.splice(index, 1)
      dispatch(setForm(test))
    }
  }

  const handleType = (field, e) => {
    const test = [...type]
    const fieldInd = test[0].pl[0].fields[index]
    const valueType = e.target.value
    switch (field) {
      case 'label':
        fieldInd.label = valueType
        break
      case 'placeholder':
        fieldInd.placeholder = valueType
        break
      default: break
    }
    dispatch(setForm(test))
  }
  const handleSwitch = (e) => {
    const test = [...type]
    test[0].pl[0].fields[index].isShow = e.target.checked
    dispatch(setForm(test))
  }
  const handleAddFieldSelect = () => {
    const test = [...type]
    const fv = select
    const field = test[0].pl[0].fields[index].array.schema
    if (fv === 'simtext' || fv === 'link' || fv === 'infotext' || fv === 'number' || fv === 'array' || fv === 'richtext' || fv === 'uid' || fv === 'embed' || fv === 'file' || fv === 'boolean' || fv === 'color' || fv === 'date' || fv === 'timestamp' || fv === 'geoPoint') {
      field.push({
        type: fv, placeholder: '', label: '',
      })
    } else if (fv === 'image') {
      field.push({
        type: fv, placeholder: '', label: '', width: '', height: '',
      })
    } else if (fv === 'select') {
      field.push({
        type: fv, placeholder: '', label: '', choices: [''],
      })
    }
    dispatch(setForm(test))
  }
  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.lastBox}>
        <Typography variant="h6">arrayOfObj</Typography>
        <IconButton onClick={deleteItem}>
          <DeleteIcon color="primary" fontSize="small" style={{ color: 'gray' }} />
        </IconButton>
      </Box>
      <Box mt={1} className={classes.boxRow}>
        <Box className={classes.box}>
          <Typography>label:</Typography>
          <TextField variant="outlined" value={fieldValue.label} onChange={(e) => handleType('label', e)} className={classes.textfield} />
        </Box>
        <Box className={classes.box}>
          <Typography>Placeholder:</Typography>
          <TextField variant="outlined" value={fieldValue.placeholder} onChange={(e) => handleType('placeholder', e)} className={classes.textfield} />
        </Box>
      </Box>
      <Box className={`${type[0].type === 'page' ? classes.lastButton : classes.lastBox}`}>
        {type[0].type === 'list' && (
          <Box mt={1} className={classes.boxSwitch}>
            <Typography>isShow:</Typography>
            <Typography style={{ marginLeft: '24px' }}>False</Typography>
            <Switch color="primary" checked={typeForm.fields[index].isShow} onChange={handleSwitch} />
            <Typography>True</Typography>
          </Box>
        )}
      </Box>
      <Box mt={2} bgcolor="whitesmoke" p={3} style={{ borderRadius: '16px' }}>
        {_map(fieldData, (z, i) => {
          const fieldDataKey = `fieldData${i}`
          return (
            <Formcreate key={fieldDataKey} value={z.type} index={index} fieldIndex={i} arrObj />)
        })}
        <Box mt={2} className={classes.typeBox}>
          <Typography variant="h6">Type:</Typography>
          <Select
            style={{ marginLeft: '24px' }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={select}
            variant="outlined"
            onChange={(e) => handleSelectedField(e)}
          >
            <MenuItem value="simtext">simtext</MenuItem>
            <MenuItem value="image">image</MenuItem>
            <MenuItem value="link">link</MenuItem>
            <MenuItem value="infotext">infotext</MenuItem>
            <MenuItem value="number">number</MenuItem>
            <MenuItem value="richtext">richtext</MenuItem>
            <MenuItem value="uid">uid</MenuItem>
            <MenuItem value="embed">embed</MenuItem>
            <MenuItem value="file">file</MenuItem>
            <MenuItem value="boolean">boolean</MenuItem>
            <MenuItem value="color">color</MenuItem>
            <MenuItem value="date">date</MenuItem>
            <MenuItem value="timestamp">timestamp</MenuItem>
            <MenuItem value="geoPoint">geopoint</MenuItem>
            <MenuItem value="select">select</MenuItem>
          </Select>
          <Button size="medium" variant="outlined" color="primary" style={{ marginLeft: '20px' }} onClick={handleAddFieldSelect}>ADD</Button>
        </Box>

      </Box>
      <Box mt={5} />
      <hr
        style={{
          width: '100%', border: 'none', borderBottom: '1px solid gainsboro', boxShadow: 'none',
        }}
      />
    </Box>
  )
}

export default ArrObjfield
ArrObjfield.propTypes = {
  index: PropTypes.number.isRequired,

}
