import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DialogTitle from '@material-ui/core/DialogTitle'
import firebase from 'firebase/app'
import _camelCase from 'lodash/camelCase'
import _toUpper from 'lodash/toUpper'
import _isEmpty from 'lodash/isEmpty'
import _values from 'lodash/values'
import _sum from 'lodash/sum'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import _get from 'lodash/get'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch } from 'react-redux'
import * as alertAction from '../../redux/actions/alert.actions'
import ImageUpload from '../Fields/ImageUpload'
import { useFetchCompanyList } from '../../pages/UserManagementPage/Users/api'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  dialogContent: {
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  cardContentcenter: {
    minHeight: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
  },
}))

export default function ModalProject(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const firebaseRef = firebase.firestore().collection('projects')

  const {
    open,
    onClose,
    mode,
    data,
  } = props

  // Get companies
  const [companyQuery, setCompanyQuery] = useState('')
  const { companyLoading, companyList } = useFetchCompanyList(companyQuery)

  useEffect(() => {
    setCompanyQuery(open ? 'fetch' : 'clear')
  }, [open])

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    name: mode === 'create' ? '' : _get(data, 'name', ''),
    type: mode === 'create' ? 'company' : _get(data, 'type', ''),
    companyId: mode === 'create' ? '' : _get(data, 'companyId', ''),
    logo: mode === 'create' ? '' : _get(data, 'logo', ''),
    url: mode === 'create' ? '' : _get(data, 'url', ''),
    status: mode === 'create' ? true : _get(data, 'status', true),
    lang: mode === 'create' ? ['en'] : _get(data, 'schema.lang', []),
  })

  const [errorObj, setErrorObj] = useState({
    name: false,
    companyId: false,
    lang: false,
  })

  const isProjectUnique = async (projectName) => {
    try {
      const projectSnap = await firebaseRef
        .doc(_camelCase(projectName))
        .get()
      return !projectSnap.exists
    } catch (error) {
      console.log(error)
      return true
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const haveError = _sum(_values(errorObj))
    if (haveError > 0) {
      dispatch(alertAction.showMessage('error', 'You haven\'t put some information yet.'))
      setLoading(false)
      return
    }

    const isUnique = await isProjectUnique(form.name)
    if (mode === 'create' && !isUnique) {
      dispatch(alertAction.showMessage('error', 'This project\'s name already used.'))
      setTimeout(() => {
        dispatch(alertAction.clear())
      }, 5000)
    } else {
      const companyRef = firebase.firestore().collection('company')
      try {
        const docUID = mode === 'create' ? _camelCase(form.name) : _get(data, 'uid')
        const saveData = {
          name: _camelCase(form.name),
          type: form.type,
          companyId: form.type === 'company' ? companyRef.doc(form.companyId.uid) : '',
          logo: form.logo || '',
          url: form.url.trim() || '',
          status: form.status,
          schema: {
            lang: _map(form.lang, (item) => item.toLowerCase()),
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
        if (mode === 'create') {
          saveData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
          saveData.schema.page = []
          saveData.schema.list = []
        }

        await firebaseRef.doc(docUID).set(saveData, { merge: true })
        setForm({
          name: '', type: '', companyId: '', logo: '', url: '', status: true, lang: [],
        })
        onClose()
        dispatch(alertAction.showMessage('success', `The project was ${mode === 'create' ? 'created' : 'edited'}`))
        setTimeout(() => {
          dispatch(alertAction.clear())
          window.location.reload()
        }, 1000)
      } catch (error) {
        console.log(error)
        dispatch(alertAction.showMessage('error', 'Something went wrong, please contact our support team.'))
        setTimeout(() => {
          dispatch(alertAction.clear())
        }, 3000)
      }
    }
    setLoading(false)
  }
  const handleChangForm = (field, newValue) => {
    setForm({
      ...form,
      [field]: newValue,
    })

    if (field === 'name') {
      setErrorObj({
        ...errorObj,
        name: _isEmpty(newValue),
      })
    } else if (field === 'companyId') {
      setErrorObj({
        ...errorObj,
        companyId: form.type === 'company' ? _isEmpty(newValue) : false,
      })
    } else if (field === 'lang') {
      setErrorObj({
        ...errorObj,
        lang: _isEmpty(newValue),
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5" component="div">
          {mode === 'create' ? 'Add Project' : 'Edit Project'}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mb={2} mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
            disabled={loading}
          >
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              id="type-label"
              value={form.type}
              onChange={(e) => handleChangForm('type', e.target.value)}
              label="Age"
            >
              <MenuItem value="company">Company</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="foxbith">Foxbith</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          { form.type === 'company' && (
            <Box mb={2}>
              <Autocomplete
                id="company"
                options={companyList}
                loading={companyLoading}
                getOptionLabel={(option) => _get(option, 'name', '')}
                onChange={(event, newValue) => handleChangForm('companyId', newValue)}
                value={form.companyId}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Company"
                    required
                    error={errorObj.companyId}
                    helperText={errorObj.companyId && 'Company is required.'}
                  />
                )}
              />
            </Box>
          )}
        </Box>
        <Box my={2}>
          <Typography variant="subtitle1">Logo</Typography>
          <ImageUpload
            maxWidth={150}
            width={150}
            height={150}
            projectId="logoStore"
            img={form.logo || ''}
            handleOnChange={(urlImage) => handleChangForm('logo', urlImage)}
            removeFunc={() => handleChangForm('logo', '')}
            disabled={loading}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Name"
            fullWidth
            variant="outlined"
            required
            error={errorObj.name}
            helperText={errorObj.name && 'Name is required.'}
            onChange={(e) => handleChangForm('name', e.target.value)}
            disabled={loading}
            value={form.name}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="URL"
            fullWidth
            variant="outlined"
            onChange={(e) => handleChangForm('url', e.target.value)}
            disabled={loading}
            value={form.url}
          />
        </Box>
        <Box mb={2}>
          <Autocomplete
            multiple
            disabled={loading}
            options={[]}
            defaultValue={['en']}
            freeSolo
            value={form.lang}
            filterSelectedOptions
            onChange={(i, newValue) => {
              const lowercaseAllLang = _map(newValue, (item) => item.toLowerCase())
              handleChangForm('lang', _uniq(lowercaseAllLang))
            }}
            renderTags={(value, getTagProps) => (
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={_toUpper(option)}
                  {...getTagProps({ index })}
                />
              ))
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Languages"
                required
                error={errorObj.lang}
                helperText={errorObj.lang && 'You must put some language here.'}
                placeholder="Put your language code here, e.g. EN, TH."
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                checked={form.status}
                onChange={(e) => handleChangForm('status', e.target.checked)}
              />
            )}
            label="Active"
            labelPlacement="end"
            disabled={loading}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading || _sum(_values(errorObj)) > 0 || _isEmpty(form.name)}
        >
          {mode === 'create' ? mode : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalProject.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
}

ModalProject.defaultProps = {
  mode: 'create',
  data: {},
}
