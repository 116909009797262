import React, {
  useState,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import _get from 'lodash/get'

import AlertBar from '../../components/NoticeMessage/SnackBar'
import Loading from '../../components/Loading'

import Users from './Users'
import Company from './Company'

import { getUser } from '../../redux/selectors/user.selectors'

const useStyles = makeStyles({
  header: {
    backgroundColor: '#000000',
  },
  tab: {
    color: '#fff',
  },
  content: {
    backgroundColor: 'rgb(246,247,249)',
  },
})

function UserManagement(props) {
  const classes = useStyles()
  const history = useHistory()
  const user = useSelector(getUser)
  const userType = _get(user, 'userData.type', 'enduser')
  const tabUrl = _get(props, 'computedMatch.params.tab')
  const queryString = _get(props, 'location.search')

  const loading = false

  const [tab, setTab] = useState(0)
  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
    history.push(`/user-management/${newValue === 0 ? 'account' : 'company'}`)
  }

  useEffect(() => {
    setTab(tabUrl === 'company' ? 1 : 0)
    if (userType !== 'foxbith') {
      history.push('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabUrl, userType])

  return (
    <>
      { loading
        ? <Loading />
        : (
          <>
            <Container maxWidth="lg">
              <AlertBar />
              <Box mb={3}>
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{ fontWeight: 800 }}
                >
                  User Management
                </Typography>
              </Box>
              <Box className={classes.header}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="secondary"
                >
                  <Tab
                    label="Accounts"
                    className={classes.tab}
                  />
                  <Tab
                    label="Company Profiles"
                    className={classes.tab}
                  />
                </Tabs>
              </Box>
            </Container>
            <Box
              py={3}
              className={classes.content}
            >
              <Container maxWidth="lg">
                {tab === 1 ? (
                  <Company queryString={queryString} />
                ) : (
                  <Users queryString={queryString} />
                )}
              </Container>
            </Box>
          </>
        )}
    </>
  )
}

export default UserManagement
