import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _isArray from 'lodash/isArray'
import _camelCase from 'lodash/camelCase'
import _get from 'lodash/get'
import _split from 'lodash/split'
import _toString from 'lodash/toString'
import _toInteger from 'lodash/toInteger'
import _size from 'lodash/size'
import PropTypes from 'prop-types'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Popover from '@material-ui/core/Popover'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Chip from '@material-ui/core/Chip'
import GetAppIcon from '@material-ui/icons/GetApp'
import { format } from 'date-fns'
import Link from '@material-ui/core/Link'
import { Link as LinkDom } from 'react-router-dom'
import _kebabCase from 'lodash/kebabCase'
import _startCase from 'lodash/startCase'
import removeMd from 'remove-markdown'
import { makeStyles } from '@material-ui/core/styles'
import './list.css'
import firebase from '../../firebase'
import {
  getList,
  getRow,
} from '../../redux/selectors/list.selectors'
import { deleteOneList } from '../../redux/actions/list.action'
import * as alertAction from '../../redux/actions/alert.actions'
import AlertBar from '../NoticeMessage/SnackBar'

const useStyles = makeStyles({
  cell: {
    textAlign: 'left',
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

export default function Row(props) {
  const classes = useStyles()
  const {
    pathListId,
    rowIndex,
    pathListProject,
    publicProject,
    date,
    language,
    uid,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openPopup, setOpenPopup] = React.useState(false)
  const [onDelete, setOnDelete] = React.useState(false)
  const rowData = useSelector(getRow)
  const listData = useSelector(getList)
  // const rowId = uid
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const dispatch = useDispatch()
  const formatDate = format(new Date(_toInteger(`${_toString(date.seconds)}000`)), 'dd/MM/yyyy HH:mm')

  let filterList = []

  listData.map((data) => {
    if (_camelCase(data.name) === _camelCase(pathListId)) {
      filterList = data.fields
    }
    return data
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenPopup = () => {
    setOpenPopup(true)
    setAnchorEl(null)
  }

  const handleClosePopup = () => {
    setOpenPopup(false)
    setAnchorEl(null)
  }

  const deleteRow = async () => {
    setOnDelete(true)
    try {
      const collectionName = publicProject ? 'publicData' : 'data'
      await firebase.firestore().collection(collectionName)
        .doc(pathListProject).collection(pathListId)
        .doc(uid)
        .delete()

      dispatch(alertAction.showMessage('success', 'Data has been deleted successfully.'))
      dispatch(deleteOneList())
      setOpenPopup(false)
    } catch (error) {
      dispatch(alertAction.showMessage('error', 'Data has been deleted failed.'))
      // console.log(error)
    }
    setTimeout(() => {
      setOnDelete(false)
    }, 5000)
  }

  return (
    <>
      { onDelete && <AlertBar /> }
      <TableRow component="div">
        <TableCell align="left" component="div">
          {formatDate}
        </TableCell>
        {
          (rowData && _isArray(filterList)) && (
            React.Children.toArray(filterList.map((data, i) => {
              const rowDataShow = _get(rowData, [rowIndex, _camelCase(data.label)], [])
              const dataType = _camelCase(data.type)
              const subText = ['simtext', 'richtext']
              const normalText = ['number', 'infotext', 'uid']
              let splitLink = ''

              if (dataType === 'link') {
                splitLink = _split(rowDataShow, '//')
              }

              if (data.isShow) {
                return (
                  <TableCell key={i} align="left" className={classes.cell} component="div">
                    {
                      (subText.includes(dataType)) && (
                        <>
                          {
                            (_size(rowDataShow[language]) > 100) ? (
                              `${removeMd((rowDataShow[language]).substring(0, 100))}...`
                            ) : removeMd(rowDataShow[language])
                          }
                        </>
                      )
                    }
                    {
                      (normalText.includes(dataType)) && (rowDataShow)
                    }
                    {
                      (dataType === 'color') && (
                        <div>
                          <span style={{ background: rowDataShow }} className="dot" />
                          <span>{rowDataShow}</span>
                        </div>
                      )
                    }
                    {
                      (dataType === 'image') && <img style={{ borderRadius: 4 }} width={100} src={rowDataShow} alt="" />
                    }
                    {
                      (dataType === 'file') && <Link style={{ color: 'black' }} href={rowDataShow} target="_blank"><GetAppIcon /></Link>
                    }
                    {
                      (dataType === 'link') && <Link style={{ color: 'black' }} href={rowDataShow} target="_blank">{splitLink[1]}</Link>
                    }
                    {
                      (dataType === 'date') && (format(new Date(rowDataShow), 'dd/MM/yyyy'))
                    }
                    {
                      (dataType === 'timestamp') && (format(new Date(rowDataShow), 'dd/MM/yyyy HH:mm'))
                    }
                    {
                      (dataType === 'boolean') && <Chip variant="outlined" style={(rowDataShow === true) ? { color: 'green' } : { color: 'red' }} label={_toString(rowDataShow) ? _startCase(_toString(rowDataShow)) : 'False'} />
                    }
                    {
                      (dataType === 'embed') && (
                        <div>
                          -
                        </div>
                      )
                    }
                    {
                      (dataType === 'geoPoint') && (
                        <div>
                          {`Lat: ${rowDataShow.lat}`}
                          <br />
                          {`Lng: ${rowDataShow.lng}`}
                        </div>
                      )
                    }
                    {
                      (dataType === 'array') && (
                        <div>
                          {
                            (_isArray(rowDataShow)) && (
                              React.Children.toArray(rowDataShow.map((array, index) => <Chip key={index} style={{ marginLeft: 3, marginBottom: 3 }} variant="outlined" label={array} />))
                            )
                          }
                        </div>
                      )
                    }
                  </TableCell>
                )
              }
              return (
                null
              )
            }))
          )
        }
        <TableCell align="left" component="div">
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >

          <List>
            <LinkDom
              to={`/project/${pathListProject}/list/${_kebabCase(pathListId)}/edit/${uid}`}
              style={{ color: 'black' }}
            >
              <ListItem
                button
              >
                Edit
              </ListItem>
            </LinkDom>
            <ListItem
              id={uid}
              onClick={handleOpenPopup}
              button
            >
              Delete
            </ListItem>
          </List>
        </Popover>
      </TableRow>
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
      >
        <DialogTitle>Are you sure you want to delete this item ?</DialogTitle>
        <DialogActions style={{ padding: '16px 24px' }}>
          <Button onClick={handleClosePopup} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteRow} color="primary" autoFocus variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

Row.propTypes = {
  pathListId: PropTypes.string.isRequired,
  pathListProject: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  publicProject: PropTypes.bool.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  language: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
}
