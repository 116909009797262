export const UPDATE_PAGE = 'UPDATE_PAGE'
export const GET_PAGE = 'GET_PAGE'
export const UPDATE_LOADING_STATUS = 'LOADING_STATUS'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'

export function updatePage(newPage, timeStamp) {
  return {
    type: UPDATE_PAGE,
    payload: {
      newPage,
      timeStamp,
    },
  }
}

export function getSchemaPage(initDataPage) {
  return {
    type: GET_PAGE,
    payload: {
      initDataPage,
    },
  }
}

export function updateLoadingStatus(status) {
  return {
    type: UPDATE_LOADING_STATUS,
    payload: {
      status,
    },
  }
}

export function errorMessage(message) {
  return {
    type: ERROR_MESSAGE,
    payload: {
      message,
    },
  }
}
