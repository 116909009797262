import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
  form: {
    marginLeft: 0,
  },
})

function Boolean(props) {
  const {
    classes,
    label,
    placeholder,
    value,
    handleOnChange,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <FormControlLabel
        className={classes.form}
        control={(
          <Switch
            checked={value}
            onChange={handleOnChange}
            name={_lowerCase(label)}
            color="primary"
          />
        )}
        label={placeholder}
      />
    </Box>
  )
}

Boolean.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleOnChange: PropTypes.func.isRequired,
}

Boolean.defaultProps = {
  value: false,
  placeholder: '',
}

export default withStyles(styles)(Boolean)
