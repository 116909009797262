import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import EditIcon from '@material-ui/icons/Edit'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'
import PetsIcon from '@material-ui/icons/Pets'
import _get from 'lodash/get'
import _toUpper from 'lodash/toUpper'
import _isEmpty from 'lodash/isEmpty'
import ModalProject from '../ModalProject'

const useStyles = makeStyles(() => ({
  card: {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  expand: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardActionArea: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  bizDiv: {
    display: 'flex',
    alignItems: 'flex-end',
    '& p': {
      marginLeft: 4,
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0000008A',
    padding: 16,
    '& p': {
      marginLeft: '4px !important',
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  colorDisabled: {
    opacity: 0.5,
  },
}))

const nameTransform = (text) => {
  const transformed = !_isEmpty(text)
    ? _get(text, '[0]').toUpperCase() + text.slice(1)
    : ''
  return transformed
}

function CardProject(props) {
  const classes = useStyles()
  const { data, userType, disabled } = props

  const name = nameTransform(data.name)
  const companyName = nameTransform(_get(data, 'companyId.name', ''))

  // Dialog
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  let companyIcon = <PetsIcon />
  let companyText = 'FOXBITH'
  if (data.type === 'company') {
    companyIcon = <BusinessIcon />
    companyText = _toUpper(companyName)
  } else if (data.type === 'individual') {
    companyIcon = <PersonIcon />
    companyText = 'INDIVIDUAL'
  }

  return (
    <Card className={clsx(classes.card, disabled && classes.colorDisabled)}>
      <Link
        className={clsx(classes.link, classes.expand, disabled && classes.disabled)}
        to={`/project/${data.uid}`}
      >
        <CardActionArea className={classes.cardActionArea}>
          <CardHeader
            title={(
              <Typography variant="h5">
                {name}
              </Typography>
            )}
            subheader={userType === 'foxbith' && (
              <div className={classes.bizDiv}>
                {companyIcon}
                <Typography variant="body2" color="textSecondary" component="p">
                  {companyText}
                </Typography>
              </div>
            )}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {data.url}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
      {userType === 'foxbith' && (
        <>
          <Divider light />
          <CardActionArea onClick={handleOpen}>
            <CardActions className={classes.action}>
              <EditIcon />
              <Typography variant="body2" color="textSecondary" component="p">
                Edit
              </Typography>
            </CardActions>
          </CardActionArea>
          <ModalProject
            open={open}
            onClose={handleClose}
            mode="edit"
            data={data}
          />
        </>
      )}
    </Card>
  )
}

CardProject.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  userType: PropTypes.string,
  disabled: PropTypes.bool,
}

CardProject.defaultProps = {
  userType: 'enduser',
  disabled: false,
}

export default CardProject
