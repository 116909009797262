import { v1 as uuidv1 } from 'uuid'
import _kebabCase from 'lodash/kebabCase'
import firebase from '../../../firebase'

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    // eslint-disable-next-line no-async-promise-executor
    return this.loader.file.then((file) => new Promise(async (resolve, reject) => {
      // Firebase Upload Image
      const storageRef = firebase.storage().ref()
      const name = file.name.split('.').slice(0, -1)[0]
      const extension = file.name.split('.').slice(-1)[0]
      const filename = `${_kebabCase(name)}.${extension}`

      try {
        const snapshot = await storageRef.child(`ckeditor/${uuidv1()}-${filename}`).put(file)
        const downloadURL = await snapshot.ref.getDownloadURL()
        resolve({
          default: downloadURL,
        })
      } catch (error) {
        const genericErrorText = `Couldn't upload file: ${file.name}.`
        reject(genericErrorText)
      }
    }))
  }

  static abort() {
    console.log('Upload image fail!!!.')
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  const newEditor = editor
  newEditor.plugins.get('FileRepository').createUploadAdapter = (loader) => new MyUploadAdapter(loader)
}

export default MyCustomUploadAdapterPlugin
