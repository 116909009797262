import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import _get from 'lodash/get'

import { getUser } from '../../redux/selectors/user.selectors'
import firebase from '../../firebase'
import { logout } from '../../redux/actions/user.actions'
import Footer from '../Footer'
import FBLogo from './foxbith.png'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flow-root',
    backgroundColor: 'rgb(246,247,249)',
  },
  cover: {
    height: 356,
    width: '100%',
    backgroundColor: '#000',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },
  setting: {
    color: '#ffffff',
    marginRight: '-12px',
  },
  logo: {
    height: 30,
    width: 'auto',
    alignItems: 'center',
    display: 'flex',
    '& img': {
      height: 30,
      width: 'auto',
    },
  },
  listItem: {
    minWidth: 0,
    marginRight: 8,
  },
  body: {
    marginTop: '-200px',
  },
}))

function ListLayout(props) {
  const user = useSelector(getUser)
  const userType = _get(user, 'userData.type')
  const classes = useStyles()
  const { children } = props

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const anchorRef = useRef(null)

  const handleLogout = async () => {
    firebase.auth().signOut().then(() => {
      dispatch(logout())
      setAnchorEl(null)
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.cover}>
        <header className={classes.header}>
          <Link to="/" className={classes.logo}>
            <img src={FBLogo} alt="Foxbith" />
          </Link>
          <Box display="flex" alignItems="center">
            {userType === 'foxbith' ? (
              <>
                <Tooltip title="Setting">
                  <IconButton
                    className={classes.setting}
                    ref={anchorRef}
                    onClick={handleClick}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  placement="bottom-end"
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    component={Link}
                    to="/user-management"
                    onClick={handleClose}
                  >
                    <ListItemIcon className={classes.listItem}>
                      <span className={clsx('material-icons')}>
                        manage_accounts
                      </span>
                    </ListItemIcon>
                    <Typography variant="inherit">
                      User Management
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon className={classes.listItem}>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Tooltip title="Logout">
                <IconButton
                  className={classes.setting}
                  // onClick={handleClick}
                  onClick={handleLogout}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </header>
      </div>
      <div className={classes.body}>
        {children}
      </div>
      <Box mt={8} mb={2}>
        <Footer />
      </Box>
    </div>
  )
}

ListLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListLayout
