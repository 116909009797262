import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import _map from 'lodash/map'

const useStyles = makeStyles(() => ({
  cell: {
    color: '#00040C',
    padding: '10px 16px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

export default function LoadingRow(props) {
  const classes = useStyles()
  const { columns } = props

  return (
    <TableRow component="div">
      {
        _map(columns, (headItem, i) => (
          <TableCell
            key={i}
            component="div"
            className={classes.cell}
            align="left"
            padding="default"
          >
            <Skeleton variant="text" />
          </TableCell>
        ))
      }
      <TableCell
        component="div"
        className={classes.cell}
        align="left"
        padding="default"
      >
        <Skeleton variant="text" />
      </TableCell>
    </TableRow>
  )
}

LoadingRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
}
