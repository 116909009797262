import React, { useState } from 'react'
import clsx from 'clsx'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import PropTypes from 'prop-types'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cell: {
    textAlign: 'left',
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  link: {
    color: '#00040C',
    textDecoration: 'none',
  },
  dialogTitle: {
    padding: 24,
    '& h2': {
      textTransform: 'none',
    },
  },
  dialogBtn: {
    padding: '8px 24px 24px 24px',
  },
})

export default function Row(props) {
  const classes = useStyles()
  const {
    columns,
    actions,
    row,
    uid,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenPopup = () => {
    setOpenPopup(true)
    setAnchorEl(null)
  }

  const handleClosePopup = () => {
    setOpenPopup(false)
    setAnchorEl(null)
  }

  const handleWarningAction = (actionFunc, id) => {
    actionFunc(id)
    setOpenPopup(false)
  }

  return (
    <>
      <TableRow
        component="div"
        hover
      >
        {_map(columns, (col, i) => (
          <TableCell
            key={i}
            className={classes.cell}
            align="left"
            component="div"
          >
            {_get(row, `${col.id}`, '')}
          </TableCell>
        ))}
        <TableCell align="left" component="div">
          {_size(actions) > 1 ? (
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => {
              _get(actions, '[0].clickFunction', console.log)(uid)
              setAnchorEl(null)
            }}
            >
              {_get(actions, '[0].icon')}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_map(actions, (menu, i) => {
          if (menu.isLink) {
            return (
              <MenuItem
                key={i}
                component={Link}
                button
                style={{ color: 'black' }}
                to={`${_get(menu, 'prefixUrl')}/${uid}`}
              >
                {_get(menu, 'label')}
              </MenuItem>
            )
          } if (!menu.warnMenu) {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  menu.clickFunction(uid)
                  setAnchorEl(null)
                }}
                className={classes.link}
                component="div"
              >
                {_get(menu, 'label')}
              </MenuItem>
            )
          }
          return (
            <div key={i}>
              <MenuItem
                component="div"
                onClick={handleOpenPopup}
                className={clsx(classes.link, classes.warnMenu)}
              >
                {_get(menu, 'label')}
              </MenuItem>
              {/* Dialog Popup */}
              <Dialog
                open={openPopup}
                onClose={handleClosePopup}
              >
                <DialogTitle className={classes.dialogTitle}>
                  {_get(menu, 'warnTitle')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {_get(menu, 'warnDesc')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogBtn}>
                  <Button onClick={handleClosePopup} variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleWarningAction(menu.clickFunction, uid)}
                    autoFocus
                  >
                    {_get(menu, 'label')}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        })}
      </Menu>
    </>
  )
}

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  uid: PropTypes.string.isRequired,
}
