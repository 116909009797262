import React, { useState } from 'react'
import { v1 as uuidv1 } from 'uuid'
import imageCompression from 'browser-image-compression'
import PropTypes from 'prop-types'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _toLower from 'lodash/toLower'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'

import CardActions from '@material-ui/core/CardActions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TextField from '@material-ui/core/TextField'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import firebase from '../../firebase'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: 'black',
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles(() => ({
  box: {
    height: '100%',
    padding: '16px',
  },
  tableContainer: {
    maxHeight: '600px',
    borderRadius: '4px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  tableHead: {
    position: 'sticky',
    top: '0',
  },
  tableBody: {
    overflow: 'scroll',
  },
  typography: {
    marginBottom: '16px',
  },
  openEditDialog: {
    '& .MuiDialog-paperFullWidth': {
      padding: '60px 40px 40px 40px',
    },
  },
  textfield: {
    marginBottom: '40px',
  },
  hideDelete: {
    display: 'none',
  },
}))

export default function AllCategories(props) {
  const classes = useStyles()
  const {
    categories,
    loading,
    setLoading,
    projectId,
    handleEditCategory,
    handleDelete,
    setDelCategory,
    duplicateEdit,
    setDuplicateEdit,
    openEditDialog,
    setOpenEditDialog,
  } = props
  const defaultEditData = {
    id: '',
    name: '',
    image: '',
    originalName: '',
    desc: '',
  }
  const [editCateData, setEditCateData] = useState(defaultEditData)
  const [dialog, setDialog] = useState(false)

  const handleTextField = (value, type) => {
    switch (type) {
      case 'Name':
        setEditCateData({ ...editCateData, name: _toLower(value) })
        setDuplicateEdit(false)
        break
      case 'Description':
        setEditCateData({ ...editCateData, desc: _toLower(value) })
        setDuplicateEdit(false)
        break
      default:
        break
    }
  }

  const uploadImage = async (projectId1, file) => {
    const options = {
      maxSizeMB: 10,
      maxWidthOrHeight: 4000,
      useWebWorker: true,
    }
    const storageRef1 = firebase.storage().ref()
    const name1 = file.name.split('.').slice(0, -1)[0]
    const extension = file.name.split('.').slice(-1)[0]
    const filename = `${_kebabCase(name1)}.${extension}`
    const fileRef = storageRef1.child(`${projectId1}/${uuidv1()}-${filename}`)

    try {
      const compressedFile = await imageCompression(file, options)
      const snapshot = await fileRef.put(compressedFile)
      const downloadURL = await snapshot.ref.getDownloadURL()
      return downloadURL
    } catch (error) {
      return ''
    }
  }

  const handleUpload = async (event) => {
    const file = event?.target?.files[0]
    setLoading(true)
    try {
      const response = await uploadImage(projectId, file)
      setEditCateData((prev) => (
        { ...prev, image: response }
      ))
    } catch (error) {
      setEditCateData((prev) => (
        { ...prev, image: '' }
      ))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Grid item sm={8} md={7}>
        <Box className={classes.box}>
          <Box>
            <Typography
              variant="h6"
              className={classes.typography}
            >
              All Tags
            </Typography>
          </Box>
          <TableContainer component={Box} className={classes.tableContainer}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {_isEmpty(categories) ? (
                  <TableRow>
                    <TableCell>
                      <Box
                        px={2}
                        py={3}
                        width="100%"
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="body2">
                          No category data.
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : _map(categories, (data, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell scope="row">
                      {data?.name ? `${(data?.name).substring(0, 10)}...` : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.description ? `${(data?.description).substring(0, 10)}...` : '-'}
                    </StyledTableCell>
                    <StyledTableCell width={120}>
                      {data?.image ? (
                        <CardMedia
                          width={30}
                          height={50}
                          component="img"
                          src={data?.image}
                        />
                      ) : (
                        <div>
                          -
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={() => {
                          setOpenEditDialog(!openEditDialog)
                          setEditCateData({
                            id: data?.id || '',
                            name: data?.name || '',
                            image: data?.image || '',
                            originalName: data?.name || '',
                            desc: data?.description || '',
                          })
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setDialog(!dialog)
                          setDelCategory(data?.id)
                        }}
                        className={classes.hideDelete}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      {/* Delete */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialog}
        onClose={() => setDialog(false)}
      >
        <DialogTitle disableTypography>Edit</DialogTitle>
        <DialogActions>
          <Button
            size="small"
            onClick={() => setDialog(false)}
          >
            cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => { handleDelete(); setDialog(!dialog) }}
            className={classes.hideDelete}
          >
            delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        className={classes.openEditDialog}
      >
        <TextField
          fullWidth
          size="small"
          label="Name*"
          value={editCateData?.name}
          error={duplicateEdit}
          variant="outlined"
          className={classes.textfield}
          // inputProps={{ maxLength: 20 }}
          FormHelperTextProps={{ className: classes.helpertext }}
          onChange={(event) => { handleTextField(event.target.value, 'Name') }}
          // eslint-disable-next-line max-len
          // helperText={duplicateEdit ? 'Already exists' : `${editCateData?.name?.length}/20 Characters`}
        />
        <TextField
          rows={3}
          multiline
          fullWidth
          value={editCateData?.desc}
          size="small"
          label="Description (Optional)"
          variant="outlined"
          className={classes.textfield}
          // inputProps={{ maxLength: 50 }}
          FormHelperTextProps={{ className: classes.helpertext }}
          onChange={(event) => handleTextField(event.target.value, 'Description')}
          // helperText={`${editCateData?.desc?.length || 0}/50 Characters`}
        />
        <Box display="flex" justifyContent="space-between">
          <CardActions style={{ padding: 0 }}>
            <label htmlFor={`${projectId}1`}>
              <input
                disabled={editCateData?.image}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                id={_camelCase(`${projectId}1`)}
                name={_camelCase(`${projectId}1`)}
                onChange={handleUpload}
              />
              <Button
                color="primary"
                htmlFor={`${projectId}`}
                variant="contained"
                component="div"
                disabled={loading || editCateData?.image}
                endIcon={<CloudUploadIcon />}
              >
                Upload New Picture
              </Button>
            </label>
          </CardActions>
          {editCateData?.image && (
            <IconButton
              disabled={loading}
              onClick={() => {
                setEditCateData((prev) => (
                  { ...prev, image: '' }
                ))
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
        {editCateData?.image && (
          <Box mt={2}>
            <CardMedia component="img" src={editCateData?.image} />
          </Box>
        )}
        <DialogActions>
          <Button
            size="small"
            disabled={loading}
            onClick={() => {
              setOpenEditDialog(false)
              setDuplicateEdit(false)
            }}
          >
            cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={duplicateEdit || loading}
            onClick={() => {
              handleEditCategory(editCateData)
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

AllCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  setDelCategory: PropTypes.func.isRequired,
  openEditDialog: PropTypes.bool.isRequired,
  setOpenEditDialog: PropTypes.func.isRequired,
  handleEditCategory: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  duplicateEdit: PropTypes.bool,
  setDuplicateEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
}
AllCategories.defaultProps = {
  categories: [],
  duplicateEdit: false,
  loading: false,
}
