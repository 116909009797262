import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Lottie from 'lottie-react-web'
import {
  withStyles,
} from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Button,
  Box,
} from '@material-ui/core'
import loadingButton from '../../components/LottieFiles/loading_button.json'
import firebase from '../../firebase'
import * as alertAction from '../../redux/actions/alert.actions'
import AlertBar from '../../components/NoticeMessage/SnackBar'

import s from './ForgotPass.module.scss'

const CssTextField = withStyles({
  root: {
    '& label': {
      color: '#fff',
    },
    '& label.Mui-focused': {
      color: '#fc4c02',
    },
    '& input': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fc4c02',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fc4c02',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fc4c02',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField)

const CssButton = withStyles({
  root: {
    '&$disabled': {
      color: '#fff',
      background: '#fc4c02',
    },
  },
  disabled: {},
})(Button)

function ForgotPass() {
  const [emailAddress, setEmailAddress] = useState('')
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const forgotSubmit = (e) => {
    e.preventDefault()
    // console.log(`email: ${emailAddress}`)
  }

  const handleReset = async () => {
    setLoading(false)
    try {
      await firebase.auth().sendPasswordResetEmail(emailAddress)
      dispatch(alertAction.showMessage('success', 'We’ve already sent you an email for resetting your password. Please check your email.'))
      // setDisableInput(true)
    } catch (error) {
      dispatch(alertAction.showMessage('error', error.message))
      if (error) {
        document.getElementById('emailAddress').focus()
      }
    }
    setLoading(true)
  }

  return (
    <div className={s.box}>
      <AlertBar />
      <Box mb={4}>
        <Typography variant="h5" className={s.title}>
          Forgot password?
        </Typography>
      </Box>
      <form onSubmit={forgotSubmit}>
        <CssTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="emailAddress"
          name="emailAddress"
          label="Email address"
          type="email"
          disabled={!loading}
          autoFocus
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
        />
        <Box mt={4}>
          {loading
            ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={s.button}
                onClick={handleReset}
              >
                Submit
              </Button>
            )
            : (
              <CssButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={s.button}
                disabled
              >
                <Box mt={2}>
                  <Lottie
                    width="30%"
                    options={{
                      animationData: loadingButton,
                    }}
                  />
                </Box>
              </CssButton>
            )}
        </Box>
        <Box mt={2}>
          <Link to="/login">
            <Typography variant="body2" color="primary" className={s.link}>
              Back To Login
            </Typography>
          </Link>
        </Box>
      </form>
    </div>
  )
}

export default ForgotPass
