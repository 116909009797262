import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Select from '@material-ui/core/Select'
import Skeleton from '@material-ui/lab/Skeleton'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { useFetchCompanyList, useFetchProjectList } from '../api'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  dialogContent: {
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
}))

function CreateAccount(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    mode,
    onChange,
    data,
    loading,
    handleSubmit,
  } = props

  // Get companies
  const [companyQuery, setCompanyQuery] = useState('')
  const { companyLoading, companyList } = useFetchCompanyList(companyQuery)

  // Get projects
  const [projectQuery, setProjectQuery] = useState('')
  const { projectLoading, projectList } = useFetchProjectList(projectQuery)

  useEffect(() => {
    setCompanyQuery(open ? 'fetch' : 'clear')
    setProjectQuery(open ? 'fetch' : 'clear')
  }, [open])

  const errorState = {
    name: false,
    password: false,
    email: false,
    type: false,
    permission: false,
  }

  const [error, setError] = useState(errorState)

  const validate = () => {
    let errors = false
    if (_isEmpty(data.name)) {
      setError((prev) => ({ ...prev, name: true }))
      errors = true
    }
    if (_isEmpty(data.password)) {
      setError((prev) => ({ ...prev, password: true }))
      errors = true
    }
    if (_isEmpty(data.email)) {
      setError((prev) => ({ ...prev, email: true }))
      errors = true
    }
    if (_isEmpty(data.type)) {
      setError((prev) => ({ ...prev, type: true }))
      errors = true
    }
    if (_isEmpty(data.permission)) {
      setError((prev) => ({ ...prev, permission: true }))
      errors = true
    }
    return errors
  }

  const onSubmit = () => {
    try {
      if (!validate()) {
        handleSubmit()
      }
    } catch (err) {
      console.log('error, submit error', err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5" component="div">
          {mode === 'create' ? 'Create Account' : 'Edit Account'}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mt={1} mb={2}>
          {(loading || companyLoading || projectLoading) ? (
            <Skeleton variant="text" height={56} />
          ) : (
            <TextField
              variant="outlined"
              label="Name"
              name="name"
              error={error.name}
              value={data.name}
              onChange={(e) => {
                onChange(e.target.name, e.target.value)
                setError((prev) => ({ ...prev, name: false }))
              }}
              className={classes.textField}
              required
              fullWidth
              helperText={error?.name && 'Please fill this form.'}
            />
          )}
        </Box>
        <Box mb={2}>
          {(loading || companyLoading || projectLoading) ? (
            <Skeleton variant="text" height={56} />
          ) : (
            <TextField
              variant="outlined"
              label="Email"
              name="email"
              error={error.email}
              value={data.email}
              onChange={(e) => {
                onChange(e.target.name, e.target.value)
                setError((prev) => ({ ...prev, email: false }))
              }}
              className={classes.textField}
              required
              fullWidth
              helperText={error?.email && 'Please fill this form.'}
            />
          )}
        </Box>
        <Box mb={2}>
          {(loading || companyLoading || projectLoading) ? (
            <Skeleton variant="text" height={56} />
          ) : mode === 'create' && (
            <TextField
              type="password"
              variant="outlined"
              label="Password"
              name="password"
              error={error.password}
              value={data.password}
              onChange={(e) => {
                onChange(e.target.name, e.target.value)
                setError((prev) => ({ ...prev, password: false }))
              }}
              className={classes.textField}
              required
              fullWidth
              helperText={error?.password && 'Please fill this form.'}
            />
          )}
        </Box>
        <Box mb={2}>
          {(loading || companyLoading || projectLoading) ? (
            <Skeleton variant="text" height={56} />
          ) : (
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={data.type}
                onChange={(e) => {
                  onChange('type', e.target.value)
                  setError((prev) => ({ ...prev, type: false }))
                }}
                label="Type"
                error={error.type}
              >
                <MenuItem value="foxbith">
                  Foxbith
                </MenuItem>
                <MenuItem value="company">
                  Company
                </MenuItem>
                <MenuItem value="enduser">
                  Individual
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        {data.type === 'company' && (
          <Box mb={2}>
            {(loading || companyLoading || projectLoading) ? (
              <Skeleton variant="text" height={56} />
            ) : (
              <Autocomplete
                id="company"
                options={companyList}
                getOptionLabel={(option) => _get(option, 'name', '')}
                onChange={(event, newValue) => onChange('companyId', newValue)}
                value={data.companyId}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Company"
                  />
                )}
              />
            )}
          </Box>
        )}
        {data.type === 'enduser' && (
          <Box mb={2}>
            {(loading || companyLoading || projectLoading) ? (
              <Skeleton variant="text" height={56} />
            ) : (
              <Autocomplete
                id="project"
                options={projectList}
                getOptionLabel={(option) => _get(option, 'name', '')}
                onChange={(event, newValue) => onChange('projectId', newValue)}
                value={data.projectId}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Project"
                  />
                )}
              />
            )}
          </Box>
        )}
        <Box mb={2}>
          {(loading || companyLoading || projectLoading) ? (
            <Skeleton variant="text" height={56} />
          ) : (
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Permission</InputLabel>
              <Select
                value={data.permission}
                error={error.permission}
                onChange={(e) => {
                  onChange('permission', e.target.value)
                  setError((prev) => ({ ...prev, permission: false }))
                }}
                label="Permission"
              >
                <MenuItem value="admin">
                  Admin
                </MenuItem>
                <MenuItem value="editor">
                  Editor
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {(loading || companyLoading || projectLoading) ? <Skeleton width={50} height={15} variant="text" /> : 'Status'}
            </FormLabel>
            {(loading || companyLoading || projectLoading) ? <Skeleton width={100} height={40} variant="text" /> : (
              <FormControlLabel
                control={(
                  <Switch
                    checked={data.status}
                    onChange={(e) => onChange(e.target.name, e.target.checked)}
                    name="status"
                    color="primary"
                  />
                )}
                label="Active"
              />
            )}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={() => {
            handleClose()
            setError(errorState)
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          disabled={(loading || companyLoading || projectLoading)}
        >
          {mode === 'create' ? mode : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  mode: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

CreateAccount.defaultProps = {
  mode: 'create',
  loading: false,
}

export default CreateAccount
