import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _upperCase from 'lodash/upperCase'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'
import _get from 'lodash/get'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
  helperText: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0',
  },
})

function SimText(props) {
  const {
    classes,
    label,
    placeholder,
    value,
    handleOnChange,
    selectLang,
  } = props

  const getValue = _get(value, [selectLang], '')
  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <Box mb={1}>
        <TextField
          fullWidth
          multiline
          label={_upperCase(selectLang)}
          variant="outlined"
          placheholder={placeholder}
          value={_get(value, [selectLang], '')}
          onChange={(e) => handleOnChange(e, selectLang)}
          error={getValue.length > 70}
          // inputProps={{
          //   maxLength: '70',
          // }}
          // helperText={`${getValue.length > 70 ? 70 : getValue.length} / 70 Characters`}
          FormHelperTextProps={{ className: classes.helperText }}
        />
      </Box>
    </Box>
  )
}

SimText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.string]),
  handleOnChange: PropTypes.func.isRequired,
  selectLang: PropTypes.string.isRequired,
}

SimText.defaultProps = {
  value: '',
}

export default withStyles(styles)(SimText)
