import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import _size from 'lodash/size'
import _startCase from 'lodash/startCase'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import _map from 'lodash/map'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getLangNameFromCode as _fullLang } from 'language-name-map'
import Loading from './Loading'
import * as formAction from '../../../redux/actions/form.action'
import { getProjectData } from '../../../redux/selectors/project.selectors'
import {
  getFormPage,
  getFormData,
  getFormLoading,
  getIsPublic,
} from '../../../redux/selectors/form.selectors'
import * as alertAction from '../../../redux/actions/alert.actions'
import AlertBar from '../../../components/NoticeMessage/SnackBar'
import GenFieldForm from '../../../components/Fields/GenFieldForm/GenFieldForm'
import CreateDefaultInputValue from '../../../components/Fields/GenFieldForm/createDefaultInputValue'

import firebase from '../../../firebase'

const styles = (theme) => ({
  paper: {
    overflow: 'hidden',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    flex: 1,
  },
  loadingBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    paddingRight: 56,
    paddingLeft: 56,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  toolbar: {
    padding: 0,
  },
  fabButton: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})

function CreateProjectList(props) {
  const { computedMatch, classes } = props
  const history = useHistory()
  const projectId = _camelCase(_get(computedMatch, 'params.projectId'))
  const listId = _camelCase(_get(computedMatch, 'params.listId'))
  const [loading, setLoading] = useState(false)
  const [lang, setLang] = useState()
  const [chLang, setChLang] = useState('')
  const [category, setCategory] = useState([])
  const fields = useSelector(getFormPage)
  const fieldsSize = _size(fields)
  const allData = useSelector(getFormData)
  const data = _get(allData, listId)
  const isPublic = useSelector(getIsPublic)
  const project = useSelector(getProjectData)
  const formLoading = useSelector(getFormLoading)
  const dispatch = useDispatch()
  const database = firebase.firestore()

  const categoriesRef = database.collection('data').doc(projectId).collection('categories').where('listId', '==', _kebabCase(listId))

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (_isEmpty(data)) {
        dispatch(alertAction.showMessage('error', 'Please add information'))
      } else {
        const cateIds = _map(data?.categories?.categories, (cate) => cate?.id)
        const newData = { ...data, categories: cateIds }
        dispatch(formAction.submitListData(newData, projectId, listId, isPublic, history))
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (!_isEmpty(project)) {
      setLang(project.schema.lang)
      setChLang(project.schema.lang[0])
    }
  }, [project])

  useEffect(() => {
    if (fieldsSize) {
      const defaultInputValue = CreateDefaultInputValue(fields, lang)
      dispatch(formAction.setNewDataForm({
        [listId]: defaultInputValue,
      }))
    }
    // eslint-disable-next-line
  }, [fieldsSize, lang])
  const handleSelect = (e) => {
    setChLang(e.target.value)
  }

  useEffect(() => {
    dispatch(formAction.getShemaList(projectId, listId))
  }, [projectId, dispatch, listId, isPublic])

  const getCategories = async () => {
    const categories = []
    try {
      const response = await categoriesRef.get()
      response.docs.forEach((res) => {
        categories.push({
          id: res?.id,
          name: res?.data().name,
          description: res?.data().description,
        })
      })
      setCategory(categories)
    } catch (error) {
      setCategory([])
      console.log('error', error)
    }
  }

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AlertBar />
      <Box mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h4">
          {_startCase(listId)}
        </Typography>
        <Select variant="outlined" value={chLang} onChange={handleSelect}>
          {
            _map(lang, (langtype, i) => (
              <MenuItem key={`lang${i}`} value={langtype}>{` Language: ${_fullLang(langtype).name}`}</MenuItem>
            ))
          }
        </Select>
      </Box>
      <Paper className={classes.paper}>
        {
          formLoading || _isEmpty(fields) || _isEmpty(lang)
            ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <GenFieldForm
                    projectId={projectId}
                    schema={fields}
                    selectLang={chLang}
                    lang={lang}
                    allData={allData}
                    dataKey={listId}
                    categories={category}
                  />
                  <AppBar position="fixed" color="secondary" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                      <div className={classes.fabButton}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading}
                        >
                          submit
                        </Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                </form>
              </>
            )
        }
      </Paper>
    </>
  )
}

CreateProjectList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  computedMatch: PropTypes.shape({}),
}
CreateProjectList.defaultProps = {
  computedMatch: {},
}

export default withStyles(styles)(CreateProjectList)
