export const SET_AUTHTOKEN = 'SET_AUTHTOKEN'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CHECK_LOGIN = 'CHECK_LOGIN'
export const GET_USER = 'GET_USER'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS'

export function setAuthToken(token) {
  return {
    type: SET_AUTHTOKEN,
    token,
  }
}

export function clearAuth() {
  return {
    type: CLEAR_AUTH,
  }
}

export function loginSuccess(user, userData) {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      userUid: user,
      userData,
    },
  }
}

export function loginFail(message) {
  // console.log(message)
  return {
    type: LOGIN_FAIL,
    payload: {
      errorMessage: message,
    },
  }
}

export function logout(message) {
  return {
    type: LOGOUT,
    payload: {
      errorMessage: message,
    },
  }
}

export function checkLogin() {
  return {
    type: CHECK_LOGIN,
  }
}

export function getUser() {
  return {
    type: GET_USER,
  }
}

export function getErrorMessageUser(errorMessage) {
  return {
    type: ERROR_MESSAGE,
    payload: {
      errorMessage,
    },
  }
}

export function updateStatusLoading(newStatusLoading) {
  return {
    type: UPDATE_LOADING_STATUS,
    payload: {
      newStatusLoading,
    },
  }
}
