import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    gap: '48px',
  },
  helperText: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0',
  },
  metaDes: {
    height: 100,
    '&::placeholder': {

    },
  },
}))

const Seo = () => {
  const classes = useStyles()
  const [seo, setSeo] = useState({
    title: '',
    description: '',
    url: '',
  })

  const handleSeo = (value, type) => {
    setSeo((prev) => {
      const data = { ...prev }
      switch (type) {
        case 'Title':
          data.title = value
          break
        case 'Description':
          data.description = value
          break
        case 'Url':
          data.url = value
          break
        default:
          break
      }
      return data
    })
  }

  return (
    <Card>
      <Box margin={3}>
        <Typography variant="h6">Search engine listing preview</Typography>
        <Box className={classes.column} mt={2}>
          <Box width="100%">
            <Box>
              <Typography>SEO Title</Typography>
              <Box mt={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  helperText={`${seo?.title?.length}/20 Characters`}
                  FormHelperTextProps={{ className: classes.helperText }}
                  placeholder="Add a Page Title"
                  inputProps={{
                    maxLength: 20,
                  }}
                  value={seo?.title}
                  onChange={(event) => handleSeo(event.target.value, 'Title')}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Typography>Meta Description</Typography>
              <Box mt={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  helperText={`${seo?.description?.length}/75 Characters`}
                  FormHelperTextProps={{ className: classes.helperText }}
                  placeholder="Write a summary for your post"
                  multiline
                  rows={4}
                  inputProps={{
                    maxLength: 75,
                  }}
                  value={seo?.description}
                  onChange={(event) => handleSeo(event.target.value, 'Description')}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <Typography>URL& Handle</Typography>
              <Box mt={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  helperText={`${seo?.url?.length}/20 Characters`}
                  FormHelperTextProps={{ className: classes.helperText }}
                  placeholder="www.CMSfoxbith.com/post"
                  inputProps={{
                    maxLength: 20,
                  }}
                  value={seo?.url}
                  onChange={(event) => handleSeo(event.target.value, 'Url')}
                />
              </Box>
            </Box>
          </Box>
          <Box width="100%">
            <Typography>Search Engine Preview</Typography>
            <Box mt={2} mb={1}>
              <Typography
                variant="h6"
                style={{ color: 'blue' }}
              >
                {!seo?.title ? 'Example page' : `${seo?.title}`}
              </Typography>
            </Box>
            <Typography color="textSecondary">
              {!seo?.description ? `This is the example page meta description. The full length of the
              description is something like this.` : `${seo?.description}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default Seo
