import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import _get from 'lodash/get'
import _map from 'lodash/map'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#000000',
    color: '#fff',
  },
  action: {
    width: '83.42px !important',
  },
  cell: {
    padding: '10px 16px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  white: {
    color: '#fff !important',
    '& *': {
      color: '#fff !important',
    },
    '&:hover': {
      color: '#fff !important',
      '& svg': {
        color: '#fff !important',
        opacity: '1 !important',
      },
    },
  },
}))

function MyHeader(props) {
  const classes = useStyles()
  const {
    headCells,
    isLoading,
    order,
    orderBy,
    onRequestSort,
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headerNames = [1, 2, 3]

  return (
    <TableHead className={classes.header} component="div">
      <TableRow component="div">
        {!isLoading ? (
          <>
            {
              _map(headCells, (headCell, i) => (
                <TableCell
                  key={i}
                  component="div"
                  style={{ maxWidth: 'auto' }}
                  className={clsx(classes.cell, classes.white)}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {_get(headCell, 'canSort') ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {_get(headCell, 'label')}
                    </TableSortLabel>
                  ) : (
                    <>
                      {_get(headCell, 'label')}
                    </>
                  )}
                </TableCell>
              ))
            }
          </>
        ) : (
          <>
            {
              _map(headerNames, (headCell, i) => (
                <TableCell
                  key={i}
                  component="div"
                  style={{ maxWidth: 'auto' }}
                  className={clsx(classes.cell, classes.white)}
                  align="left"
                  padding="default"
                />
              ))
            }
          </>

        )}
        {!isLoading && (
          <TableCell component="div" align="right" className={clsx(classes.action, classes.white)} />
        )}
      </TableRow>
    </TableHead>
  )
}

MyHeader.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

export default MyHeader
