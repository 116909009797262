export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const CLEAR_DATA = 'CLEAR_DATA'

export function showMessage(key, message) {
  return {
    type: SHOW_MESSAGE,
    payload: {
      keyAlert: key,
      messageAlert: message,
    },
  }
}

export function clear() {
  return {
    type: CLEAR_DATA,
  }
}
