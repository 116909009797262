import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CodeIcon from '@material-ui/icons/Code'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  actionArea: {
    padding: 0,
  },
  outerEmbed: {
    '& :first-child': {
      maxWidth: '100%',
    },
  },
})

function Embed(props) {
  const {
    label,
    value,
    placeholder,
    handleOnChange,
  } = props
  const classes = useStyles()
  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <Box mb={2}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start"><CodeIcon /></InputAdornment>,
          }}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleOnChange(e)}
        />
      </Box>
    </Box>
  )
}

Embed.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

Embed.defaultProps = {
  value: '',
}

export default Embed
