import _isString from 'lodash/isString'
import _isObject from 'lodash/isObject'
import firebase from '../../firebase'

const db = firebase.firestore()
function to(promise) {
  return promise
    .then((result) => ({ error: null, result }))
    .catch((error) => ({ error }))
}

function getProjectByTypeFoxbith(status) {
  return new Promise((resolve, reject) => {
    db
      .collection('projects')
      .where('status', '==', status)
      .orderBy('createdAt', 'desc')
      .get()
      .then((result) => {
        resolve(result.docs)
      })
      .catch((err) => {
        reject(new Error(err))
      })
  })
}

function getProjectByTypeCompany(companyRef) {
  if (!_isObject(companyRef)) {
    return Promise.reject(new Error('invalid params companyId is type object'))
  }

  return new Promise((resolve, reject) => {
    db
      .collection('projects')
      .where('companyId', '==', companyRef)
      .where('status', '==', true)
      .orderBy('createdAt', 'desc')
      .get()
      .then((result) => {
        resolve(result.docs)
      })
      .catch((err) => {
        reject(new Error(err))
      })
  })
}

function getProjectByTypeEndUser(projectRef) {
  if (!_isObject(projectRef)) {
    return Promise.reject(new Error('invalid params projectId is type object'))
  }
  return new Promise((resolve, reject) => {
    projectRef
      .get()
      .then((result) => {
        resolve([result])
      })
      .catch((err) => {
        reject(new Error(err))
      })
  })
}

const firebaseProject = {
  async getProject(type, docProjectID, status = true) {
    let errorType = false
    let errorDocProjectID = false
    if (!_isString(type)) {
      errorType = true
    }
    if (type !== 'foxbith' && !_isObject(docProjectID)) {
      errorDocProjectID = true
    }

    if (errorType || errorDocProjectID) {
      let errorMessage = ''
      if (errorType) errorMessage = 'type '
      if (errorMessage) {
        if (errorType) errorMessage = 'and docProjectID'
        else errorMessage = 'docProjectID'
      }
      return Promise.reject(new Error(`invalid params ${errorMessage} is string`))
    }
    let error
    let result

    switch (type) {
      case 'foxbith':
        ({ error, result } = await to(getProjectByTypeFoxbith(status)))
        break
      case 'company':
        ({ error, result } = await to(getProjectByTypeCompany(docProjectID)))
        break
      case 'enduser':
        ({ error, result } = await to(getProjectByTypeEndUser(docProjectID)))
        break
      default:
        error = new Error('not found')
    }
    if (error) return Promise.reject(error)
    return Promise.resolve(result)
  },
}

export default firebaseProject
