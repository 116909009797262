import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import { v1 as uuidv1 } from 'uuid'
import firebase from '../../../firebase'

const useStyles = makeStyles({
  actionArea: {
    padding: 0,
  },
})

async function postFile(projectId, file) {
  const storageRef = firebase.storage().ref()

  const name = file.name.split('.').slice(0, -1)[0]
  const extension = file.name.split('.').slice(-1)[0]
  const filename = `${_kebabCase(name)}.${extension}`

  const fileRef = storageRef.child(`${projectId}/${uuidv1()}-${filename}`)
  try {
    const snapshot = await fileRef.put(file)
    const downloadURL = snapshot.ref.getDownloadURL()
    return downloadURL
  } catch (error) {
    console.log(error)
    return ''
  }
}

function UploadFileEditor(props) {
  const {
    projectId,
    removeFunc,
    updateUrl,
    label,
    order,
    placeholder,
    handleOnChange,
  } = props
  const classes = useStyles()

  // have to use redux instead
  const [newUrl, setNewURL] = React.useState(updateUrl)

  const [loading, setLoading] = React.useState(false)

  const handleSelect = async (event) => {
    const file = event.target.files[0]
    setLoading(true)
    try {
      const imgUrl = await postFile(projectId, file)
      setNewURL(imgUrl)
      setLoading(false)
      handleOnChange(imgUrl)
    } catch (error) {
      console.log(error)
      setNewURL('')
      setLoading(false)
    }
  }
  const handleOnRemove = () => {
    setNewURL('')
    removeFunc('')
  }

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <Box mb={2}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start"><AttachFileIcon /></InputAdornment>,
          }}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={updateUrl}
        />
      </Box>
      <CardActions className={classes.actionArea}>
        <label htmlFor={_camelCase(`${label}${order}`)}>
          <input
            // accept="image/*"
            style={{ display: 'none' }}
            id={_camelCase(`${label}${order}`)}
            name={_camelCase(label)}
            type="file"
            disabled={loading}
            onChange={handleSelect}
          />
          <Button
            variant="contained"
            color="default"
            component="div"
            startIcon={<CloudUploadIcon />}
            htmlFor={_camelCase(`${label}${order}`)}
            disabled={loading}
          >
            Upload
          </Button>
        </label>
        { newUrl && (
          <Button
            variant="contained"
            color="default"
            startIcon={<DeleteIcon />}
            disabled={loading}
            onClick={handleOnRemove}
          >
            Delete
          </Button>
        )}
      </CardActions>
    </Box>
  )
}

UploadFileEditor.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  removeFunc: PropTypes.func.isRequired,
  updateUrl: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  order: PropTypes.number,
}

UploadFileEditor.defaultProps = {
  projectId: '',
  updateUrl: '',
  order: 0,
}

export default UploadFileEditor
