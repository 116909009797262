import React from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import Lottie from 'lottie-react-web'
import { Link } from 'react-router-dom'
import Notfound from './404NotFound.json'
import s from './Error.module.scss'

function ErrorPage() {
  return (
    <>
      <Box mb={2}>
        <Lottie options={{ animationData: Notfound }}>
          animation
        </Lottie>
      </Box>
      <Typography variant="h5" color="primary">
        Error page not found
      </Typography>
      <Box mt={4}>
        <Link to="/">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={s.button}
          >
            Back to project
          </Button>
        </Link>
      </Box>
    </>
  )
}

export default ErrorPage
