import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import listReducer from './reducers/list.reducer'
import userReducer from './reducers/user.reducer'
import formReducer from './reducers/form.reducer'
import pageReducer from './reducers/page.reducer'
import projectReducer from './reducers/project.reducer'
import alertReducer from './reducers/alert.reducer'
import createformReducer from './reducers/createform.reducer'

const reducers = combineReducers({
  user: userReducer,
  list: listReducer,
  form: formReducer,
  page: pageReducer,
  project: projectReducer,
  alert: alertReducer,
  createform: createformReducer,
})

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
)

export default store
