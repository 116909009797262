export const getList = (state) => state.list.listData || []

export const getLoadingList = (state) => state.list.loadingList

export const getLoadingRow = (state) => state.list.loadingRow

export const getRow = (state) => state.list.rowData || []

export const getAllList = (state) => state.list

export const getId = (state) => state.list.id

export const getEditData = (state) => state.list.editData
