import React from 'react'
import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import _map from 'lodash/map'

import Row from './Row'
import LoadingRow from './LoadingRow'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    width: '100%',
  },
  tableBody: {
    display: 'table',
    width: '100%',
    whiteSpace: 'nowrap',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      tableLayout: 'auto',
      overflowX: 'scroll',
    },
  },
  paginate: {
    width: '100%',
    '& .MuiTablePagination-toolbar': {
      padding: '8px 16px',
    },
    '& .MuiTablePagination-select': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 1,
      padding: '8px 24px 8px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      '& .MuiTablePagination-toolbar': {
        display: 'block',
        padding: '16px 16px 8px 16px',
        textAlign: 'center',
      },
      '& .MuiTablePagination-input': {
        margin: '8px 0',
      },
      '& .MuiTablePagination-actions': {
        margin: '8px 0 0 0',
      },
    },
  },
}))

export default function MyTable(props) {
  const classes = useStyles()

  const {
    isLoading,
    columns,
    language,
    pathListId,
    pathListProject,
    publicProject,
    rows,
    onLoadmore,
    showLoadmore,
  } = props

  const loadingMock = [...Array(5).keys()]

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table size="small" className={classes.tableBody} component="div">
          <Header names={columns} isLoading={isLoading} />
          <TableBody component="div">
            {isLoading ? (
              <>
                {
                  _map(loadingMock, (row, id) => (
                    <LoadingRow
                      headerNames={columns}
                      key={id}
                    />
                  ))
                }
              </>
            ) : (
              <>
                {
                  rows.length > 0 && (
                    _map(rows, (row, index) => (
                      <Row
                        label={row}
                        rowIndex={index}
                        pathListId={pathListId}
                        pathListProject={pathListProject}
                        publicProject={publicProject}
                        date={row.createdAt}
                        uid={row.uid}
                        key={index}
                        language={language}
                      />
                    ))
                  )
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(!isLoading && rows.length === 0) && (
        <Box alignItems="center" justifyContent="center" display="flex" px={2} py={3}>
          <Typography variant="body2">
            No data available in table.
          </Typography>
        </Box>
      )}
      {(!isLoading && showLoadmore) && (
        <Box alignItems="center" justifyContent="center" display="flex" p={2}>
          <Button variant="outlined" onClick={onLoadmore}>
            Loadmore
          </Button>
        </Box>
      )}
    </Paper>
  )
}

MyTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.string,
  publicProject: PropTypes.bool,
  pathListProject: PropTypes.string.isRequired,
  pathListId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onLoadmore: PropTypes.func,
  showLoadmore: PropTypes.bool,
}

MyTable.defaultProps = {
  isLoading: true,
  publicProject: false,
  language: '',
  onLoadmore: () => {},
  showLoadmore: false,
}
