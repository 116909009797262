import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'
import _map from 'lodash/map'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
  formControl: {
    minWidth: 120,
  },
})

function MySelect(props) {
  const {
    classes,
    label,
    value,
    handleOnChange,
    choices,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{_startCase(_lowerCase(label))}</InputLabel>
        <Select
          value={value}
          onChange={handleOnChange}
          label={_startCase(_lowerCase(label))}
        >
          {_map(choices, (c, i) => (
            <MenuItem key={i} value={_lowerCase(c)}>{_startCase(_lowerCase(c))}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

MySelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  choices: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.string]),
  handleOnChange: PropTypes.func.isRequired,
}

MySelect.defaultProps = {
  value: '',
}

export default withStyles(styles)(MySelect)
