import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'
import _upperCase from 'lodash/upperCase'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import MyEditorConfiguration from './uploadAdapter'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
})

function RichText(props) {
  const {
    classes,
    lang,
    label,
    value,
    handleOnChange,
    selectLang,
  } = props

  const editorConfiguration = {
    extraPlugins: [
      MyEditorConfiguration,
    ],
    language: lang,
    mediaEmbed: {
      previewsInData: true,
    },
  }

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      { lang.map((item, i) => (
        (selectLang === item) && (
        <Box mb={2} key={i}>
          <Box mb={1}>
            <Typography variant="body1">{_upperCase(item)}</Typography>
          </Box>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={value?.[selectLang] ? value?.[selectLang] : ''}
            onChange={(event, editor) => {
              const data = editor.getData()
              handleOnChange(data, selectLang)
            }}
            // onReady={(editor) => {
            //   // You can store the "editor" and use when it is needed.
            //   console.log('Editor is ready to use!', editor)
            // }}
            // onBlur={(event, editor) => {
            //   console.log('Blur.', editor)
            // }}
            // onFocus={(event, editor) => {
            //   console.log('Focus.', editor)
            // }}
          />
        </Box>
        )
      ))}
    </Box>
  )
}

RichText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  lang: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.string]),
  selectLang: PropTypes.string.isRequired,
}

RichText.defaultProps = {
  value: '',
}

export default withStyles(styles)(RichText)
