import React from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import Lottie from 'lottie-react-web'
import { Link } from 'react-router-dom'
import NoData from './NoData.json'
import s from './Contact.module.scss'

function NoProject() {
  return (
    <>
      <Box mb={2}>
        <Lottie options={{ animationData: NoData }}>
          animation
        </Lottie>
      </Box>
      <Box textAlign="center">
        <Typography color="primary" gutterBottom variant="h3" align="center">
          No project found
        </Typography>
        <Box display="flex" flexDirection="column" margin="0 auto" className={s.contact}>
          <Typography variant="h6">
            Please contact our team
          </Typography>
          <Typography variant="h6">
            Email: info@foxbith.com
          </Typography>
          <Typography variant="h6">
            Phone: +6661-496-9871
          </Typography>
        </Box>
        <Box mt={4}>
          <Link to="/">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={s.button}
            >
              Back to project
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  )
}
export default NoProject
