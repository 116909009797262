import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'
import _filter from 'lodash/filter'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import _startCase from 'lodash/startCase'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import _map from 'lodash/map'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getLangNameFromCode as _fullLang } from 'language-name-map'
import Loading from '../CreateProjectList/Loading'
import { getFormPage, getFormData, getIsPublic } from '../../../redux/selectors/form.selectors'
import * as formAction from '../../../redux/actions/form.action'
import GenFieldForm from '../../../components/Fields/GenFieldForm/GenFieldForm'
import * as alertAction from '../../../redux/actions/alert.actions'
import AlertBar from '../../../components/NoticeMessage/SnackBar'
import { getProjectData } from '../../../redux/selectors/project.selectors'

import firebase from '../../../firebase'

const styles = (theme) => ({
  paper: {
    overflow: 'hidden',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    flex: 1,
  },
  loadingBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    paddingRight: 56,
    paddingLeft: 56,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  toolbar: {
    padding: 0,
  },
  fabButton: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})

function EditProjectList(props) {
  const { computedMatch, classes } = props
  const history = useHistory()
  const projectId = _camelCase(_get(computedMatch, 'params.projectId'))
  const listId = _camelCase(_get(computedMatch, 'params.listId'))
  const uid = (_get(computedMatch, 'params.uid'))
  const database = firebase.firestore()
  const categoriesRef = database.collection('data').doc(projectId).collection('categories').where('listId', '==', _kebabCase(_get(computedMatch, 'params.listId')))

  const editData = useSelector(getFormData)
  const schemaList = useSelector(getFormPage)
  const isPublic = useSelector(getIsPublic)
  const project = useSelector(getProjectData)
  const dispatch = useDispatch()
  const [lang, setLang] = useState()
  const [chLang, setChLang] = useState('')
  const [allCategory, setAllCategory] = useState([])

  const [isFirst, setIsFirst] = useState(true)

  useEffect(() => {
    dispatch(formAction.getEditData(projectId, listId, uid, isPublic))
    dispatch(formAction.getShemaList(projectId, listId))
  }, [projectId, dispatch, listId, uid, isPublic])

  useEffect(() => {
    if (!_isEmpty(project)) {
      setLang(project.schema.lang)
      setChLang(project.schema.lang[0])
    }
  }, [project])

  const handleSelect = (e) => {
    setChLang(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (_isEmpty(editData[listId])) {
      dispatch(alertAction.showMessage('error', 'fail'))
    } else {
      const cateIds = _map(editData[listId]?.categories?.categories, (cate) => cate?.id)
      const newData = { ...editData[listId], categories: cateIds }
      dispatch(formAction.editListData(newData, projectId, listId, isPublic, uid, history))
    }
  }

  const getCategories = async () => {
    const categoriesRes = []
    try {
      const response = await categoriesRef.get()
      response.docs.forEach((res) => {
        categoriesRes.push({
          id: res?.id,
          name: res?.data().name,
          description: res?.data().description,
        })
      })
      if (_isArray(editData?.[listId]?.categories)) {
        setAllCategory(categoriesRes)
        const categories = _filter(
          categoriesRes, (cate) => editData?.[listId]?.categories?.includes(cate?.id),
        )
        await dispatch(formAction.changeValueIteratorObject(
          listId,
          'categories',
          { key: 'categories', value: categories },
        ))
      }
    } catch (error) {
      setAllCategory([])
      console.log('error get categories', error)
    } finally {
      setIsFirst(false)
    }
  }

  useEffect(() => {
    if (editData[listId]?.categories && schemaList.length > 0 && isFirst) {
      getCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, schemaList])

  return (
    <>
      <AlertBar />
      <Box mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h4">
          {_startCase(listId)}
        </Typography>
        <Select variant="outlined" value={chLang} onChange={handleSelect}>
          {
            _map(lang, (langtype, i) => (
              <MenuItem key={`lang${i}`} value={langtype}>{` Language: ${_fullLang(langtype).name}`}</MenuItem>
            ))
          }
        </Select>
      </Box>
      <Paper className={classes.paper}>
        {
          (_isEmpty(editData) || _isEmpty(schemaList) || _isEmpty(lang))
            ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <GenFieldForm
                    projectId={projectId}
                    schema={schemaList}
                    lang={lang}
                    allData={editData}
                    dataKey={listId}
                    selectLang={chLang}
                    categories={allCategory}
                    disabled={isPublic}
                  />
                  <AppBar position="fixed" color="secondary" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                      <div className={classes.fabButton}>
                        <Button type="submit" variant="contained" color="primary">submit</Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                </form>
              </>
            )
        }
      </Paper>
    </>
  )
}

EditProjectList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  computedMatch: PropTypes.shape({}),
}
EditProjectList.defaultProps = {
  computedMatch: {},
}

export default withStyles(styles)(EditProjectList)
