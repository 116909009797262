import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import { SET_PROJECT, ERROR_MESSAGE, LOADING } from '../actions/project.actions'

const initialState = {
  loading: true,
  projectData: null,
  errorMessage: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT: {
      const checkValueProjectData = !!_get(action, 'payload.projectData')
      let newState = {}
      if (checkValueProjectData) {
        newState = {
          ...state,
          projectData: action.payload.projectData,
          loading: false,
        }
      } else {
        newState = {
          ...state,
          loading: false,
          errorMessage: [...state.errorMessage, 'can not get data project from firebase'],
        }
      }
      return newState
    }
    case ERROR_MESSAGE: {
      const checkValueErrorMessage = !!_get(action, 'payload.errorMessage')
      let newState = {}
      if (checkValueErrorMessage) {
        newState = {
          ...state,
          errorMessage: [...state.errorMessage, action.payload.errorMessage],
        }
      } else {
        newState = {
          ...state,
          errorMessage: [...state.errorMessage, 'parameter function project error message input invalid'],
        }
      }
      return newState
    }
    case LOADING: {
      const checkValueLoading = _isBoolean(action.payload.newLoading)
      let newState = {}
      if (checkValueLoading) {
        newState = {
          ...state,
          loading: action.payload.newLoading,
        }
      } else {
        newState = {
          ...state,
          errorMessage: [...state.errorMessage, 'parameter function update loading project error message input invalid'],
        }
      }
      return newState
    }
    default: {
      return state
    }
  }
}

export default reducer
