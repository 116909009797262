import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
  disabledTextField: {
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
})

function InfoText(props) {
  const {
    classes,
    label,
    placeholder,
    value,
    handleOnChange,
    disabled,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleOnChange(e)}
        disabled={disabled}
        className={disabled && classes.disabledTextField}
      />
    </Box>
  )
}

InfoText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleOnChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,

}

InfoText.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
}

export default withStyles(styles)(InfoText)
