import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import ModalProject from '../ModalProject'

const useStyles = makeStyles(() => ({
  cardContentcenter: {
    minHeight: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
  },
}))

export default function CardCreateProject() {
  const classes = useStyles()

  // Dialog
  const [open, setOpen] = useState(false)
  const handleOpen = async () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Card onClick={handleOpen}>
        <CardActionArea>
          <CardContent className={classes.cardContentcenter}>
            <AddIcon
              color="primary"
              fontSize="large"
            />
            <Box mt={1}>
              <Typography
                variant="subtitle2"
                color="primary"
              >
                Add Project
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      <ModalProject
        open={open}
        onClose={handleClose}
        mode="create"
      />
    </>
  )
}
