import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = (theme) => ({
  title: {
    fontWeight: 'bold',
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    margin: '0 16px 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 16px 0',
      width: '100%',
    },
  },
  right: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
})

function GeoLocation(props) {
  const {
    classes,
    label,
    lat,
    lng,
    handleOnChange,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <Box className={classes.location} mb={2}>
        <Box className={classes.left}>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start">Latitude</InputAdornment>,
            }}
            type="number"
            fullWidth
            variant="outlined"
            placeholder="12.32"
            value={lat}
            onChange={(e) => handleOnChange(e, 'lat')}
          />
        </Box>
        <Box className={classes.right}>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start">Longtitude</InputAdornment>,
            }}
            fullWidth
            type="number"
            variant="outlined"
            placeholder="100.01"
            value={lng}
            onChange={(e) => handleOnChange(e, 'lng')}
          />
        </Box>
      </Box>
    </Box>
  )
}

GeoLocation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleOnChange: PropTypes.func.isRequired,
}

GeoLocation.defaultProps = {
  lat: '',
  lng: '',
}

export default withStyles(styles)(GeoLocation)
