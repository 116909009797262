import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import Switch from '@material-ui/core/Switch'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import {
  arrStore, getCreateForm, listData, storeData, updateForm,
} from '../../redux/selectors/createform.selector'
import {
  setForm, setStoreData, arrObjStore, setListData,
} from '../../redux/actions/createform.action'

const useStyles = makeStyles(() => ({
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  boxRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  box: {
    width: '100%',
  },
  textfield: {
    width: '80%',
  },
  boxSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  lastBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lastButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addSelect: {
    color: 'gray',
    cursor: 'text',
    borderRadius: '0',
    '&:hover': {
      borderBottom: '1px solid',
      backgroundColor: 'white',
    },
    hidddenBt: {
      visibility: 'hidden',
      display: 'none',
    },
  },
}))
const Formcreate = (props) => {
  const {
    value, index, arrObj, fieldIndex,
  } = props
  const dispatch = useDispatch()
  const type = useSelector(getCreateForm)
  const isUpdate = useSelector(updateForm)
  const dataStore = useSelector(storeData)
  const listStore = useSelector(listData)
  const arrOfObjStore = useSelector(arrStore)
  const typeForm = _get(type, '[0].pl[0]')
  const fieldData = _get(type, `[0].pl[0].fields[${index}].array.schema[${fieldIndex}]`)
  const choiceMap = _get(type, `[0].pl[0].fields[${index}].choices`)
  const selectMap = _get(type, `[0].pl[0].fields[${index}].array.schema[${fieldIndex}].choices`, choiceMap)
  const [num, setNum] = useState(2)
  const handleType = (e, field) => {
    const test = [...type]
    const fieldInd = test[0].pl[0].fields[index]
    const valueType = e.target.value
    const arrIndex = _get(test, `[0].pl[0].fields[${index}].array.schema[${fieldIndex}]`)
    switch (field) {
      case 'label':
        if (arrObj) {
          arrIndex.label = valueType
        } else {
          fieldInd.label = valueType
        }
        break
      case 'placeholder':
        if (arrObj) {
          arrIndex.placeholder = valueType
        } else {
          fieldInd.placeholder = valueType
        }
        break
      case 'width':
        if (arrObj) {
          arrIndex.width = +valueType
        } else {
          fieldInd.width = +valueType
        }
        break
      case 'height':
        if (arrObj) {
          arrIndex.height = +valueType
        } else {
          fieldInd.height = +valueType
        }
        break
      default:
        break
    }

    dispatch(setForm(test))
  }
  const handleSelectfield = (i, e) => {
    const test = [...type]
    if (!arrObj) {
      test[0].pl[0].fields[index].choices[i] = e.target.value
    } else {
      test[0].pl[0].fields[index].array.schema[fieldIndex].choices[i] = e.target.value
    }
    dispatch(setForm(test))
  }
  const handleSwitch = (e) => {
    const test = [...type]
    test[0].pl[0].fields[index].isShow = e.target.checked
    dispatch(setForm(test))
  }
  const deleteItem = () => {
    const test = [...type]
    const store = [...dataStore]
    const dataList = [...listStore]
    const arrobj = [...arrOfObjStore]
    if (arrObj) {
      if (isUpdate) {
        if (test[0].type === 'list') {
          arrobj.map((x) => x.map((y) => y.map((z) => z.splice(fieldIndex, 1))))
        } else {
          arrobj.splice(fieldIndex, 1)
        }
        test[0].pl[0].fields[index].array.schema.splice(fieldIndex, 1)
        dispatch(arrObjStore(arrobj))
        dispatch(setForm(test))
      }
    } else if (!arrObj) {
      if (isUpdate) {
        if (test[0].type === 'list') {
          dataList.map((x) => x.splice(index, 1))
          dispatch(setListData(dataList))
        } else {
          store.splice(index, 1)
          dispatch(setStoreData(store))
        }
      }
      test[0].pl[0].fields.splice(index, 1)
      dispatch(setForm(test))
    }
  }
  const deleteSelect = (i) => {
    const test = [...type]
    if (arrObj) {
      test[0].pl[0].fields[index].array.schema[fieldIndex].choices.splice(i, 1)
      setNum(i + 1)
    } else {
      test[0].pl[0].fields[index].choices.splice(i, 1)
      setNum(i + 1)
    }
    dispatch(setForm(test))
  }
  const addSelect = (i) => {
    const test = [...type]
    if (arrObj) {
      test[0].pl[0].fields[index].array.schema[fieldIndex].choices.push('')
      setNum(i + 1)
    } else {
      test[0].pl[0].fields[index].choices.push('')
      setNum(i + 1)
    }
    dispatch(setForm(test))
  }
  const classes = useStyles()
  return (
    <Box className={classes.boxContainer}>
      { value === 'image' && (
      <>
        <Box className={classes.lastBox}>
          <Typography variant="h6">{value}</Typography>
          <IconButton onClick={deleteItem}>
            <DeleteIcon color="primary" fontSize="small" style={{ color: 'gray' }} />
          </IconButton>
        </Box>

        <Box mt={1} className={classes.boxRow}>
          <Box className={classes.box}>
            <Typography>label:</Typography>
            <TextField variant="outlined" value={arrObj ? fieldData.label : typeForm.fields[index].label} className={classes.textfield} onChange={(e) => handleType(e, 'label')} required />
          </Box>
          <Box className={classes.box}>
            <Typography>Placeholder:</Typography>
            <TextField variant="outlined" value={arrObj ? fieldData.placeholder : typeForm.fields[index].placeholder} className={classes.textfield} onChange={(e) => handleType(e, 'placeholder')} />
          </Box>
        </Box>

        <Box mt={1} className={classes.boxRow}>
          <Box className={classes.box}>
            <Typography>width:</Typography>
            <TextField variant="outlined" value={arrObj ? fieldData.width : typeForm.fields[index].width} type="number" className={classes.textfield} onChange={(e) => handleType(e, 'width')} />
          </Box>
          <Box className={classes.box}>
            <Typography>height:</Typography>
            <TextField variant="outlined" value={arrObj ? fieldData.height : typeForm.fields[index].height} type="number" className={classes.textfield} onChange={(e) => handleType(e, 'height')} />
          </Box>

        </Box>
        <Box className={`${type[0].type === 'page' ? classes.lastButton : classes.lastBox}`}>
          {(type[0].type === 'list' && !arrObj) && (
          <Box mt={1} className={classes.boxSwitch}>
            <Typography>isShow:</Typography>
            <Typography style={{ marginLeft: '24px' }}>False</Typography>
            <Switch color="primary" checked={typeForm.fields[index].isShow} onChange={handleSwitch} />
            <Typography>True</Typography>
          </Box>
          )}
        </Box>

        <Box mt={5} />
        <hr
          style={{
            width: '100%', border: 'none', borderBottom: '1px solid gainsboro', boxShadow: 'none',
          }}
        />
      </>
      )}
      {(value === 'number' || value === 'array' || value === 'richtext' || value === 'simtext' || value === 'link' || value === 'infotext' || value === 'uid' || value === 'embed' || value === 'file' || value === 'boolean' || value === 'color' || value === 'date' || value === 'timestamp' || value === 'geoPoint') && (
      <Box>
        <Box className={classes.lastBox}>
          <Typography variant="h6">{value}</Typography>
          <IconButton onClick={deleteItem}>
            <DeleteIcon color="primary" fontSize="small" style={{ color: 'gray' }} />
          </IconButton>
        </Box>
        <Box mt={1} className={classes.boxRow}>
          <Box className={classes.box}>
            <Typography>label:</Typography>
            <TextField variant="outlined" value={arrObj ? fieldData.label : typeForm.fields[index].label} className={classes.textfield} onChange={(e) => handleType(e, 'label')} />
          </Box>
          <Box className={classes.box}>
            <Typography>Placeholder:</Typography>
            <TextField variant="outlined" value={arrObj ? fieldData.placeholder : typeForm.fields[index].placeholder} className={classes.textfield} onChange={(e) => handleType(e, 'placeholder')} />
          </Box>
        </Box>
        <Box className={`${type[0].type === 'page' ? classes.lastButton : classes.lastBox}`}>
          {(type[0].type === 'list' && !arrObj) && (
          <Box mt={1} className={classes.boxSwitch}>
            <Typography>isShow:</Typography>
            <Typography style={{ marginLeft: '24px' }}>False</Typography>
            <Switch color="primary" checked={typeForm.fields[index].isShow} onChange={handleSwitch} />
            <Typography>True</Typography>
          </Box>
          )}
        </Box>
        <Box mt={5} />
        <hr
          style={{
            width: '100%', border: 'none', borderBottom: '1px solid gainsboro', boxShadow: 'none',
          }}
        />
      </Box>
      )}
      {value === 'select' && (
        <>
          <Box className={classes.lastBox}>
            <Typography variant="h6">{value}</Typography>
            <IconButton onClick={deleteItem}>
              <DeleteIcon color="primary" fontSize="small" style={{ color: 'gray' }} />
            </IconButton>
          </Box>
          <Box mt={1} className={classes.boxRow}>
            <Box className={classes.box}>
              <Typography>label:</Typography>
              <TextField variant="outlined" value={arrObj ? fieldData.label : typeForm.fields[index].label} className={classes.textfield} onChange={(e) => handleType(e, 'label')} />
            </Box>
            <Box className={classes.box}>
              <Typography>Placeholder:</Typography>
              <TextField variant="outlined" value={arrObj ? fieldData.placeholder : typeForm.fields[index].placeholder} className={classes.textfield} onChange={(e) => handleType(e, 'placeholder')} />
            </Box>
          </Box>
          <Box className={`${type[0].type === 'page' ? classes.lastButton : classes.lastBox}`}>
            {(type[0].type === 'list' && !arrObj) && (
            <Box mt={1} className={classes.boxSwitch}>
              <Typography>isShow:</Typography>
              <Typography style={{ marginLeft: '24px' }}>False</Typography>
              <Switch color="primary" checked={typeForm.fields[index].isShow} onChange={handleSwitch} />
              <Typography>True</Typography>
            </Box>
            )}
          </Box>
          <Box mt={2}>
            <Typography>Choices</Typography>
          </Box>
            { selectMap.map((selectValue, i) => {
              const selectId = `selectID${i}`
              return (
                <Box mt={2} key={selectId} className={classes.lastBox}>
                  <TextField fullWidth placeholder={`Select ${i + 1}`} value={selectValue} color="secondary" onChange={(e) => handleSelectfield(i, e)} />
                  <IconButton disabled={selectMap.length < 2} onClick={() => deleteSelect(i)}>
                    <CloseIcon color={selectMap.length < 2 ? 'secondary' : 'disabled'} fontSize="small" />
                  </IconButton>
                </Box>
              )
            }) }

          <Box mt={1}>
            <Button className={classes.addSelect} color="primary" onClick={() => addSelect(num)}>Add Choice</Button>
          </Box>
          <Box mt={5} />

          <hr
            style={{
              width: '100%', border: 'none', borderBottom: '1px solid gainsboro', boxShadow: 'none',
            }}
          />
        </>
      )}
    </Box>
  )
}

export default Formcreate
Formcreate.propTypes = {
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  arrObj: PropTypes.bool.isRequired,
  fieldIndex: PropTypes.number.isRequired,
}
