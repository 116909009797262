import React, {
  useState,
  useEffect,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import _get from 'lodash/get'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import TableWithSort from '../../../components/TableWithSort'
import CreateCompany from './CreateCompany'
import { useFetchAllCompany, useFetchACompany, useSaveACompany } from './api'

const useStyles = makeStyles({
  createBtnGrid: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
})

const columns = [
  {
    id: 'updateAt', numeric: false, label: 'Updated', canSort: true,
  },
  {
    id: 'name', numeric: false, label: 'Name', canSort: true,
  },
  {
    id: 'status', numeric: false, label: 'Status', canSort: true,
  },
]

function Company(props) {
  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState('create')

  // QueryString
  const [query, setQuery] = useState('')
  const rowsPerPageOptions = [50, 100, 250]
  const queryStr = _get(props, 'queryString')
  const parsed = queryString.parse(queryStr, { arrayFormat: 'comma', parseNumbers: true })
  const [queryObj, setQueryObj] = useState({
    page: _get(parsed, 'page', 0),
    rowsperpage: _get(parsed, 'rowsperpage', rowsPerPageOptions[0]),
    order: _get(parsed, 'order', 'desc'),
    orderby: _get(parsed, 'orderby', 'updateAt'),
    lindex: _get(parsed, 'lindex', ''),
    findex: _get(parsed, 'findex', ''),
    pageDirection: '',
  })

  const { loading, rows, numberOfDocs } = useFetchAllCompany(
    query,
    queryObj.page,
    queryObj.rowsperpage,
    queryObj.order,
    queryObj.orderby,
    queryObj.findex,
    queryObj.lindex,
    queryObj.pageDirection,
  )

  const handleRequestSort = (event, property) => {
    const isAsc = queryObj.orderby === property && queryObj.order === 'asc'
    setQueryObj({
      ...queryObj,
      order: isAsc ? 'desc' : 'asc',
      orderby: property,
      page: 0,
      lindex: '',
      findex: '',
    })
  }

  const handleChangePage = (event, newPage) => {
    const changePageDirection = newPage > queryObj.page ? 'after' : 'before'
    setQueryObj({
      ...queryObj,
      page: newPage,
      pageDirection: changePageDirection,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setQueryObj({
      ...queryObj,
      page: 0,
      lindex: '',
      findex: '',
      rowsperpage: parseInt(event.target.value, 10),
    })
  }

  const [companyId, setCompanyId] = useState('')
  const {
    loadingCompany,
    companyData,
    setCompanyData,
  } = useFetchACompany(companyId)

  const handleChange = (name, value) => {
    setCompanyData({
      ...companyData,
      [name]: value,
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = (modeStr, comId = '') => {
    setOpen(true)
    setCompanyId(modeStr === 'create' ? '' : comId)
    setMode(modeStr)
  }

  const [saveType, setSaveType] = useState('')
  const { loadingSave, saveStatus, setStatus } = useSaveACompany(saveType, companyData, companyId)
  const handleSubmit = () => {
    setSaveType(mode)
  }

  const actionList = [
    {
      id: 'edit',
      label: 'Edit',
      isLink: false,
      clickFunction: (uid) => handleOpen('edit', uid),
      warnMenu: false,
      icon: <EditIcon />,
      // prefixUrl: '/#',
    },
  ]

  useEffect(() => {
    setSaveType('')
    if (saveStatus.code === 200) {
      setQuery('after save')
      setOpen(false)
      setCompanyId('')
      setMode('create')
      setStatus({
        code: '',
        message: '',
      })
      setCompanyData({
        name: '',
        status: false,
      })
      setQueryObj({
        ...queryObj,
        page: 0,
        lindex: '',
        findex: '',
        pageDirection: '',
      })
    } else if (!saveStatus.code) {
      setQuery('first load')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveStatus.code])

  // update findex, lindex
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      findex: _get(rows, '[0].uid'),
      lindex: _get(rows, `[${rows.length - 1}].uid`),
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  // update querystring
  useEffect(() => {
    history.push({
      search: queryString.stringify(queryObj, { arrayFormat: 'comma', skipNull: true, skipEmptyString: true }),
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryObj.page,
    queryObj.rowsperpage,
    queryObj.order,
    queryObj.orderby,
    queryObj.findex,
    queryObj.lindex,
  ])

  return (
    <div>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.createBtnGrid}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen('create')}
          >
            Create Company
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>
        <TableWithSort
          columns={columns}
          rows={rows}
          actions={actionList}
          isLoading={loading}
          handleRequestSort={handleRequestSort}
          orderBy={queryObj.orderby}
          order={queryObj.order}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={queryObj.rowsperpage}
          page={queryObj.page}
          numberOfDocs={numberOfDocs}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <CreateCompany
        mode={mode}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        data={companyData}
        onChange={handleChange}
        loading={loadingCompany || loadingSave}
      />
    </div>
  )
}

export default Company
