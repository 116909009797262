import _get from 'lodash/get'
import _map from 'lodash/map'
import _camelCase from 'lodash/camelCase'

function createDefaultInputValue(fields, lang) {
  const result = {}
  const now = new Date()
  const langObj = {}
  _map(lang, (l) => {
    langObj[l] = ''
  })

  _map(fields, (field) => {
    let value = ''
    if (field.type === 'simtext' || field.type === 'richtext') {
      value = langObj
    } else if (field.type === 'geoPoint') {
      value = { lat: '', lng: '' }
    } else if (field.type === 'date') {
      value = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`
    } else if (field.type === 'timestamp') {
      value = now
    } else if (field.type === 'array') {
      value = []
    } else if (field.type === 'arrayOfObj') {
      const arrFields = _get(field, 'array.schema')
      const defaultArrValue = createDefaultInputValue(arrFields, lang)
      value = [defaultArrValue]
    }
    result[_camelCase(field.label)] = value
  })
  return result
}

export default createDefaultInputValue
