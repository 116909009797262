import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _get from 'lodash/get'
import _head from 'lodash/head'
import _kebabCase from 'lodash/kebabCase'
import _camelCase from 'lodash/camelCase'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import Desktop from './Desktop'
import Mobile from './Mobile'
import Footer from '../Footer'
import { getUser } from '../../redux/selectors/user.selectors'
import s from './ProjectLayout.module.scss'
import { loadTableData } from '../../redux/actions/list.action'
import FirebaseProject from '../../utils/FirebaseProject/FirebaseProject'
import * as projectAction from '../../redux/actions/project.actions'
import { clearForm } from '../../redux/actions/form.action'

function ProjectLayout(props) {
  const history = useHistory()
  const { children } = props
  const { computedMatch } = children.props
  const projectId = _get(computedMatch, 'params.projectId')
  const pageOrList = _get(computedMatch, 'params.pageId') ? 'page' : 'list'
  const activeId = pageOrList === 'page' ? _get(computedMatch, 'params.pageId') : _get(computedMatch, 'params.listId')
  let pageStatus = false
  const urlParams = _get(computedMatch, 'params')
  if (computedMatch.path.length === 32
    || computedMatch.path === '/project/:projectId/categories/:listId') {
    pageStatus = false
  } else {
    pageStatus = true
  }
  const [pageData, setPageData] = useState([])
  const [pageList, setPageList] = useState([])
  const [projectData, setProjectData] = useState({})
  const user = useSelector(getUser)

  const dispatch = useDispatch()

  const loadingFunc = () => {
    dispatch(loadTableData())
    dispatch(clearForm())
  }

  const loadData = async () => {
    if (user) {
      dispatch(projectAction.updateStatusLoading(true))
      try {
        const userType = _get(user, 'userData.type')
        const docProjectID = userType === 'enduser' ? _get(user, 'userData.projectId', '') : _get(user, 'userData.companyId', '')

        const dataProject = await FirebaseProject.getProject(userType, docProjectID)
        dataProject.forEach((result) => {
          const data = result.data()
          if ((result.id.trim()) === _camelCase(projectId)) {
            const currentDataProject = { ...data, uid: result.id.trim() }
            dispatch(projectAction.setProject(currentDataProject, history, urlParams))
            setProjectData(currentDataProject)
            setPageData(_get(data, 'schema.page', []))
            setPageList(_get(data, 'schema.list', []))
          }
        })
        dispatch(projectAction.updateStatusLoading(false))
      } catch (error) {
        dispatch(projectAction.getErrorMessageProject(error))
      }
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, dispatch, user])

  const pages = [
    pageData.map((data) => (
      {
        id: data.name,
        active: pageOrList === 'page' && _kebabCase(data.name) === activeId,
      }
    )),
  ]
  const lists = [
    pageList.map((data) => (
      {
        id: data.name,
        active: pageOrList === 'list'
          && _kebabCase(data.name) === activeId,
        isShowTags: !!(data?.isShowTags),
        activeTags: pageOrList === 'list'
          && _kebabCase(data.name) === activeId
          && computedMatch.path === '/project/:projectId/tags/:listId',
      }
    )),
  ]

  return (
    <div className={s.root}>
      <Hidden smDown>
        <nav className={s.drawer}>
          <Desktop
            data={projectData}
            pages={_head(pages)}
            lists={_head(lists)}
            loadingFunc={loadingFunc}
            pageStatus={pageStatus}
          />
        </nav>
      </Hidden>
      <Hidden mdUp>
        <Mobile
          data={projectData}
          pages={_head(pages)}
          lists={_head(lists)}
          loadingFunc={loadingFunc}
          pageStatus={pageStatus}
        />
      </Hidden>
      <div className={s.app}>
        <main className={s.main}>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

ProjectLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProjectLayout
