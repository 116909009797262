import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import format from 'date-fns/format'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _size from 'lodash/size'
import LoadingRow from '../Table/LoadingRow'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  paper: {
    overflow: 'hidden',
    width: '100%',
  },
  active: {
    color: 'green',
    borderColor: 'green',
  },
  inactive: {
    color: 'red',
    borderColor: 'red',
  },
})

function TablePage(props) {
  const classes = useStyles()

  const {
    columns,
    rows,
    isLoading,
  } = props

  const loadingMock = [...Array(10).keys()]
  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {_map(columns, (column, i) => (
                <StyledTableCell key={`columns${i}`}>{column.label}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              _map(loadingMock, (row, i) => (
                <LoadingRow
                  columns={columns}
                  key={i}
                />
              ))
            ) : (
              <>
                {
                  _size(rows) > 0 ? (
                    _map(rows, (row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Chip
                            label={_get(row, 'isPublic') ? 'Published' : 'Draft'}
                            variant="outlined"
                            className={clsx(_get(row, 'isPublic') ? classes.active : classes.inactive)}
                          />
                        </TableCell>
                        <TableCell>
                          {
                            format(
                              new Date(
                                _get(row, 'createdAt.seconds') * 1000,
                              ),
                              'yyyy/MM/dd HH:mm',
                            )
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow style={{ height: 80 }} component="div">
                      <TableCell
                        colSpan={_size(columns) + 1}
                        style={{ textAlign: 'center' }}
                        component="div"
                      >
                        <Typography variant="body1">
                          No data available in table.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

TablePage.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
}
TablePage.defaultProps = {
  isLoading: false,
}

export default TablePage
