import React, { useEffect, useState } from 'react'
import _camelCase from 'lodash/camelCase'
import _size from 'lodash/size'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { withStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import {
  format,
  fromUnixTime,
} from 'date-fns'
import { Link } from 'react-router-dom'
import { Typography, Box } from '@material-ui/core'
import DateRangePicker from '../../components/DateRangePicker'
import TableProjectPage from '../../components/TableProjectPage'
import s from './PageTable.module.scss'
import firebase from '../../firebase'
import AlertBar from '../../components/NoticeMessage/SnackBar'

const styles = (theme) => ({
  textField: {
    [theme.breakpoints.down('sm')]: {
      height: '48px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '38px',
    },
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  box: {
    width: '90vw',
  },
})

const columns = [
  { id: 'pages', label: 'Pages' },
  { id: 'order', label: 'Order' },
  { id: 'status', label: 'Status' },
  { id: 'createdAt', label: 'Date Created' },
]

function ProjectPageTable(props) {
  const pathProject = _get(props, 'computedMatch.params.projectId')

  const [tableLength, setTableLength] = useState(0)
  const [csv, setCsv] = useState([])
  const FILE_NAME_DATA = `${pathProject}.csv`
  // Table
  const [loading, setLoading] = useState(true)
  const [rowData, setRowData] = useState([])

  const [date, setDateObj] = useState({
    start: null,
    end: null,
    error: true,
  })

  const handleChangeDate = (data) => {
    setDateObj(data)
  }

  const queryData = async (data, dateStart, dateEnd) => {
    setLoading(true)
    let output = data
    if (date.start !== null && date.end !== null) {
      const secondStart = dateStart.getTime() / 1000
      const secondEnd = dateEnd.getTime() / 1000
      output = _filter(
        data,
        (page) => (_get(page, 'createdAt.seconds')) >= secondStart && (_get(page, 'createdAt.seconds')) <= secondEnd,
      )
    }
    setRowData(output)
    return output
  }

  const getTableData = async () => {
    setLoading(true)
    try {
      const data = await firebase.firestore().collection('projects').doc(_camelCase(pathProject)).get()
      const schemaPage = _get(data.data(), 'schema.page')
      setRowData(schemaPage)
      const csvArray = []
      const pushRow = await queryData(schemaPage, date.start, date.end)
      if (!_isEmpty(pushRow)) {
        pushRow.map((sub, index) => {
          // updatedAt field
          csvArray[index] = {
            ...pushRow[index],
            isPublic: `${pushRow[index].isPublic}`,
            createdAt: format(fromUnixTime(_get(sub, 'createdAt.seconds')), 'yyyy-MM-dd HH:mm:ss'),
          }
          return csvArray
        })
      }
      setCsv(csvArray)
      setTableLength(_size(schemaPage))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // using filter in the page
  useEffect(() => {
    getTableData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.end])

  // when changing to the new page
  useEffect(() => {
    setDateObj({
      start: null,
      end: null,
      error: true,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathProject])

  return (
    <>
      <AlertBar />
      <Typography variant="h4">
        Pages
      </Typography>
      <Box mb={2} className={s.buttonCreate}>
        <Box className={s.btIcon}>
          <Box className={s.boxItem}>
            <CSVLink data={csv} filename={FILE_NAME_DATA}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<SaveAltIcon />}
              >
                Export
              </Button>
            </CSVLink>
          </Box>
          <Box className={s.btIcon}>
            <Link
              to={`/project/${pathProject}/create-form`}
              style={{ textDecoration: 'none' }}
              className={s.button}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<AddCircleOutlineIcon />}
                fullWidth
              >
                CREATE NEW PAGE
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box mb={3} display="flex" className={s.box}>
        <Box className={s.boxItem}>
          <DateRangePicker
            onChange={handleChangeDate}
            value={date}
          />
        </Box>
        <Box className={s.lBox}>
          <Typography className={s.numItem}>
            {`${tableLength} Item`}
          </Typography>
        </Box>
      </Box>
      <Box className={s.boxtable}>
        <TableProjectPage
          columns={columns}
          rows={loading ? [] : rowData}
          isLoading={loading}
        />
      </Box>
    </>
  )
}

export default withStyles(styles)(ProjectPageTable)
