import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _isEmpty from 'lodash/isEmpty'
import showMessage from '../../redux/selectors/alert.selectors'
import { clear } from '../../redux/actions/alert.actions'

const CssSnackbar = withStyles({
  // style of sanckbar
  root: {
    '& .MuiAlert-standardSuccess': {
      color: '#ffffff',
      background: '#4caf50',
      '& svg': {
        color: '#ffffff',
      },
    },
    '& .MuiAlert-standardError': {
      color: '#ffffff',
      background: '#e53935',
      '& svg': {
        color: '#ffffff',
      },
    },
    '& .MuiAlert-standardWarning': {
      color: '#ffffff',
      background: '#ff9800',
      '& svg': {
        color: '#ffffff',
      },
    },
  },

})(Snackbar)

function AlertBar() {
  const key = useSelector(showMessage)
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()

  async function setOpenCssSnackBar() {
    setOpen(true)
  }

  useEffect(() => {
    if (!_isEmpty(key.keyAlert) && !_isEmpty(key.messageAlert)) {
      setOpenCssSnackBar()
      setTimeout(() => {
        dispatch(clear())
      }, 5000)
    }
  }, [key, dispatch])

  return (
    <>
      <Box>
        <CssSnackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            open={open}
            severity={key.keyAlert}
          >
            {key.messageAlert}
          </Alert>
        </CssSnackbar>
      </Box>
    </>
  )
}

export default AlertBar
