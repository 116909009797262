import React, {
  useState,
  useEffect,
} from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import format from 'date-fns/format'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _startCase from 'lodash/startCase'
import Chip from '@material-ui/core/Chip'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'
import PetsIcon from '@material-ui/icons/Pets'
import axios from 'axios'
import firebase from '../../../firebase'

const useStyles = makeStyles({
  active: {
    color: 'green',
    borderColor: 'green',
  },
  inactive: {
    color: 'red',
    borderColor: 'red',
  },
  list: {
    padding: 0,
  },
  listIcon: {
    minWidth: 0,
    marginRight: 8,
  },
  listText: {
    '& span': {
      fontSize: '0.875rem',
    },
  },
})

const useFetchAllUser = (
  query, page, rowsperpage = 50, order, orderBy, findex, lindex, direction, company,
) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [numberOfDocs, setNumberOfDocs] = useState(0)

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true)
      try {
        let url = `${process.env.REACT_APP_API_AUTH}/user?rowsperpage=${rowsperpage}`
        if (!_isEmpty(order)) {
          url = `${url}&order=${order}`
        }
        if (!_isEmpty(orderBy)) {
          url = `${url}&orderBy=${orderBy}`
        }
        if (!_isEmpty(page)) {
          url = `${url}&page=${page}`
        }
        if (!_isEmpty(page)) {
          url = `${url}&page=${page}`
        }
        if (!_isEmpty(direction) && !_isEmpty(findex) && !_isEmpty(lindex)) {
          url = `${url}&direction=${direction}&findex=${findex}&lindex=${lindex}`
        }
        if (!_isEmpty(company)) {
          url = `${url}&company=${company}`
        }

        const getAPI = await axios.get(url)
        const user = _get(getAPI, 'data.data', [])
        const output = _map(user, (item) => {
          const obj = item
          const status = (
            <Chip
              label={_get(obj, 'status') ? 'Active' : 'Inactive'}
              variant="outlined"
              className={clsx(_get(obj, 'status') ? classes.active : classes.inactive)}
            />
          )
          const updatedAt = format(
            new Date(
              _get(obj, 'updatedAt._seconds') * 1000,
            ),
            'yyyy/MM/dd HH:mm',
          )

          let icon = <PetsIcon />
          let text = 'Foxbith'
          if (_get(obj, 'type') === 'enduser') {
            icon = <PersonIcon />
            text = _startCase(_get(obj, 'projectId.name', ''))
          } else if (_get(obj, 'type') === 'company') {
            icon = <BusinessIcon />
            text = _startCase(_get(obj, 'companyId.name', ''))
          }

          const type = (
            <ListItem className={classes.list}>
              <ListItemAvatar className={classes.listIcon}>
                {icon}
              </ListItemAvatar>
              <ListItemText
                className={classes.listText}
                primary={text}
              />
            </ListItem>
          )
          return {
            uid: item.id,
            name: _startCase(_get(obj, 'name')),
            status,
            updatedAt,
            email: _get(obj, 'email'),
            type,
            role: _startCase(_get(obj, 'role')),
            lastSignInTime: _get(obj, 'lastSignInTime') || 'Never',
          }
        })

        let numberData = await firebase
          .firestore()
          .collection('collectionData')
          .doc('users')
          .get()
        if (numberData.exists) {
          numberData = numberData.data()
          numberData = _get(numberData, 'number')
          setNumberOfDocs(numberData)
        }

        setData(output)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }

    if (query) {
      getUsers()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    rowsperpage,
    order,
    orderBy,
    page,
    company,
  ])

  return {
    loading,
    rows: data,
    filterRows: data,
    numberOfDocs,
  }
}

const useFetchAUser = (userId) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    type: '',
    permission: '',
    companyId: '',
    projectId: '',
    status: false,
  })
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const snapshot = await axios.get(`${process.env.REACT_APP_API_AUTH}/user/${userId}`)
        const docData = _get(snapshot, 'data.data')

        let output = {
          name: '',
          email: '',
          password: '',
          type: '',
          permission: '',
          companyId: '',
          projectId: '',
          status: false,
        }
        if (!_isEmpty(docData)) {
          output = {
            name: _get(docData, 'name', ''),
            email: _get(docData, 'email', ''),
            type: _get(docData, 'type', ''),
            permission: _get(docData, 'role', ''),
            companyId: _get(docData, 'companyId', ''),
            projectId: _get(docData, 'projectId', ''),
            status: _get(docData, 'status', false),
          }
        }
        setData(output)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }

    if (_isEmpty(userId)) {
      setData({
        name: '',
        email: '',
        password: '',
        type: '',
        permission: '',
        companyId: '',
        projectId: '',
        status: false,
      })
    } else {
      fetchData()
    }
  }, [userId])

  return {
    loadingUser: loading,
    userData: data,
    setUserData: setData,
  }
}

const useSaveAUser = (saveType, data, uid) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState({
    code: '',
    message: '',
  })

  useEffect(() => {
    if (_isEmpty(saveType)) return

    const saveData = async () => {
      setLoading(true)
      const saveObj = {
        ...data,
        companyId: _get(data, 'companyId.uid', ''),
        projectId: _get(data, 'projectId.uid', ''),
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
      }

      try {
        if (saveType === 'create' && _isEmpty(uid)) {
          await axios.post(`${process.env.REACT_APP_API_AUTH}/user`, {
            ...saveObj,
            createAt: firebase.firestore.FieldValue.serverTimestamp(),
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
        } else {
          await axios.put(`${process.env.REACT_APP_API_AUTH}/user/${uid}`, saveObj)
        }
        setStatus({
          code: 200,
          message: 'Save successfully.',
        })
      } catch (error) {
        setStatus({
          code: 500,
          message: error,
        })
        // console.log(error.response.data)
      } finally {
        setLoading(false)
      }

      setLoading(false)
    }
    saveData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveType])

  return {
    loadingSave: loading,
    saveStatus: status,
    setStatus,
  }
}

const useFetchCompanyList = (query) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const snapshots = await firebase.firestore().collection('company').where('status', '==', true).get()

      const output = _map(snapshots.docs, (item) => {
        const objData = item.data()

        return {
          uid: item.id,
          ...objData,
        }
      })

      setData(output)
      setLoading(false)
    }

    if (query === 'fetch') {
      fetchData()
    } else {
      setData([])
    }
  }, [
    query,
  ])

  return {
    companyLoading: loading,
    companyList: data,
  }
}

const useFetchProjectList = (query) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const snapshots = await firebase.firestore()
        .collection('projects')
        .where('status', '==', true)
        .get()

      const output = _map(snapshots.docs, (item) => {
        const objData = item.data()

        return {
          uid: item.id,
          ...objData,
        }
      })

      setData(output)
      setLoading(false)
    }

    if (query === 'fetch') {
      fetchData()
    } else {
      setData([])
    }
  }, [
    query,
  ])

  return {
    projectLoading: loading,
    projectList: data,
  }
}

export {
  useFetchAllUser,
  useFetchAUser,
  useSaveAUser,
  useFetchCompanyList,
  useFetchProjectList,
}
