import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  autocomplete: {
    marginBottom: '16px',
  },
  card: {
    padding: '16px',
    marginBottom: '16px',
  },
  gridItem: {
    gap: '8px',
  },
}))

export default function Categories(props) {
  const classes = useStyles()
  const {
    // label,
    categories,
    handleOnChange,
    value,
  } = props

  return (
    <>
      <Typography
        className={classes.title}
        variant="h6"
      >
        Tags
      </Typography>
      <Autocomplete
        filterSelectedOptions
        fullWidth
        multiple
        value={value}
        options={categories}
        disableCloseOnSelect
        id="categories-option"
        className={classes.autocomplete}
        getOptionLabel={(data) => data?.name}
        onChange={handleOnChange}
        renderOption={(data) => (
          <>
            {data?.name}
          </>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="categories-option"
            variant="outlined"
            placeholder="tag"
          />
        )}
      />
    </>
  )
}

Categories.propTypes = {
  label: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object),
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
}

Categories.defaultProps = {
  label: '',
  categories: [{}],
  value: [],
}
