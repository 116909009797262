import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import WebIcon from '@material-ui/icons/Web'
import Box from '@material-ui/core/Box'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded'
import { Link } from 'react-router-dom'
import _kebabCase from 'lodash/kebabCase'
import _upperCase from 'lodash/upperCase'
import _get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import { resetForm, setForm } from '../../redux/actions/createform.action'
import { getCreateForm } from '../../redux/selectors/createform.selector'
import { getUser } from '../../redux/selectors/user.selectors'
import firebase from '../../firebase'
import { logout } from '../../redux/actions/user.actions'

const styles = (theme) => ({
  appbar: {
    backgroundColor: '#000',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  itemWh: {
    color: '#fff',
  },
  activeIcon: {
    color: '#fc4c02',
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  listSpace: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  itemCategory: {
    backgroundColor: '#000000',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  lastitemCategory: {
    backgroundColor: '#000000',
    boxShadow: '0 0 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  logo: {
    margin: 'auto',
    maxHeight: 40,
    maxWidth: '100%',
    height: 50,
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#fc4c02',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  projectSelect: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemIcon: {
    color: '#fff',
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  itemLogout: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  itemIconLogout: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  menu: {
    width: 300,
  },
  menuDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#d6d6d6',
  },
})

function Navigator(props) {
  const {
    classes,
    data,
    pages,
    lists,
    loadingFunc,
    pageStatus,
  } = props

  const [open, setOpen] = React.useState(false)

  const user = useSelector(getUser)

  const toggleDrawer = (value) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(value)
  }

  const dispatch = useDispatch()

  // const form = useSelector(getCreateForm)
  const dataForm = useSelector(getCreateForm)

  const handleLogout = async () => {
    firebase.auth().signOut().then(() => {
      dispatch(logout())
    })
  }
  const resetFormValue = () => {
    dispatch(resetForm())
  }
  const setList = () => {
    const type = [...dataForm]
    type[0].type = 'list'
    dispatch(setForm(type))
    setOpen(false)
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {_upperCase(data.name)}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <List disablePadding>
          <ListItem className={classes.itemCategory}>
            <img className={classes.logo} src={data.logo} alt={data.name} />
          </ListItem>
        </List>
        <List disablePadding>
          {_get(user, 'userData.type') === 'foxbith' ? (
            <Link
              to={`/project/${data.uid}/page-table`}
              style={{ textDecoration: 'none' }}
            >
              <ListItem
                className={classes.categoryHeader}
                onClick={resetFormValue}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <WebIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  Pages
                </ListItemText>
              </ListItem>
            </Link>
          ) : (
            <ListItem
              className={classes.categoryHeader}
              onClick={resetFormValue}
            >
              <ListItemIcon className={classes.itemIcon}>
                <WebIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                Pages
              </ListItemText>
            </ListItem>
          )}
          {pages.map(({ id: childId, active }) => (
            <React.Fragment key={childId}>
              {
                active
                  ? (
                    <ListItem
                      onClick={toggleDrawer(false)}
                      button
                      className={clsx(classes.item, active && classes.itemActiveItem)}
                    >
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      >
                        {childId}
                      </ListItemText>
                    </ListItem>
                  )
                  : (
                    <Link
                      to={`/project/${data.uid}/page/${_kebabCase(childId)}`}
                      style={{ textDecoration: 'none' }}
                      onClick={loadingFunc}
                    >
                      <ListItem
                        onClick={toggleDrawer(false)}
                        button
                        className={clsx(classes.item, active && classes.itemActiveItem)}
                      >
                        <ListItemText
                          classes={{
                            primary: classes.itemPrimary,
                          }}
                        >
                          {childId}
                        </ListItemText>
                      </ListItem>
                    </Link>
                  )
              }
            </React.Fragment>
          ))}
        </List>
        <Box mt={1} />
        <List disablePadding>
          {lists.map(({ id: childId, active }) => (
            <React.Fragment key={childId}>
              {
                (active && !pageStatus)
                  ? (
                    <ListItem
                      onClick={toggleDrawer(false)}
                      button
                      className={clsx(
                        classes.item, classes.categoryHeaderPrimary,
                        active && classes.itemActiveItem, classes.listSpace,
                      )}
                    >
                      <ListItemIcon className={clsx(classes.activeIcon)}>
                        <LocalMallIcon />
                      </ListItemIcon>
                      <ListItemText>
                        {childId}
                      </ListItemText>
                    </ListItem>
                  )
                  : (
                    <Link
                      to={`/project/${data.uid}/list/${_kebabCase(childId)}`}
                      style={{ textDecoration: 'none' }}
                      onClick={loadingFunc}
                    >
                      <ListItem
                        onClick={toggleDrawer(false)}
                        button
                        className={clsx(
                          classes.item, active && classes.itemActiveItem, classes.listSpace,
                        )}
                      >
                        <ListItemIcon className={clsx(classes.itemIcon)}>
                          <LocalMallIcon />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.categoryHeaderPrimary,
                          }}
                        >
                          {childId}
                        </ListItemText>
                      </ListItem>
                    </Link>
                  )
              }
            </React.Fragment>
          ))}
          {user.userData.type === 'foxbith' && (
            <Link to={`/project/${data.uid}/create-form`} style={{ textDecoration: 'none' }}>
              <ListItem
                className={clsx(classes.item, classes.listSpace)}
                onClick={setList}
              >
                <ListItemIcon className={clsx(classes.itemIcon)}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  Add new list
                </ListItemText>
              </ListItem>
            </Link>
          )}
          <Divider className={classes.divider} />
        </List>
        <Link
          to={`/project/${data.uid}/categories`}
          style={{ textDecoration: 'none' }}
        >
          <ListItem
            className={clsx(classes.item, classes.itemWh, classes.itemCategory)}
          >
            <ListItemIcon className={clsx(classes.itemIcon)}>
              <CategoryRoundedIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.catergoryHeaderPrimary,
              }}
            >
              Categories
            </ListItemText>
          </ListItem>
        </Link>
        <Link
          to={`/project/${data.uid}/media-page`}
          style={{ textDecoration: 'none' }}
        >
          <ListItem
            className={clsx(classes.item, classes.itemWh, classes.itemCategory)}
          >
            <ListItemIcon className={clsx(classes.itemIcon)}>
              <PermMediaIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.catergoryHeaderPrimary,
              }}
            >
              Media
            </ListItemText>
          </ListItem>
        </Link>
        <Divider />
        {user.userData.type !== 'enduser' && (
          <Link to="/" style={{ textDecoration: 'none' }}>
            <ListItem className={clsx(classes.item, classes.itemWh, classes.lastitemCategory)}>
              <ListItemIcon className={clsx(classes.itemIcon)}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                onClick={loadingFunc}
              >
                Back to all projects
              </ListItemText>
            </ListItem>
          </Link>
        )}
        <ListItem
          className={clsx(classes.item, classes.itemWh, classes.lastitemCategory)}
          onClick={handleLogout}
        >
          <ListItemIcon className={clsx(classes.itemIcon)}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Log out
          </ListItemText>
        </ListItem>
      </Drawer>
    </>
  )
}

Navigator.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
  })).isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
  })).isRequired,
  loadingFunc: PropTypes.func.isRequired,
  pageStatus: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Navigator)
