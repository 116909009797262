import _isString from 'lodash/isString'
import firebase from '../../firebase'

const db = firebase.firestore()
const FirebaseUser = {
  getCurrentUser(uid) {
    if (!_isString(uid)) {
      return Promise.reject(new Error('invalid params uid is type string'))
    }
    return new Promise((resolve, reject) => {
      db
        .collection('users')
        .doc(uid)
        .get()
        .then((result) => {
          try {
            resolve(result.data())
          } catch (TypeError) {
            reject(new Error('firebase store error'))
          }
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}

export default FirebaseUser
