import React from 'react'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = () => ({
  loadingBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
})

function ProjectDetail(props) {
  const { classes } = props

  return (
    <Box className={classes.loadingBox}>
      <Box>
        <Box mb={1}>
          <Skeleton variant="rect" height={20} />
        </Box>
        <Box mb={3}>
          <Skeleton variant="rect" height={80} />
        </Box>
      </Box>
      <Box>
        <Box mb={1}>
          <Skeleton variant="rect" height={20} />
        </Box>
        <Box mb={3}>
          <Skeleton variant="rect" height={80} />
        </Box>
      </Box>
      <Box>
        <Box mb={1}>
          <Skeleton variant="rect" height={20} />
        </Box>
        <Box mb={3}>
          <Skeleton variant="rect" height={100} />
        </Box>
      </Box>
      <Box>
        <Box mb={1}>
          <Skeleton variant="rect" height={20} />
        </Box>
        <Box mb={3}>
          <Skeleton variant="rect" height={100} />
        </Box>
      </Box>
    </Box>
  )
}

ProjectDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProjectDetail)
