import React, {
  useState,
  useEffect,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import _get from 'lodash/get'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import TableWithSort from '../../../components/TableWithSort'
import CreateUser from './CreateUser'
import {
  useFetchAllUser,
  useFetchAUser,
  useSaveAUser,
  useFetchCompanyList,
} from './api'

const useStyles = makeStyles({
  createBtnGrid: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
})

const columns = [
  {
    id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated', canSort: true,
  },
  {
    id: 'email', numeric: false, disablePadding: false, label: 'Email',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Name', canSort: true,
  },
  {
    id: 'type', numeric: false, disablePadding: true, label: 'Type', canSort: true,
  },
  {
    id: 'role', numeric: false, disablePadding: true, label: 'Permission', canSort: true,
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status', canSort: true,
  },
  {
    id: 'lastSignInTime', numeric: false, disablePadding: false, label: 'Last SignIn Time',
  },
]

function Users(props) {
  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState('create')

  // QueryString
  const [query, setQuery] = useState('')
  const rowsPerPageOptions = [50, 100, 250]
  const queryStr = _get(props, 'queryString')
  const parsed = queryString.parse(queryStr, { arrayFormat: 'comma', parseNumbers: true })
  const [queryObj, setQueryObj] = useState({
    page: _get(parsed, 'page', 0),
    rowsperpage: _get(parsed, 'rowsperpage', rowsPerPageOptions[0]),
    order: _get(parsed, 'order', 'desc'),
    orderby: _get(parsed, 'orderby', 'updatedAt'),
    lindex: _get(parsed, 'lindex', ''),
    findex: _get(parsed, 'findex', ''),
    pageDirection: '',
    company: _get(parsed, 'company', ''),
  })

  const { loading, rows, numberOfDocs } = useFetchAllUser(
    query,
    queryObj.page,
    queryObj.rowsperpage,
    queryObj.order,
    queryObj.orderby,
    queryObj.findex,
    queryObj.lindex,
    queryObj.pageDirection,
    queryObj.company,
  )

  // Get companies
  const [companyQuery, setCompanyQuery] = useState('')
  const { companyLoading, companyList } = useFetchCompanyList(companyQuery)

  const handleRequestSort = (event, property) => {
    const isAsc = queryObj.orderby === property && queryObj.order === 'asc'
    setQueryObj({
      ...queryObj,
      order: isAsc ? 'desc' : 'asc',
      orderby: property,
      page: 0,
      lindex: '',
      findex: '',
    })
  }

  const handleChangePage = (event, newPage) => {
    const changePageDirection = newPage > queryObj.page ? 'after' : 'before'
    setQueryObj({
      ...queryObj,
      page: newPage,
      pageDirection: changePageDirection,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setQueryObj({
      ...queryObj,
      page: 0,
      lindex: '',
      findex: '',
      rowsperpage: parseInt(event.target.value, 10),
    })
  }

  // handle open modal
  const [userId, setUserId] = useState('')
  const {
    loadingUser,
    userData,
    setUserData,
  } = useFetchAUser(userId)

  const handleChange = (name, value) => {
    setUserData({
      ...userData,
      [name]: value,
    })
    if (name === 'type' && value !== 'company' && userData.companyId !== '') {
      setUserData({
        ...userData,
        companyId: '',
        type: value,
      })
    }
    if (name === 'type' && value !== 'enduser' && userData.projectId !== '') {
      setUserData({
        ...userData,
        projectId: '',
        type: value,
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = (modeStr, comId = '') => {
    setOpen(true)
    setUserId(modeStr === 'create' ? '' : comId)
    setMode(modeStr)
  }

  // save data
  const [saveType, setSaveType] = useState('')
  const { loadingSave, saveStatus, setStatus } = useSaveAUser(saveType, userData, userId)
  const handleSubmit = () => {
    setSaveType(mode)
  }

  const actionList = [
    {
      id: 'edit',
      label: 'Edit',
      isLink: false,
      clickFunction: (uid) => handleOpen('edit', uid),
      warnMenu: false,
      icon: <EditIcon />,
    },
  ]

  useEffect(() => {
    setSaveType('')
    if (saveStatus.code === 200) {
      setQuery('after save')
      setOpen(false)
      setUserId('')
      setMode('create')
      setStatus({
        code: '',
        message: '',
      })
      setUserData({
        name: '',
        status: false,
      })
      setQueryObj({
        ...queryObj,
        page: 0,
        lindex: '',
        findex: '',
        pageDirection: '',
        company: '',
      })
    } else if (!saveStatus.code) {
      setQuery('first load')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveStatus.code])

  // update findex, lindex
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      findex: _get(rows, '[0].uid'),
      lindex: _get(rows, `[${rows.length - 1}].uid`),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  // update querystring
  useEffect(() => {
    history.push({
      search: queryString.stringify(queryObj, { arrayFormat: 'comma', skipNull: true, skipEmptyString: true }),
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryObj.page,
    queryObj.rowsperpage,
    queryObj.order,
    queryObj.orderby,
    queryObj.findex,
    queryObj.lindex,
    queryObj.company,
  ])
  return (
    <div>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="companySearchList"
            options={companyList}
            getOptionLabel={(option) => _get(option, 'name', '')}
            onChange={(event, newValue) => (
              setQueryObj({
                ...queryObj,
                company: _get(newValue, 'uid', ''),
              })
            )}
            filterSelectedOptions
            loading={companyLoading}
            onOpen={() => setCompanyQuery('fetch')}
            onClose={() => setCompanyQuery('clear')}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by company"
                placeholder="Select some companies"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.createBtnGrid}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen('create')}
          >
            Create Account
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>
        <TableWithSort
          columns={columns}
          rows={rows}
          actions={actionList}
          isLoading={loading}
          handleRequestSort={handleRequestSort}
          orderBy={queryObj.orderby}
          order={queryObj.order}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={queryObj.rowsperpage}
          page={queryObj.page}
          numberOfDocs={numberOfDocs}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <CreateUser
        mode={mode}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        data={userData}
        onChange={handleChange}
        loading={loadingUser || loadingSave}
      />
    </div>
  )
}

export default Users
