import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
})

function NumberField(props) {
  const {
    classes,
    label,
    placeholder,
    value,
    handleOnChange,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <TextField
        fullWidth
        type="number"
        variant="outlined"
        placeholder={placeholder.toString()}
        value={value}
        onInput={(e) => handleOnChange(Number(e.target.value))}
      />
    </Box>
  )
}

NumberField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleOnChange: PropTypes.func.isRequired,
}

NumberField.defaultProps = {
  placeholder: '',
  value: '',
}

export default withStyles(styles)(NumberField)
