import React from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

// Fields
import SimText from '../SimText'
import InfoText from '../InfoText'
import UID from '../UID'
import URL from '../URL'
import RichText from '../RichText'
import ImageUpload from '../ImageUpload'
import Date from '../Date'
import Timestamp from '../Timestamp'
import ColorPicker from '../ColorPicker'
import Number from '../Number'
import Boolean from '../Boolean'
import File from '../File'
import Embed from '../Embed'
import GeoPoint from '../GeoLocation'
import Select from '../Select'
import Tag from '../Tag'
import Categories from '../Categories'

function NotArrayFields(props) {
  const {
    projectId,
    order,
    lang,
    value,
    label,
    placeholder,
    type,
    width,
    height,
    choices,
    handleChangeObject,
    handleChangeString,
    handleChangeNumber,
    handleChangeBoolean,
    handleChangeTag,
    selectLang,
    categories,
    disabled,
  } = props

  return (
    <>
      {type === 'simtext' && (
        <SimText
          lang={lang}
          label={label}
          placeholder={placeholder}
          value={value}
          handleOnChange={(e, lng) => handleChangeObject(e.target.value, lng, label)}
          selectLang={selectLang}
        />
      )}
      {type === 'array' && (
        <Tag
          label={label}
          placeholder={placeholder}
          value={value || []}
          handleOnChange={(newArr) => handleChangeTag(newArr, label)}
        />
      )}
      {type === 'richtext' && (
        <RichText
          lang={lang}
          label={label}
          selectLang={selectLang}
          placeholder={placeholder}
          value={value}
          handleOnChange={(data, lng) => handleChangeObject(data, lng, label)}
        />
      )}
      {type === 'infotext' && (
        <InfoText
          label={label}
          placeholder={placeholder}
          value={value}
          handleOnChange={(e) => handleChangeString(e.target.value, label)}
          disabled={disabled}
        />
      )}
      {type === 'uid' && (
        <UID
          label={label}
          placeholder={placeholder}
          value={value || ''}
          handleOnChange={(e) => handleChangeString(e.target.value, label)}
        />
      )}
      {type === 'link' && (
        <URL
          label={label}
          placeholder={placeholder}
          value={value}
          handleOnChange={(e) => handleChangeString(e.target.value, label)}
          disabled={disabled}
        />
      )}
      {type === 'image' && (
        <ImageUpload
          projectId={projectId}
          removeFunc={() => handleChangeString('', label)}
          handleOnChange={(urlImage) => handleChangeString(urlImage, label)}
          order={order}
          label={label}
          img={value || ''}
          width={width || 480}
          height={height || 480}
          placeholder={placeholder}
        />
      )}
      {type === 'date' && (
        <Date
          label={label}
          value={value}
          handleOnChange={(newDate) => handleChangeString(newDate, label)}
        />
      )}
      {type === 'timestamp' && (
        <Timestamp
          label={label}
          value={value}
          handleOnChange={(newDate) => handleChangeNumber(newDate, label)}
        />
      )}
      {type === 'color' && (
        <ColorPicker
          label={label}
          value={!value ? '#fff' : value}
          handleOnChange={(newValue) => handleChangeString(newValue, label)}
        />
      )}
      {type === 'number' && (
        <Number
          label={label}
          value={value}
          placeholder="number"
          handleOnChange={(number) => handleChangeNumber(number, label)}
        />
      )}
      {type === 'boolean' && (
        <Boolean
          label={label}
          value={value || false}
          placeholder="Show"
          handleOnChange={(event) => handleChangeBoolean(event.target.checked, label)}
        />
      )}
      {type === 'file' && (
        <File
          projectId={projectId}
          removeFunc={() => handleChangeString('', label)}
          label={label}
          order={order}
          placeholder="Paste your URL here"
          updateUrl={value || ''}
          handleOnChange={(file) => handleChangeString(file, label)}
        />
      )}
      {type === 'embed' && (
        <Embed
          label={label}
          value={value}
          placeholder="Paste your HTML code here"
          handleOnChange={(e) => handleChangeString(e.target.value, label)}
        />
      )}
      {type === 'geoPoint' && (
        <GeoPoint
          label={label}
          lat={_get(value, '[lat]')}
          lng={_get(value, '[lng]')}
          handleOnChange={(e, geo) => handleChangeObject(e.target.value, geo, label)}
        />
      )}
      {type === 'select' && (
        <Select
          label={label}
          choices={choices}
          placeholder={placeholder}
          value={value}
          handleOnChange={(e) => handleChangeString(e.target.value, label)}
        />
      )}
      {type === 'category' && (
        <Categories
          label={label}
          categories={categories}
          value={_get(value, 'categories')}
          handleOnChange={(e, data) => handleChangeObject(data, 'categories', label)}
        />
      )}
    </>
  )
}

export default NotArrayFields

NotArrayFields.propTypes = {
  projectId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.any,
  ]).isRequired,
  lang: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  order: PropTypes.number,
  choices: PropTypes.arrayOf(PropTypes.any),
  handleChangeObject: PropTypes.func,
  handleChangeString: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleChangeBoolean: PropTypes.func,
  handleChangeTag: PropTypes.func,
  selectLang: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
}

NotArrayFields.defaultProps = {
  label: '',
  placeholder: '',
  width: 0,
  height: 0,
  choices: [],
  order: 0,
  categories: [],
  handleChangeObject: () => {},
  handleChangeString: () => {},
  handleChangeNumber: () => {},
  handleChangeBoolean: () => {},
  handleChangeTag: () => {},
  disabled: false,
}
