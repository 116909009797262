import firebase from 'firebase/app'

export const GET_LIST_DATA = 'GET_LIST_DATA'
export const GET_ROW_DATA = 'GET_ROW_DATA'
export const LOAD_TABLE_DATA = 'LOAD_TABLE_DATA'
export const SET_PAGE = 'SET_PAGE'
export const SET_VISIBLE_DATA = 'SET_VISIBLE_DATA'
export const LAST_VISIBLE = 'LAST_VISIBLE'
export const CLEAR_VISIBLE_DATA = 'CLEAR_VISIBLE_DATA'
export const DELETE_ONE_LIST = 'DELETE_ONE_LIST'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'

export function loadTableData() {
  return {
    type: LOAD_TABLE_DATA,
  }
}

export function getListData(data) {
  return {
    type: GET_LIST_DATA,
    payload: {
      listData: data,
    },
  }
}

export function getRowData(data, id) {
  return {
    type: GET_ROW_DATA,
    payload: {
      rowData: data,
      id,
    },
  }
}

export function setPageList(data) {
  return {
    type: SET_PAGE,
    payload: {
      page: data,
    },
  }
}

export function setVisibleData(data) {
  return {
    type: SET_VISIBLE_DATA,
    payload: {
      visibleData: data,
    },
  }
}

export function setLastVisible(data) {
  return {
    type: LAST_VISIBLE,
    payload: {
      lastVisible: data,
    },
  }
}

export function clearVisibleData() {
  return {
    type: CLEAR_VISIBLE_DATA,
  }
}

export function deleteOneList() {
  return {
    type: DELETE_ONE_LIST,
  }
}

export function submitData(submData, projectId, listId, uid) {
  return () => {
    firebase.firestore()
      .collection('data')
      .doc(projectId)
      .collection(listId)
      .doc(uid)
      .set({
        pages: submData,
      }, { merge: true })
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
