import { SHOW_MESSAGE, CLEAR_DATA } from '../actions/alert.actions'

const initialState = {
  keyAlert: '',
  messageAlert: '',
}
function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE: {
      return {
        ...state,
        keyAlert: action.payload.keyAlert,
        messageAlert: action.payload.messageAlert,
      }
    }
    case CLEAR_DATA: {
      return {
        keyAlert: '',
        messageAlert: '',
      }
    }
    default: {
      return state
    }
  }
}

export default alertReducer
