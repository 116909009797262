import firebase from 'firebase/app'

import _includes from 'lodash/includes'
import _findIndex from 'lodash/findIndex'
import _startCase from 'lodash/startCase'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import _isNumber from 'lodash/isNumber'
import _get from 'lodash/get'
import _indexOf from 'lodash/indexOf'
import _result from 'lodash/result'
import _zipObject from 'lodash/zipObject'
import _filter from 'lodash/filter'
import * as alertAction from './alert.actions'

export const SETDATA_FORM = 'SETDATA_FORM'
export const SETEDIT_FORM = 'SETEDIT_FORM'
export const RESET_FORM = 'RESET_FORM'
export const LOADING = 'LOADING'
export const STORE_DATA = 'STORE_DATA'
export const LIST_DATA = 'LIST_DATA'
export const ARR_STORE_DATA = 'ARR_STORE_DATA'
export const DATA_DOC_ID = 'DATA_DOC_ID'

export function resetForm() {
  return {
    type: RESET_FORM,
  }
}

function setEditForm(value, type, index) {
  return {
    type: SETEDIT_FORM,
    payload: {
      data: value,
      type,
      index,
    },
  }
}
export function setForm(e) {
  return {
    type: SETDATA_FORM,
    payload: {
      data: e,
    },
  }
}

function loading(status) {
  return {
    type: LOADING,
    payload: {
      status,
    },
  }
}
export function setStoreData(data) {
  return {
    type: STORE_DATA,
    payload: {
      data,
    },
  }
}

export function setListData(data) {
  return {
    type: LIST_DATA,
    payload: {
      data,
    },
  }
}

export function arrObjStore(data) {
  return {
    type: ARR_STORE_DATA,
    payload: {
      data,
    },
  }
}

function dataDocId(data) {
  return {
    type: DATA_DOC_ID,
    payload: {
      data,
    },
  }
}
const Alert = async (type, message, dispatch) => {
  await dispatch(alertAction.showMessage(type, message))
  setTimeout(() => {
    dispatch(alertAction.clear())
  }, 1500)
}

async function getPage(projectId) {
  const ref = await firebase.firestore().collection('projects').doc(projectId)
  const result = await ref.get()
  try {
    if (result.exists) {
      const data = result.data()
      return data
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

async function deletePage(projectId, pageId, type) {
  const ref = await firebase.firestore().collection('projects').doc(projectId)
  const result = await ref.get()
  try {
    if (result.exists) {
      const pagelist = _startCase(pageId)
      const data = result.data()
      if (type === 'page' || type === 'list') {
        const schema = _get(data, `schema.${type}`)
        const index = _findIndex(schema, { name: pagelist })
        if (index === -1) {
          const name = schema.map((x) => _kebabCase(x.name))
          const i = _indexOf(name, _kebabCase(pagelist))
          schema.splice(i, 1)
        } else {
          schema.splice(index, 1)
        }
        await firebase.firestore().collection('projects').doc(projectId).set({
          schema: { [type]: schema },
        }, { merge: true })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

async function setDataPage(type, projectId, dataAll) {
  const { name } = type[0].pl[0]
  const nameMod = _camelCase(name)
  const dataLabel = dataAll[0].fields.map((x) => x.label)
  const dataType = dataAll[0].fields.map((x) => x.type)
  const arrData = []
  dataType.forEach((x) => {
    if (x === 'array' || x === 'arrayOfObj') {
      arrData.push([])
    } else {
      arrData.push({})
    }
  })
  const mergeData = _zipObject(dataLabel, arrData)
  try {
    await firebase.firestore().collection('data').doc(projectId).set({
      pages: { [nameMod]: mergeData },
    }, { merge: true })
  } catch (error) {
    console.log(error)
  }
}

export function getAndSetPrev(projectId, pageId) {
  return async (dispatch) => {
    const pagelist = _startCase(pageId)
    const ref = await firebase.firestore().collection('projects').doc(projectId).get()
    const refData = await firebase.firestore().collection('data').doc(projectId).get()
    const data = ref.data()
    const pageData = refData.data()
    const storeData = []
    let arrData = []
    const combineArr = []
    const schema = _get(data, 'schema.page')
    const getPageData = pageData.pages[pageId]
    const index = _findIndex(schema, { name: pagelist })
    if (index === -1) {
      const name = schema.map((x) => _kebabCase((x.name)))
      const i = _indexOf(name, _kebabCase(pagelist))
      const oldData = schema[i].fields.map((x) => x.label)
      await oldData.forEach((x) => storeData.push(getPageData[x]))
      dispatch(setStoreData(storeData))
    } else {
      const oldData = schema[index].fields.map((x) => x.label)
      const typeData = schema[index].fields.map((x) => x.type)
      if (typeData.includes('arrayOfObj')) {
        const nameArr = schema[index].fields.map((x) => (x))
        const filter = _filter(nameArr, { type: 'arrayOfObj' })
        filter.forEach((x) => {
          // old
          // const getData = getPageData[_camelCase(x.label)]
          // new
          const getData = getPageData[_camelCase(x.label)] || getPageData[x.label]
          getData.forEach((z) => {
            const dataAr = x.array.schema.map((y) => _result(z, y.label))
            arrData.push(dataAr)
          })
          combineArr.push(arrData)
          arrData = []
        })
        dispatch(arrObjStore(combineArr))
      }
      await oldData.forEach((x) => storeData.push(getPageData[x]))
      dispatch(setStoreData(storeData))
    }
  }
}

async function setListField(upData, projectId, storeData, listDoc) {
  try {
    const isPublic = _get(upData, '[0].pl[0].isPublic')
    const collection = isPublic ? 'publicData' : 'data'
    const newData = _get(upData, '[0].pl[0].fields')
    const name = _get(upData, '[0].pl[0].name')
    const newDataLabel = newData.map((x) => _camelCase(x.label))

    listDoc.forEach((docId, i) => {
      const stLength = storeData[i].length
      const nDataLength = newDataLabel.length
      if (stLength < nDataLength) {
        newDataLabel.splice(stLength, nDataLength - stLength)
      }
      newDataLabel.forEach((fields, j) => {
        firebase.firestore().collection(collection).doc(projectId).collection(_camelCase(name))
          .doc(docId)
          .set({
            [fields]: storeData[i][j],
          }, { merge: true })
      })
    })
  } catch (error) {
    console.log(error)
  }
}

async function setDataInListForArrObj(eachId, listDoc, upData, projectId) {
  try {
    let array = []
    const isPublic = _get(upData, '[0].pl[0].isPublic')
    const listId = _camelCase(_get(upData, '[0].pl[0].name'))
    const collection = isPublic ? 'publicData' : 'data'
    eachId.forEach((arrObj, i) => {
      arrObj.forEach((x, j) => {
        const newData = _get(upData, '[0].pl[0].fields')
        const newDataMap = newData.map((g) => _kebabCase(g.label))
        const newDataFilter = _filter(newData, { type: 'arrayOfObj' })
        const index = _indexOf(newDataMap, _kebabCase(newDataFilter[j].label))
        const newDataLabel = newData[index].array.schema.map((k) => _camelCase(k.label))
        x.map((oldData) => {
          const oIndex = oldData.length
          const nIndex = newDataLabel.length
          if (oIndex < nIndex) {
            newDataLabel.splice(oIndex, nIndex - oIndex)
          }
          const mergeData = _zipObject(newDataLabel, oldData)
          return (array.push(mergeData))
        })
        firebase.firestore().collection(collection).doc(projectId).collection(listId)
          .doc(listDoc[i])
          .set({
            [_camelCase(newDataFilter[j].label)]: array,
          }, { merge: true })
        array = []
      })
    })
  } catch (error) {
    console.log(error)
  }
}

async function setArrObjStoreList(getData, filter, dispatch) {
  try {
    let arrData = []
    let combineArr = []
    const eachId = []
    getData.forEach((_, i) => {
      filter.forEach((z) => {
        const data = _get(getData, `[${i}][${_camelCase(z.label)}]`, [])
        data.forEach((y) => {
          const dataAr = z.array.schema.map((p) => _result(y, _camelCase(p.label)))
          arrData.push(dataAr)
        })
        combineArr.push(arrData)
        arrData = []
      })
      eachId.push(combineArr)
      combineArr = []
    })
    dispatch(arrObjStore(eachId))
  } catch (error) {
    console.log(error)
  }
}

async function setAllDataList(projectId, listId, schema, dispatch) {
  try {
    const collection = schema.isPublic ? 'publicData' : 'data'
    const ref = await firebase.firestore().collection(collection).doc(projectId).collection(listId)
      .get()
    const projects = await firebase.firestore().collection('projects').doc(projectId).get()
    const dataProjects = projects.data().schema.list
    const dataProjectsName = dataProjects.map((x) => _kebabCase(x.name))
    const index = _indexOf(dataProjectsName, _kebabCase(listId))
    const dataSchema = _get(dataProjects, `${index}`)
    const dataSchemaLabel = dataSchema.fields.map((x) => x.label)
    const dataSchemaType = dataSchema.fields.map((x) => x.type)
    const data = ref.docs.map((doc) => doc.data())
    const id = ref.docs.map((doc) => doc.id)
    const arr = []
    if (dataSchemaType.includes('arrayOfObj')) {
      const nameArr = dataSchema.fields.map((x) => x)
      const filter = _filter(nameArr, { type: 'arrayOfObj' })
      await setArrObjStoreList(data, filter, dispatch)
    }
    data.map((x) => {
      const dataArr = dataSchemaLabel.map((z) => (x[_camelCase(z)]))
      return (
        arr.push(dataArr)
      )
    })
    dispatch(dataDocId(id))
    dispatch(setListData(arr))
  } catch (error) {
    console.log(error)
  }
}

export function getAndSetPrevList(projectId, listId) {
  return async (dispatch) => {
    const list = _startCase(listId)
    const ref = await firebase.firestore().collection('projects').doc(projectId).get()
    const data = ref.data()
    const schema = _get(data, 'schema.list')
    const index = _findIndex(schema, { name: list })
    if (index === -1) {
      const name = schema.map((x) => _kebabCase(x.name))
      const i = _indexOf(name, _kebabCase(list))
      await setAllDataList(projectId, listId, schema[i], dispatch)
    } else {
      await setAllDataList(projectId, listId, schema[index], dispatch)
    }
  }
}
async function setDataForArrOfObj(projectId, upData, arrObj) {
  try {
    let array = []
    const pageName = _camelCase(_get(upData, '[0].pl[0].name'))
    arrObj.forEach((x, i) => {
      const newData = _get(upData, '[0].pl[0].fields')
      const newDataMap = newData.map((g) => _kebabCase(g.label))
      const newDatafilter = _filter(newData, { type: 'arrayOfObj' })
      const index = _indexOf(newDataMap, _kebabCase(newDatafilter[i].label))
      const newDataLabel = newData[index].array.schema.map((k) => _camelCase(k.label))
      x.map((oldData) => {
        const oIndex = oldData.length
        const nIndex = newDataLabel.length
        if (oIndex < nIndex) {
          newDataLabel.splice(oIndex, nIndex - oIndex)
        }
        const mergeData = _zipObject(newDataLabel, oldData)
        return (array.push(mergeData))
      })
      firebase.firestore().collection('data').doc(projectId).set({
        pages: { [pageName]: { [_camelCase(newDatafilter[i].label)]: array } },
      }, { merge: true })
      array = []
    })
  } catch (error) {
    console.log(error)
  }
}

async function setAllDataInField(projectId, upData, storeData) {
  try {
    const pageName = _camelCase(_get(upData, '[0].pl[0].name'))
    const newData = _get(upData, '[0].pl[0].fields')
    const newDataLabel = newData.map((x) => x.label)
    const mergeData = _zipObject(newDataLabel, storeData)
    await firebase.firestore().collection('data').doc(projectId).set({
      pages: { [pageName]: mergeData },
    }, { merge: true })
  } catch (error) {
    console.log(error)
  }
}

async function updateDataList(upData, projectId, pagelistId, oldList) {
  try {
    const listName = _camelCase(_get(upData, '[0].pl[0].name'))
    const collectionSet = upData[0].pl[0].isPublic ? 'publicData' : 'data'
    const collectionGet = oldList.isPublic ? 'publicData' : 'data'
    const ref = await firebase.firestore().collection(collectionGet).doc(projectId)
      .collection(pagelistId)
      .get()
    const data = ref.docs.map((doc) => doc.data())
    const id = ref.docs.map((doc) => doc.id)
    data.forEach((_, i) => firebase.firestore().collection(collectionSet).doc(projectId)
      .collection(listName)
      .doc(id[i])
      .set(data[i], { merge: true }))
  } catch (error) {
    console.log(error)
  }
}
async function getName(typech, namefc, projectId, index) {
  try {
    const ref = await firebase.firestore().collection('projects').doc(projectId).get()
    const x = ref.data()
    if (typech === 'list') {
      const y = x.schema.list.map((data) => data.name.replace(/\s+/g, ''))
      let check = _includes(y, namefc.replace(/\s+/g, ''))
      if (_isNumber(index)) {
        const checkIn = _indexOf(y, namefc.replace(/\s+/g, ''))
        if (checkIn === index) {
          check = false
        }
      }
      return check
    }
    if (typech === 'page') {
      const y = x.schema.page.map((data) => data.name.replace(/\s+/g, ''))
      let check = _includes(y, namefc.replace(/\s+/g, ''))
      if (_isNumber(index)) {
        const checkIn = _indexOf(y, namefc.replace(/\s+/g, ''))
        if (checkIn === index) {
          check = false
        }
      }
      return check
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

export function handleDeletePage(projectId, pageId, history, type) {
  return async (dispatch) => {
    try {
      dispatch(loading(true))
      await deletePage(projectId, pageId, type)
      await dispatch(alertAction.showMessage('success', `${type} Delete`))
      setTimeout(() => {
        dispatch(alertAction.clear())
        history.replace(`/project/${projectId}/`)
        window.location.reload()
      }, 1500)
    } catch (error) {
      dispatch(loading(false))
      dispatch(alertAction.showMessage('error', error))
      setTimeout(() => {
        dispatch(alertAction.clear())
      }, 1500)
    }
  }
}

export function submitForm(type, projectId, cateId) {
  const typech = type[0].type
  const { name } = type[0].pl[0]
  const dataAll = type
  dataAll[0].pl[0].name = _startCase(name)
  return async (dispatch) => {
    const check = await getName(typech, dataAll[0].pl[0].name, projectId)
    if (!check) {
      dispatch(loading(true))
      try {
        const data = await getPage(projectId)
        if (type[0].type === 'page') {
          if (data.schema.page) {
            const dataPage = [...data.schema.page, ...dataAll[0].pl]
            await firebase.firestore().collection('projects').doc(projectId).set(
              {
                schema: { page: dataPage },
              },
              { merge: true },
            )
            setDataPage(type, projectId, dataAll[0].pl)
            await Alert('success', 'Page Created', dispatch)
            window.location.reload()
          } else {
            await firebase.firestore().collection('projects').doc(projectId).set(
              {
                schema: { page: dataAll[0].pl },
              },
              { merge: true },
            )
            setDataPage(type, projectId)
            await Alert('success', 'Page Created', dispatch)
            window.location.reload()
          }
        } else if (type[0].type === 'list') {
          if (data.schema.list) {
            const dataList = [...data.schema.list, ...dataAll[0].pl]
            await firebase.firestore().collection('projects').doc(projectId).set(
              {
                schema: { list: dataList },
              },
              { merge: true },
            )
            Alert('success', 'List Created', dispatch)
            if (cateId) {
              await firebase.firestore().collection('projects').doc(projectId).collection('categories')
                .doc(_camelCase(cateId))
                .set({
                  groupName: 'string',
                  name: 'string',
                  description: 'string',
                })
            }
            window.location.reload()
          } else {
            await firebase.firestore().collection('projects').doc(projectId).set(
              {
                schema: { list: dataAll[0].pl },
              },
              { merge: true },
            )
            Alert('success', 'List Created', dispatch)
            window.location.reload()
          }
        }
      } catch (error) {
        dispatch(alertAction.showMessage('error', error))
      }
    } else {
      dispatch(alertAction.showMessage('error', 'Already Created'))
      dispatch(loading(false))
      setTimeout(() => {
        dispatch(alertAction.clear())
      }, 1500)
    }
  }
}

export function editDataForm(projectId, pageId, type) {
  return async (dispatch) => {
    try {
      dispatch(loading(false))
      const pagelist = _startCase(pageId)
      const ref = await firebase.firestore().collection('projects').doc(projectId).get()
      const data = ref.data()
      if (type === 'page' || type === 'list') {
        const schema = _get(data, `schema.${type}`)
        const index = _findIndex(schema, { name: pagelist })
        if (index === -1) {
          const name = schema.map((x) => _kebabCase(x.name))
          const i = _indexOf(name, _kebabCase(pagelist))
          await dispatch(setEditForm(schema[i], type, i))
        } else {
          await dispatch(setEditForm(schema[index], type, index))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export function editAllData(projectId, type, index, upData, history,
  pagelistId, storeData, arrObj, listDoc) {
  return async (dispatch) => {
    const ref = await firebase.firestore().collection('projects').doc(projectId).get()
    const data = ref.data()
    const name = _startCase(upData[0].pl[0].name)
    const newData = upData
    newData[0].pl[0].name = name
    const check = await getName(type, name, projectId, index)
    const schema = _get(data, `schema.${type}`)
    if (!check) {
      try {
        dispatch(loading(true))
        if (type === 'page' || type === 'list') {
          if (type === 'page') {
            await setAllDataInField(projectId, newData, storeData)
            if (arrObj.length > 0) {
              await setDataForArrOfObj(projectId, newData, arrObj)
            }
          }
          if (type === 'list') {
            await updateDataList(newData, projectId, pagelistId, schema[index])
            await setListField(newData, projectId, storeData, listDoc)
            if (arrObj.length > 0) {
              await setDataInListForArrObj(arrObj, listDoc, upData, projectId)
            }
          }
          const schemaUpdate = _get(data, `schema.${type}`)
          schemaUpdate.splice(index, 1, ...newData[0].pl)
          await firebase.firestore().collection('projects').doc(projectId).set({
            schema: { [type]: schemaUpdate },
          }, { merge: true })
        }
        await Alert('success', 'Updated Success', dispatch)
        setTimeout(() => {
          history.replace(`/project/${projectId}`)
          window.location.reload()
        }, 1500)
      } catch (error) {
        Alert('error', error)
      }
    } else {
      dispatch(loading(false))
      dispatch(alertAction.showMessage('error', 'Already Created'))
      setTimeout(() => {
        dispatch(alertAction.clear())
      }, 1500)
    }
  }
}
