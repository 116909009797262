import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _capitalize from 'lodash/capitalize'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import { Typography, Box } from '@material-ui/core'
import _startCase from 'lodash/startCase'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Card from '@material-ui/core/Card'
import _map from 'lodash/map'
import { Link as LinkDom, useHistory } from 'react-router-dom'
import { getLangNameFromCode as _fullLang } from 'language-name-map'
import Loading from './Loading'
import * as formAction from '../../redux/actions/form.action'
import { getFormPage, getFormData, getFormLoading } from '../../redux/selectors/form.selectors'
import { getProjectData } from '../../redux/selectors/project.selectors'
import { getUser } from '../../redux/selectors/user.selectors'
// Fields
import GenFieldForm from '../../components/Fields/GenFieldForm/GenFieldForm'
import * as alertAction from '../../redux/actions/alert.actions'
import { handleDeletePage, editDataForm } from '../../redux/actions/createform.action'
import AlertBar from '../../components/NoticeMessage/SnackBar'
import { loadingBt } from '../../redux/selectors/createform.selector'
import Seo from '../../components/Seo'

const styles = (theme) => ({
  paper: {
    overflow: 'hidden',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    flex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    paddingRight: 56,
    paddingLeft: 56,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  toolbar: {
    padding: 0,
  },
  fabButton: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
function ProjectDetail(props) {
  const history = useHistory()
  const { computedMatch, classes } = props
  const selectedProject = _camelCase(_get(computedMatch, 'params.projectId'))
  const selectedDocument = _camelCase(_get(computedMatch, 'params.pageId'))
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const formPage = useSelector(getFormPage)
  const formData = useSelector(getFormData)
  const project = useSelector(getProjectData)
  const formLoading = useSelector(getFormLoading)
  const loading = useSelector(loadingBt)
  const open = Boolean(anchorEl)
  const user = useSelector(getUser)
  const [lang, setLang] = useState()
  const [chLang, setChLang] = useState(' ')
  const dispatch = useDispatch()

  useEffect(() => {
    if (!_isEmpty(project)) {
      setLang(project.schema.lang)
      setChLang(project.schema.lang[0])
    }
  }, [project])

  useEffect(() => {
    dispatch(formAction.getForm(selectedProject, selectedDocument))
    dispatch(formAction.getDataForm(selectedProject, selectedDocument))
  }, [selectedProject, dispatch, selectedDocument])

  const handleSelect = (e) => {
    setChLang(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const cb = (key, message) => { dispatch(alertAction.showMessage(key, message)) }
    dispatch(formAction.submitData(formData, selectedProject, cb))
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenPopup = () => {
    setOpenPopup(true)
    setAnchorEl(null)
  }

  const handleClosePopup = () => {
    setOpenPopup(false)
    setAnchorEl(null)
  }

  const handleDelete = () => {
    dispatch(handleDeletePage(selectedProject, selectedDocument, history, 'page'))
  }

  const handleEdit = () => {
    dispatch(editDataForm(selectedProject, selectedDocument, 'page'))
  }

  return (
    <>
      <AlertBar />
      <Box mb={2} className={classes.header}>
        <Typography variant="h4">
          {_capitalize(_startCase(selectedDocument))}
        </Typography>
        <Box>
          <Select variant="outlined" value={chLang} onChange={handleSelect}>
            {_map(lang, (langtype, i) => (
              <MenuItem key={i} value={langtype}>{` Language: ${_fullLang(langtype)?.name || langtype}`}</MenuItem>
            ))}
          </Select>
          {user.userData.type === 'foxbith' && (
          <>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >

              <List>
                <LinkDom
                  to={`/project/${selectedProject}/page/${_kebabCase(selectedDocument)}/edit`}
                  style={{ color: 'black' }}
                >
                  <ListItem
                    button
                    onClick={handleEdit}
                  >
                    Edit
                  </ListItem>
                </LinkDom>
                <ListItem
                  button
                  onClick={handleOpenPopup}
                >
                  Delete
                </ListItem>
              </List>
            </Popover>
            <Dialog
              open={openPopup}
              onClose={handleClosePopup}
            >
              <DialogTitle>Are you sure you want to delete this item ?</DialogTitle>
              <DialogActions style={{ padding: '16px 24px' }}>
                <Button onClick={handleClosePopup} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus disabled={loading} variant="contained">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

          </>
          )}
        </Box>
      </Box>
      <Paper className={classes.paper}>
        {formLoading || _isEmpty(lang)
          ? (
            <Loading />
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <Card>
                  <Box margin={3}>
                    <GenFieldForm
                      projectId={selectedProject}
                      schema={formPage}
                      lang={lang}
                      allData={formData}
                      dataKey={selectedDocument}
                      selectLang={chLang}
                    />
                  </Box>
                </Card>
                <Box mt={4} />
                <Seo />
                <AppBar position="fixed" color="secondary" className={classes.appBar}>
                  <Toolbar className={classes.toolbar}>
                    <div className={classes.fabButton}>
                      <Button type="submit" variant="contained" color="primary">submit</Button>
                    </div>
                  </Toolbar>
                </AppBar>
              </form>
            </>
          )}
      </Paper>
    </>
  )
}

ProjectDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  computedMatch: PropTypes.shape({}),
}
ProjectDetail.defaultProps = {
  computedMatch: {},
}

export default withStyles(styles)(ProjectDetail)
