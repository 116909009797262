import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react-web'
import {
  withStyles,
} from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Button,
  Box,
} from '@material-ui/core'
import loadingButton from '../../components/LottieFiles/loading_button.json'
import firebase from '../../firebase'
import * as userAction from '../../redux/actions/user.actions'
import * as alertAction from '../../redux/actions/alert.actions'
import FireStoreUser from '../../utils/FirebaseUser/FirebaseUser'

import s from './LoginPage.module.scss'
import AlertBar from '../../components/NoticeMessage/SnackBar'

const CssTextField = withStyles({
  root: {
    '& label': {
      color: '#fff',
    },
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& input': {
      color: '#fff',
    },
    '& .Mui-disable ': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fc4c02',
    },
    '& .MuiFormHelperText-root': {
      color: '#fc4c02',
    },
    '& .MuiFormLabel-root': {
      '&.Mui-disabled ': {
        color: '#fff',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#fff',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        borderColor: '#f44336',
      },
      '&:hover fieldset': {
        borderColor: '#f44336',
      },
      '&.Mui-focused fieldset': {
        color: '#fff',
        borderColor: '#f44336',
      },
    },
  },
})(TextField)

const CssButton = withStyles({
  root: {
    '&$disabled': {
      color: '#fff',
      background: '#fc4c02',
    },
  },
  disabled: {},
})(Button)
function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const loginSubmit = async (e) => {
    e.preventDefault()
    setLoading(false)
    try {
      const response = await firebase.auth().signInWithEmailAndPassword(email, password)
      const { user } = response
      try {
        const userData = await FireStoreUser.getCurrentUser(user.uid)
        dispatch(userAction.loginSuccess(user, userData))
      } catch (error) {
        dispatch(userAction.getErrorMessageUser(error))
      }
    } catch (error) {
      dispatch(userAction.logout(error.message))
      dispatch(alertAction.showMessage('error', error.message))
    }
    setLoading(true)
  }
  return (
    <div className={s.box}>
      <Box mb={4}>
        <Typography variant="h5" className={s.title}>
          Login
        </Typography>
      </Box>
      <AlertBar />
      <form onSubmit={loginSubmit}>
        <CssTextField
          disabled={!loading}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CssTextField
          disabled={!loading}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box mt={4}>
          {loading
            ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={s.button}
              >
                Login
              </Button>
            )
            : (
              <CssButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled
                className={s.button}
              >
                <Box mt={2}>
                  <Lottie
                    width="30%"
                    options={{
                      animationData: loadingButton,
                    }}
                  />
                </Box>
              </CssButton>
            )}
        </Box>
        <Box mt={2}>
          <Link to="/forgotpass">
            <Typography variant="body2" color="primary" className={s.link}>
              Forgot Password?
            </Typography>
          </Link>
        </Box>
      </form>
    </div>
  )
}

export default LoginPage
