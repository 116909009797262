import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../Footer'

import s from './NotLoginLayout.module.scss'

function Navbar(props) {
  const { children } = props
  return (
    <div className={s.root}>
      <div className={s.app}>
        <main className={s.main}>
          {children}
        </main>
        <Footer color="white" />
      </div>
    </div>
  )
}

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Navbar
