import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import WebIcon from '@material-ui/icons/Web'
import Box from '@material-ui/core/Box'
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PermMediaIcon from '@material-ui/icons/PermMedia'

import { useDispatch, useSelector } from 'react-redux'
import _kebabCase from 'lodash/kebabCase'
import _get from 'lodash/get'
import AddIcon from '@material-ui/icons/Add'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import firebase from '../../firebase'
import { logout } from '../../redux/actions/user.actions'
import { getUser } from '../../redux/selectors/user.selectors'
import { resetForm, setForm } from '../../redux/actions/createform.action'
import { getCreateForm } from '../../redux/selectors/createform.selector'

const styles = (theme) => ({
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(252, 76, 2, 0.7)',
      color: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: 'rgba(252, 76, 2, 0.5)',
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  itemWh: {
    color: '#fff',
  },
  itemCategory: {
    backgroundColor: '#000000',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  lastitemCategory: {
    backgroundColor: '#000000',
    boxShadow: '0 0 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  logo: {
    margin: 'auto',
    maxHeight: 40,
    maxWidth: '100%',
    height: 50,
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listSpace: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#fc4c02',
  },
  tagsHeaderPrimary: {
    backgroundColor: 'rgba(252, 76, 2, 0.5)',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    color: '#fff',
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  activeIcon: {
    color: '#fc4c02',
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  arrowIcon: {
    color: '#fff',
    minWidth: 'auto',
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  dividerList: {
    borderTop: '1px solid #404854',
  },
  itemIconLogout: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  list: {
    fontSize: '14px !important',
  },
})

function Navigator(props) {
  const {
    classes,
    data,
    pages,
    lists,
    loadingFunc,
    pageStatus,
  } = props

  const [openCollapse, setOpenCollapse] = useState({
    id: 0,
    isActive: false,
  })

  const user = useSelector(getUser)

  const dispatch = useDispatch()

  const dataForm = useSelector(getCreateForm)

  const history = useHistory()

  const handleLogout = async () => {
    firebase.auth().signOut().then(() => {
      dispatch(logout())
    })
  }
  const resetFormValue = () => {
    dispatch(resetForm())
  }

  const setList = () => {
    const type = [...dataForm]
    type[0].type = 'list'
    dispatch(setForm(type))
  }
  const handleCollapse = (index, isShowTags, childId) => {
    if (childId) {
      if (!isShowTags) {
        history.push(`/project/${data.uid}/list/${_kebabCase(childId)}`)
      }
      setOpenCollapse({
        id: index,
        isActive: openCollapse.id === index ? !openCollapse.isActive : true,
      })
    } else {
      setOpenCollapse({
        id: index,
        isActive: !openCollapse.isActive,
      })
    }
  }

  return (
    <Drawer
      variant="permanent"
      PaperProps={{ style: { width: 256 } }}
    >
      <List disablePadding>
        <ListItem className={classes.itemCategory}>
          <img className={classes.logo} src={data.logo} alt={data.name} />
        </ListItem>
      </List>
      <List
        disablePadding
        className={classes.navPage}
      >
        {_get(user, 'userData.type') === 'foxbith' ? (
          <Link
            to={`/project/${data.uid}/page-table`}
            style={{ textDecoration: 'none' }}
          >
            <ListItem
              className={classes.categoryHeader}
              onClick={resetFormValue}
            >
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                Pages
              </ListItemText>
            </ListItem>
          </Link>
        ) : (
          <ListItem
            className={classes.categoryHeader}
            onClick={resetFormValue}
          >
            <ListItemIcon className={classes.itemIcon}>
              <WebIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              Pages
            </ListItemText>
          </ListItem>
        )}
        {pages.map(({ id: childId, active }) => (
          <React.Fragment key={childId}>
            {
              active
                ? (
                  <ListItem
                    key={childId}
                    button
                    className={clsx(classes.item, active && classes.itemActiveItem)}
                  >
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                )
                : (
                  <Link
                    key={childId}
                    to={`/project/${data.uid}/page/${_kebabCase(childId)}`}
                    style={{ textDecoration: 'none' }}
                    onClick={loadingFunc}
                  >
                    <ListItem
                      button
                      className={clsx(classes.item, active && classes.itemActiveItem)}
                    >
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      >
                        {childId}
                      </ListItemText>
                    </ListItem>
                  </Link>
                )
            }
          </React.Fragment>
        ))}
        {/* <Divider className={classes.divider} /> */}
      </List>
      <Box
        mt={1}
        className={classes.dividerList}
      />
      <List disablePadding>
        {/* <ListItem className={classes.categoryHeader}>
          <ListItemIcon className={classes.itemIcon}>
            <ListIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.categoryHeaderPrimary,
            }}
          >
            Lists
          </ListItemText>
        </ListItem> */}
        <ListItem
          className={classes.categoryHeader}
          onClick={resetFormValue}
        >
          <ListItemText
            classes={{
              primary: classes.categoryHeaderPrimary,
            }}
          >
            List
          </ListItemText>
        </ListItem>
        {lists.map(({
          id: childId, active, isShowTags, activeTags,
        }, index) => (
          <React.Fragment key={childId}>
            {
              (active && !pageStatus)
                ? (
                  <>
                    <ListItem
                      button
                      className={clsx(
                        classes.item,
                        classes.categoryHeaderPrimary,
                        classes.listSpace,
                        (active && (openCollapse.id === index)) && classes.tagsHeaderPrimary,
                      )}
                      onClick={() => handleCollapse(index)}
                    >
                      <ListItemIcon className={classes.itemIcon}>
                        <LocalMallIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.list}>
                        {childId}
                      </ListItemText>
                      {isShowTags && (
                        <ListItemIcon
                          className={classes.arrowIcon}
                        >
                          {openCollapse.id === index && openCollapse.isActive
                            ? <ExpandLess />
                            : <ExpandMore />}
                        </ListItemIcon>
                      )}
                    </ListItem>
                    {isShowTags
                      && (
                        <Collapse
                          in={(openCollapse.id === index && openCollapse.isActive)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Link
                            to={`/project/${data.uid}/list/${_kebabCase(childId)}`}
                            style={{ textDecoration: 'none' }}
                            onClick={loadingFunc}
                          >
                            <Box
                              pl={2}
                              className={clsx(
                                classes.item,
                                // (isShowTags) && classes.itemActiveItem,
                                (isShowTags && !activeTags) && classes.itemActiveItem,
                              )}
                            >
                              <ListItemText
                                classes={{
                                  primary: classes.itemPrimary,
                                }}
                              >
                                View
                              </ListItemText>
                            </Box>
                          </Link>
                          <Link
                            to={`/project/${data.uid}/tags/${_kebabCase(childId)}`}
                            style={{ textDecoration: 'none' }}
                            onClick={loadingFunc}
                          >
                            <Box
                              pl={2}
                              mb={2}
                              className={clsx(
                                classes.item,
                                (activeTags) && classes.itemActiveItem,
                              )}
                            >
                              <ListItemText
                                classes={{
                                  primary: classes.itemPrimary,
                                }}
                              >
                                Tags
                              </ListItemText>
                            </Box>

                          </Link>
                        </Collapse>
                      )}
                  </>
                )
                : (
                  <>
                    <ListItem
                      button
                      className={clsx(
                        classes.item,
                        classes.categoryHeaderPrimary,
                        classes.listSpace,
                        ((openCollapse.id === index)) && classes.tagsHeaderPrimary,
                      )}
                      onClick={() => { handleCollapse(index, isShowTags, childId) }}
                    >
                      <ListItemIcon className={classes.itemIcon}>
                        <LocalMallIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.list}>
                        {childId}
                      </ListItemText>
                      {isShowTags
                        && (
                          <ListItemIcon className={classes.arrowIcon}>
                            {openCollapse.id === index && openCollapse.isActive
                              ? <ExpandLess />
                              : <ExpandMore />}
                          </ListItemIcon>
                        )}
                    </ListItem>
                    {isShowTags
                      && (
                        <Collapse
                          in={(openCollapse.id === index && openCollapse.isActive)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Link
                            to={`/project/${data.uid}/list/${_kebabCase(childId)}`}
                            style={{ textDecoration: 'none' }}
                            onClick={loadingFunc}
                          >
                            <Box
                              pl={2}
                              className={clsx(
                                classes.item,
                                activeTags && classes.itemActiveItem,
                              )}
                            >
                              <ListItemText
                                classes={{
                                  primary: classes.itemPrimary,
                                }}
                              >
                                View
                              </ListItemText>
                            </Box>

                          </Link>
                          <Link
                            to={`/project/${data.uid}/tags/${_kebabCase(childId)}`}
                            style={{ textDecoration: 'none' }}
                            onClick={loadingFunc}
                          >
                            <Box
                              pl={2}
                              mb={2}
                              className={clsx(
                                classes.item,
                                activeTags && classes.itemActiveItem,
                              )}
                            >
                              <ListItemText
                                classes={{
                                  primary: classes.itemPrimary,
                                }}
                              >
                                Tags
                              </ListItemText>
                            </Box>

                          </Link>
                        </Collapse>
                      )}
                  </>
                )
            }
          </React.Fragment>
        ))}
        {user.userData.type === 'foxbith' && (
          <Link
            to={`/project/${data.uid}/create-form`}
            style={{ textDecoration: 'none' }}
          >
            <ListItem
              className={clsx(classes.item, classes.listSpace)}
              onClick={setList}
            >
              <ListItemIcon className={clsx(classes.itemIcon)}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                Add new list
              </ListItemText>
            </ListItem>
          </Link>
        )}
        <Link
          to={`/project/${data.uid}/categories`}
          style={{ textDecoration: 'none' }}
        >
          <ListItem
            className={clsx(classes.item, classes.itemWh, classes.itemCategory)}
          >
            <ListItemIcon className={clsx(classes.itemIcon)}>
              <CategoryRoundedIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.catergoryHeaderPrimary,
              }}
            >
              Categories
            </ListItemText>
          </ListItem>
        </Link>
      </List>
      <Link
        to={`/project/${data.uid}/media-page`}
        style={{ textDecoration: 'none' }}
      >
        <ListItem
          className={clsx(classes.item, classes.itemWh, classes.itemCategory)}
        >
          <ListItemIcon className={clsx(classes.itemIcon)}>
            <PermMediaIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.catergoryHeaderPrimary,
            }}
          >
            Media
          </ListItemText>
        </ListItem>
      </Link>
      <Divider />
      {user.userData.type !== 'enduser' && (
        <Link to="/" style={{ textDecoration: 'none' }}>
          <ListItem className={clsx(classes.item, classes.itemWh, classes.itemCategory)}>
            <ListItemIcon className={clsx(classes.itemIcon)}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
              onClick={loadingFunc}
            >
              Back to all projects
            </ListItemText>
          </ListItem>
        </Link>
      )}
      <ListItem
        className={clsx(classes.item, classes.itemWh, classes.lastitemCategory)}
        onClick={handleLogout}
      >
        <ListItemIcon className={clsx(classes.itemIcon)}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemPrimary,
          }}
        >
          Log out
        </ListItemText>
      </ListItem>
    </Drawer>
  )
}

Navigator.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
  })).isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
  })).isRequired,
  loadingFunc: PropTypes.func.isRequired,
  pageStatus: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Navigator)
