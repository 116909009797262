import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { ChromePicker } from 'react-color'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
  border: {
    borderRadius: 4,
    border: '1px solid #000',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chip: {
    width: 20,
    height: 20,
    borderRadius: 4,
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
})

function ColorForm(props) {
  const {
    classes,
    label,
    value,
    handleOnChange,
  } = props

  const [background, setBackground] = React.useState(value)
  const [showPicker, setShowPicker] = React.useState(false)

  const handleClick = () => {
    setShowPicker(!showPicker)
  }

  const handleClose = () => {
    setShowPicker(false)
  }

  const handleChangeComplete = (color) => {
    setBackground(color.hex)
    handleOnChange(color.hex)
  }

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">{_startCase(_lowerCase(label))}</Typography>
      </Box>
      <div
        className={classes.border}
        role="button"
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
      >
        <div
          className={classes.chip}
          style={{ backgroundColor: background }}
        />
      </div>
      {showPicker && (
        <div className={classes.popover}>
          <div
            className={classes.cover}
            onClick={handleClose}
            onKeyDown={handleClose}
            role="button"
            tabIndex={0}
            aria-label="Color Popover"
          />
          <ChromePicker
            color={background}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      )}
    </Box>
  )
}

ColorForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
}

ColorForm.defaultProps = {
  value: '',
}

export default withStyles(styles)(ColorForm)
