import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _lowerCase from 'lodash/lowerCase'
import _startCase from 'lodash/startCase'

const styles = () => ({
  title: {
    fontWeight: 'bold',
  },
  disabledTextFieldUrl: {
    '& .MuiInputBase-root .Mui-disabled': {
      cursor: 'pointer',
      '&:hover': {
        color: '#fc4c02',
      },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
})

function URL(props) {
  const {
    classes,
    label,
    placeholder,
    value,
    handleOnChange,
    disabled,
  } = props

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography className={classes.title} variant="h6">
          {_startCase(_lowerCase(label))}
        </Typography>
      </Box>
      <div className={classes.disabledTextFieldUrl}>
        {disabled
          ? (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start"><LinkIcon /></InputAdornment>,
                }}
                fullWidth
                variant="outlined"
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={(e) => handleOnChange(e)}
              />
            </a>
          ) : (
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start"><LinkIcon /></InputAdornment>,
              }}
              fullWidth
              variant="outlined"
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={(e) => handleOnChange(e)}
            />
          )}
      </div>
    </Box>
  )
}

URL.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleOnChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
URL.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
}

export default withStyles(styles)(URL)
