import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useSelector, useDispatch } from 'react-redux'
import _get from 'lodash/get'
import _camelCase from 'lodash/camelCase'
import _map from 'lodash/map'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'

import ShortTextIcon from '@material-ui/icons/ShortText'
import NotesIcon from '@material-ui/icons/Notes'
import LooksOneIcon from '@material-ui/icons/LooksOne'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ImageIcon from '@material-ui/icons/Image'
import LinkIcon from '@material-ui/icons/Link'
import PersonIcon from '@material-ui/icons/Person'
import CodeIcon from '@material-ui/icons/Code'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ScheduleIcon from '@material-ui/icons/Schedule'
import RoomIcon from '@material-ui/icons/Room'
import ListIcon from '@material-ui/icons/List'
import CategoryIcon from '@material-ui/icons/Category'
import ListItemText from '@material-ui/core/ListItemText'

import { useHistory } from 'react-router-dom'
import Formcreate from '../../components/FormComponent/Formcreate'
import {
  arrStore,
  getCreateForm, indexForm, listData, listDoc, loadingBt, storeData, updateForm,
} from '../../redux/selectors/createform.selector'
import {
  editDataForm, editAllData, setForm, submitForm, getAndSetPrev, getAndSetPrevList, resetForm,
} from '../../redux/actions/createform.action'
import AlertBar from '../../components/NoticeMessage/SnackBar'
import ArrObjfield from '../../components/FormComponent/ArrObjfield'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    flex: 1,
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    paddingRight: 56,
    paddingLeft: 56,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  toolbar: {
    padding: 0,
  },
  fabButton: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  typeBox: {
    width: '100%',
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  submitBt: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}))

const menuItem = [
  { value: 'simtext', label: 'Short text', icon: <ShortTextIcon /> },
  { value: 'richtext', label: 'Rich text', icon: <NotesIcon /> },
  { value: 'infotext', label: 'Info text', icon: <ShortTextIcon /> },
  { value: 'number', label: 'Number', icon: <LooksOneIcon /> },
  { value: 'image', label: 'Image', icon: <ImageIcon /> },
  { value: 'link', label: 'URL link', icon: <LinkIcon /> },
  { value: 'file', label: 'file', icon: <InsertDriveFileIcon /> },
  { value: 'embed', label: 'embed', icon: <CodeIcon /> },
  { value: 'uid', label: 'uid', icon: <PersonIcon /> },
  { value: 'array', label: 'Tags', icon: <LocalOfferIcon /> },
  { value: 'boolean', label: 'boolean', icon: <RadioButtonCheckedIcon /> },
  { value: 'color', label: 'color', icon: <ColorLensIcon /> },
  { value: 'date', label: 'date', icon: <CalendarTodayIcon /> },
  { value: 'timestamp', label: 'timestamp', icon: <ScheduleIcon /> },
  { value: 'geoPoint', label: 'geoPoint', icon: <RoomIcon /> },
  { value: 'select', label: 'select', icon: <ListIcon /> },
  { value: 'arrayOfObj', label: 'arrayOfObj', icon: <CategoryIcon /> },
]

const CreateFormPage = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [select, setSelect] = useState('simtext')
  const type = useSelector(getCreateForm)
  const checkName = _get(type, '[0].pl[0].name')
  const checkFields = _get(type, '[0].pl[0].fields.length')
  const fieldValue = _get(type, '[0].pl[0]')
  const loading = useSelector(loadingBt)
  const isUpdate = useSelector(updateForm)
  const dataStore = useSelector(storeData)
  const indexF = useSelector(indexForm)
  const dataList = useSelector(listData)
  const listDocId = useSelector(listDoc)
  const arrObjStore = useSelector(arrStore)
  const dispatch = useDispatch()
  const { computedMatch } = props
  const projectId = _camelCase(_get(computedMatch, 'params.projectId'))
  const pageId = _camelCase(_get(computedMatch, 'params.pageId'))
  const listId = _camelCase(_get(computedMatch, 'params.listId'))
  const path = _get(computedMatch, 'path')
  const handleSelectedField = (e) => {
    setSelect(e.target.value)
  }
  const handleSequence = (e) => {
    const test = [...type]
    const testF = test[0].pl[0]
    test[0].type = e.target.value
    if (test[0].type === 'page') {
      testF.isPublic = false
      if (testF.fields) {
        testF.fields.forEach((_, i) => {
          testF.fields[i].isShow = false
          dispatch(setForm(test))
        })
      }
      dispatch(setForm(test))
    }
    dispatch(setForm(test))
  }
  const handleType = (e) => {
    const test = [...type]
    test[0].pl[0].name = (e.target.value)
    dispatch(setForm(test))
  }
  const handleAddFieldSelect = () => {
    const test = [...type]
    const fv = select
    const field = test[0].pl[0].fields

    if (fv === 'simtext' || fv === 'link' || fv === 'infotext' || fv === 'number' || fv === 'array' || fv === 'richtext' || fv === 'uid' || fv === 'embed' || fv === 'file' || fv === 'boolean' || fv === 'color' || fv === 'date' || fv === 'timestamp' || fv === 'geoPoint') {
      field.push({
        type: fv, placeholder: '', label: '', isShow: false,
      })
    } else if (fv === 'image') {
      field.push({
        type: fv, placeholder: '', label: '', width: '', height: '', isShow: false,
      })
    } else if (fv === 'select') {
      field.push({
        type: fv, placeholder: '', label: '', isShow: false, choices: [''],
      })
    } else if (fv === 'arrayOfObj') {
      field.push({
        type: fv, label: '', placeholder: '', isShow: false, array: { type: 'map', schema: [] },
      })
    }
    dispatch(setForm(test))
  }
  const handleSwitch = (e) => {
    const test = [...type]
    test[0].pl[0].isPublic = e.target.checked
    dispatch(setForm(test))
  }
  const handleSwitchShowCate = (e) => {
    const cate = [...type]
    cate[0].pl[0].isShowTags = e.target.checked
    dispatch(setForm(cate))
  }
  const handleSubmit = async () => {
    // Auto Add Category Field
    const test = [...type]
    const field = test[0]?.pl[0]?.fields
    const pagelist = test[0]?.type
    if (pagelist === 'list' && fieldValue.isShowTags) {
      field.unshift({
        type: 'category',
        placeholder: '',
        label: 'categories',
        isShow: false,
      })
      dispatch(setForm(test))
    }
    // // End Auto Add Category Field
    if (fieldValue?.isShowTags) {
      dispatch(submitForm(type, projectId, _camelCase(fieldValue?.name)))
    } else {
      dispatch(submitForm(type, projectId))
    }
  }
  const handleEditSubmit = async () => {
    const test = [...type]
    const pagelist = test[0].type
    if (pagelist === 'page') {
      dispatch(editAllData(projectId, 'page', indexF, type, history, pageId, dataStore, arrObjStore))
    } else if (pagelist === 'list') {
      dispatch(editAllData(projectId, 'list', indexF, type, history, listId, dataList, arrObjStore, listDocId))
    }
  }

  useEffect(() => {
    if (path === '/project/:projectId/page/:pageId/edit') {
      dispatch(editDataForm(projectId, pageId, 'page'))
      dispatch(getAndSetPrev(projectId, pageId))
    }
    if (path === '/project/:projectId/list/:listId/edit') {
      dispatch(editDataForm(projectId, listId, 'list'))
      dispatch(getAndSetPrevList(projectId, listId))
    }
    if (path === '/project/:projectId/create-form') {
      dispatch(resetForm())
    }
  }, [projectId, pageId, path, dispatch, listId])

  return (
    <>
      <AlertBar />
      <Paper className={classes.paper}>
        <Box className={classes.boxContainer}>
          <Typography variant="h6">{`${isUpdate ? 'Update Form' : 'Create Form'}`}</Typography>
          <Box mt={2} className={classes.typeBox}>
            <Typography variant="h6">Type:</Typography>
            <Select
              style={{ marginLeft: '24px' }}
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={type[0].type}
              variant="outlined"
              onChange={(e) => handleSequence(e)}
            >
              <MenuItem value="page" disabled={isUpdate}>Page</MenuItem>
              <MenuItem value="list" disabled={isUpdate}>List</MenuItem>
            </Select>
          </Box>

          <Box mt={3} className={classes.typeBox}>
            <Typography variant="h6">Name:</Typography>
            <TextField style={{ marginLeft: '16px' }} fullWidth variant="outlined" value={fieldValue.name} onChange={(e) => handleType(e)} />
          </Box>
          <Box mt={3} className={classes.typeBox}>
            {type[0].type === 'list' && (
            <Box>
              <Box mt={2} className={classes.typeBox}>
                <Checkbox
                  checked={fieldValue.isPublic}
                  onChange={handleSwitch}
                  color="primary"
                />
                <Typography variant="h6">Make list public</Typography>
              </Box>
              <Box mt={2} className={classes.typeBox}>
                <Checkbox
                  checked={fieldValue.isShowTags}
                  onChange={handleSwitchShowCate}
                  color="primary"
                />
                <Typography variant="h6">Create Category</Typography>
              </Box>
            </Box>
            )}

          </Box>
          {_map(type[0].pl, (x, i) => {
            const { fields } = x
            const typeId = `typeId_${i}`
            return (
              <React.Fragment key={typeId}>
                {_map(fields, (z, index) => {
                  const fieldId = `fieldId_${index}`
                  return (
                    <Box key={fieldId} mt={2} className={classes.typeBox}>
                      { z.type === 'arrayOfObj' ? (
                        <ArrObjfield index={index} />
                      ) : (
                        <Formcreate value={z.type} index={index} arrObj={false} fieldIndex={0} />
                      )}
                    </Box>
                  )
                })}
                <Box mt={2} className={classes.typeBox}>
                  <Typography variant="h6">Type:</Typography>
                  <Select
                    style={{ marginLeft: '24px' }}
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={select}
                    variant="outlined"
                    onChange={(e) => handleSelectedField(e)}
                  >
                    {
                      _map(menuItem, (e, index) => (
                        <MenuItem value={e.value} key={index}>
                          <Box className={classes.menuItemIcon}>
                            <Box mr={1} className={classes.menuItemIcon}>
                              {e.icon}
                            </Box>
                            <ListItemText primary={e.label} />
                          </Box>
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <Button size="medium" variant="outlined" color="primary" style={{ marginLeft: '20px' }} onClick={() => handleAddFieldSelect()}>ADD</Button>
                </Box>
              </React.Fragment>

            )
          })}

        </Box>
        <AppBar position="fixed" color="secondary" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.fabButton}>
              <Button
                type="submit"
                variant="contained"
                disabled={!(checkName && checkFields > 0) || loading}
                color="primary"
                onClick={isUpdate ? (handleEditSubmit) : (handleSubmit)}
              >
                submit
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </Paper>
    </>
  )
}

export default CreateFormPage

CreateFormPage.propTypes = {
  computedMatch: PropTypes.shape({}),
}
CreateFormPage.defaultProps = {
  computedMatch: {},
}
