import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import _get from 'lodash/get'
import _map from 'lodash/map'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#000000',
    color: '#fff',
  },
  action: {
    width: '83.42px !important',
  },
  cell: {
    padding: '10px 16px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  white: {
    color: '#fff !important',
    '& svg': {
      color: '#fff !important',
    },
    '&:hover': {
      color: '#fff !important',
      '& svg': {
        color: '#fff !important',
        opacity: '1 !important',
      },
    },
  },
}))

function MyHeader(props) {
  const classes = useStyles()
  const {
    names,
    isLoading,
  } = props

  const headerNames = [1, 2, 3]

  return (
    <TableHead className={classes.header} component="div">
      <TableRow component="div">
        <TableCell component="div" align="left" className={clsx(classes.action, classes.white)}>
          Created At
        </TableCell>
        {!isLoading ? (
          <>
            {
              _map(names, (headCell, i) => (
                <React.Fragment key={i}>
                  {headCell.isShow && (
                    <TableCell
                      component="div"
                      style={{ maxWidth: 'auto' }}
                      className={clsx(classes.cell, classes.white)}
                      align="left"
                      padding="default"
                    >
                      {_get(headCell, 'label')}
                    </TableCell>
                  )}
                </React.Fragment>
              ))
            }
          </>
        ) : (
          <>
            {
              _map(headerNames, (headCell, i) => (
                <TableCell
                  key={i}
                  component="div"
                  style={{ maxWidth: 'auto' }}
                  className={clsx(classes.cell, classes.white)}
                  align="left"
                  padding="default"
                />
              ))
            }
          </>

        )}
        {!isLoading && (
          <TableCell component="div" align="right" className={clsx(classes.action, classes.white)} />
        )}
      </TableRow>
    </TableHead>
  )
}

MyHeader.propTypes = {
  names: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default MyHeader
