import React from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _camelCase from 'lodash/camelCase'
import { useDispatch } from 'react-redux'

// Fields
import Array from '../Array'
import NotArrayFields from './NotArrayFields'
import * as fromAction from '../../../redux/actions/form.action'

function GenFieldForm(props) {
  const {
    projectId,
    schema,
    lang,
    allData,
    dataKey,
    selectLang,
    categories,
    disabled,
  } = props
  const dispatch = useDispatch()
  const handleChangeObject = (data, objKey, fieldLabel) => {
    dispatch(fromAction.changeValueIteratorObject(
      dataKey,
      _camelCase(fieldLabel),
      { key: objKey, value: data },
    ))
  }

  const handleChangeString = (data, fieldLabel) => {
    dispatch(fromAction.changeStringType(
      dataKey,
      _camelCase(fieldLabel),
      data,
    ))
  }

  const handleChangeNumber = (data, fieldLabel) => {
    dispatch(fromAction.changeNumberType(
      dataKey,
      _camelCase(fieldLabel),
      data,
    ))
  }

  const handleChangeBoolean = (data, fieldLabel) => {
    dispatch(fromAction.changeBooleanType(
      dataKey,
      _camelCase(fieldLabel),
      data,
    ))
  }

  const handleChangeArray = (data, fieldLabel) => {
    dispatch(fromAction.changeArrayType(
      dataKey,
      _camelCase(fieldLabel),
      data,
    ))
  }

  return (
    <>
      {_map(schema, ({
        label,
        placeholder,
        type,
        width,
        height,
        choices,
        array,
      }, i) => (
        <React.Fragment key={i}>
          {type === 'arrayOfObj' ? (
            <Array
              projectId={projectId}
              lang={lang}
              label={label}
              selectLang={selectLang}
              placeholder={placeholder}
              array={array}
              values={_get(allData, `${dataKey}.${_camelCase(label)}`) || ['']}
              handleOnChange={(arr, arrLabel) => handleChangeArray(arr, arrLabel)}
            />
          ) : (
            <NotArrayFields
              projectId={projectId}
              lang={lang}
              value={_get(allData, `${dataKey}.${_camelCase(label)}`) || ''}
              handleChangeObject={handleChangeObject}
              handleChangeString={handleChangeString}
              handleChangeNumber={handleChangeNumber}
              handleChangeBoolean={handleChangeBoolean}
              handleChangeTag={handleChangeArray}
              label={label}
              placeholder={placeholder}
              type={type}
              width={width}
              height={height}
              choices={choices}
              selectLang={selectLang}
              categories={categories}
              disabled={disabled}
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default GenFieldForm

GenFieldForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  schema: PropTypes.arrayOf(PropTypes.object).isRequired,
  allData: PropTypes.objectOf(PropTypes.any).isRequired,
  lang: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataKey: PropTypes.string.isRequired,
  selectLang: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
}

GenFieldForm.defaultProps = {
  categories: [],
  disabled: false,
}
