import _isString from 'lodash/isString'
import _isNumber from 'lodash/isNumber'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _camelCase from 'lodash/camelCase'
import _isObject from 'lodash/isObject'
import {
  LOAD_NEW_FORM,
  SET_NEW_FORM,
  SET_NEW_DATA_FORM,
  CHANGE_STRING_TYPE,
  CHANGE_NUMBER_TYPE,
  CHANGE_OBJECT_TYPE,
  CHANGE_BOOLEAN_TYPE,
  CHANGE_OBJECT_LNG_TYPE,
  CHANGE_ARRAY_TYPE,
  CLEAR_FORM,
  GET_EDIT_DATA,
} from '../actions/form.action'

const initialState = {
  view: {
    loadingLabel: true,
    loadingValue: true,
    status: null,
    label: [],
    value: {},
    errorMessage: [],
  },
}

const changeValueNotIterator = (state, functionKeyCheck, selectedPage, objectPage, newValue) => {
  const checkValueSelectPage = !_isString(selectedPage) ? 'selctedPage is string type' : ''
  const checkValueObjectPage = !_isString(objectPage) ? 'objectPage is string type' : ''
  const checkValueNewValue = !functionKeyCheck(newValue) ? 'selctedPage is string type' : ''
  if (checkValueSelectPage || checkValueObjectPage || checkValueNewValue) {
    let message = ''
    if (checkValueSelectPage) {
      message += checkValueSelectPage
    }
    if (checkValueObjectPage) {
      if (checkValueSelectPage) message += ', '
      message += checkValueObjectPage
    }
    if (checkValueNewValue) {
      if (checkValueSelectPage || checkValueObjectPage) message += ', '
      message += checkValueNewValue
    }
    return {
      ...state,
      status: 'form/edit',
      errorMessage: [...state?.view?.errorMessage, `error ${message}`],
    }
  }
  return {
    ...state,
    status: 'form/edit',
    value: {
      ...state.value,
      [selectedPage]: {
        ...state.value[selectedPage],
        [objectPage]: newValue,
      },
    },
  }
}

const changeValueIteratorObject = (state, functionKeyCheck, selectedPage, objectPage, newValue) => {
  const checkValueSelectPage = !_isString(selectedPage) ? 'selctedPage is string type' : ''
  const checkValueObjectPage = !_isString(objectPage) ? 'objectPage is string type' : ''
  const checkValueNewValue = !_isObject(newValue) ? 'selctedPage is object type' : ''
  if (checkValueSelectPage || checkValueObjectPage || checkValueNewValue) {
    let message = ''
    if (checkValueSelectPage) {
      message += checkValueSelectPage
    }
    if (checkValueObjectPage) {
      if (checkValueSelectPage) message += ', '
      message += checkValueObjectPage
    }
    if (checkValueNewValue) {
      if (checkValueSelectPage || checkValueObjectPage) message += ', '
      message += checkValueNewValue
    }
    return {
      ...state,
      status: 'form/edit',
      errorMessage: [...state.view.errorMessage, `errors ${message} `],
    }
  }
  const newObjectPage = _camelCase(objectPage)

  return {
    ...state,
    status: 'form/edit',
    value: {
      ...state.value,
      [selectedPage]: {
        ...state.value[selectedPage],
        [newObjectPage]: {
          ...state.value[selectedPage][newObjectPage],
          [newValue.key]: newValue.value,
        },
      },
    },
  }
}

const changeValueIteratorArray = (state, selectedPage, objectPage, newValue) => {
  const checkValueSelectPage = !_isString(selectedPage) ? 'selctedPage is string type' : ''
  const checkValueObjectPage = !_isString(objectPage) ? 'objectPage is string type' : ''
  const checkValueNewValue = !_isObject(newValue) ? 'selctedPage is array type' : ''
  if (checkValueSelectPage || checkValueObjectPage || checkValueNewValue) {
    let message = ''
    if (checkValueSelectPage) {
      message += checkValueSelectPage
    }
    if (checkValueObjectPage) {
      if (checkValueSelectPage) message += ', '
      message += checkValueObjectPage
    }
    if (checkValueNewValue) {
      if (checkValueSelectPage || checkValueObjectPage) message += ', '
      message += checkValueNewValue
    }
    return {
      ...state,
      status: 'form/edit',
      errorMessage: [...state.view.errorMessage, `errors ${message} `],
    }
  }
  const newObjectPage = _camelCase(objectPage)
  return {
    ...state,
    status: 'form/edit',
    value: {
      ...state.value,
      [selectedPage]: {
        ...state.value[selectedPage],
        [newObjectPage]: newValue,
      },
    },
  }
}

const viewReducer = (state = initialState.view, action) => {
  // const newData = { ...state }
  // const selectedPage = action?.payload?.selectedPage
  // const fieldName = action?.payload?.newValue?.fieldName
  // const fieldValue = action?.payload?.newValue?.fieldValue
  // const lang = action?.payload?.newValue?.lang
  switch (action.type) {
    case LOAD_NEW_FORM:
      return {
        ...state,
        loadingLabel: true,
        loadingValue: true,
        status: '',
        label: [],
        value: {},
      }
    case CLEAR_FORM:
      return {
        ...state,
        status: '',
        label: [],
        value: {},
      }
    case SET_NEW_FORM:
      return {
        ...state,
        status: 'form/edit-set-up',
        loadingLabel: false,
        label: action.payload.formPage,
        isPublic: action.payload.isPublic,
        isShowTags: action.payload.isPublic,
      }
    case SET_NEW_DATA_FORM:
      return {
        ...state,
        loadingValue: false,
        status: 'form/edit-data-set-up',
        value: action.payload.formData,
      }
    case CHANGE_STRING_TYPE: {
      return changeValueNotIterator(state, _isString, action.payload.selectedPage,
        action.payload.objectPage, action.payload.newValue)
    }
    case CHANGE_OBJECT_LNG_TYPE: {
      return changeValueIteratorObject(state, _isObject, action.payload.selectedPage,
        action.payload.objectPage, action.payload.newValue)
    }
    case CHANGE_NUMBER_TYPE: {
      return changeValueNotIterator(state, _isNumber, action.payload.selectedPage,
        action.payload.objectPage, action.payload.newValue)
    }
    case CHANGE_BOOLEAN_TYPE: {
      return changeValueNotIterator(state, _isBoolean, action.payload.selectedPage,
        action.payload.objectPage, action.payload.newValue)
    }
    case CHANGE_OBJECT_TYPE: {
      if (_isEmpty(action.payload.newValue)) {
        return {
          ...state,
          errorMessage: [state.errorMessage, 'error object is empty'],
        }
      }
      return changeValueNotIterator(state, _isObject, action.payload.selectedPage,
        action.payload.objectPage, action.payload.newValue)
    }
    case CHANGE_ARRAY_TYPE: {
      return changeValueIteratorArray(state, action.payload.selectedPage,
        action.payload.objectPage, action.payload.newValue)
    }
    case GET_EDIT_DATA: {
      return {
        ...state,
        loadingValue: false,
        value: action.payload.data,
      }
    }
    default:
      return state
  }
}

// const viewDataReducer = (state = initialState.viewData, action) => {
//   const newData = { ...state }
//   const selectedPage = action?.payload?.selectedPage
//   const fieldName = action?.payload?.newValue?.fieldName
//   const fieldValue = action?.payload?.newValue?.fieldValue
//   const lang = action?.payload?.newValue?.lang
//   switch (action.type) {

//     case ADD_CHANGE:
//       newData.value[selectedPage][fieldName][lang] = fieldValue
//       return {
//         ...state,
//         status: 'form/edit',
//         value: { ...newData.value },
//       }

//     default:
//       return state
//   }
// }

export default viewReducer
