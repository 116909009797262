import _get from 'lodash/get'
import {
  SET_AUTHTOKEN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECK_LOGIN,
  LOGOUT,
  ERROR_MESSAGE,
  UPDATE_LOADING_STATUS,
} from '../actions/user.actions'

const initialState = {
  authToken: window.localStorage.getItem('authToken'),
  loading: true,
  isLogin: false,
  userUid: null,
  userData: null,
  errorMessage: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHTOKEN: {
      const { token } = action
      return {
        ...state,
        authToken: token,
      }
    }
    case CHECK_LOGIN: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLogin: true,
        userUid: action.payload.userUid,
        userData: action.payload.userData,
      }
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        isLogin: false,
        userUid: null,
        errorMessage: !_get(action, 'payload.message') ? state.errorMessage : [...state.errorMessage, action.payload.message],
      }
    }
    case LOGOUT: {
      return {
        ...state,
        loading: false,
        isLogin: false,
        userUid: null,
        userData: null,
        errorMessage: !_get(action, 'payload.message') ? state.errorMessage : [...state.errorMessage, action.payload.message],
      }
    }
    case ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: !_get(action, 'payload.message') ? state.errorMessage : [...state.errorMessage, action.payload.message],
      }
    }
    case UPDATE_LOADING_STATUS: {
      const checkValueLoading = !!_get(action, 'payload.newStatusLoading')
      let newState = {}
      if (checkValueLoading) {
        newState = {
          ...state,
          loading: action.payload.newStatusLoading,
        }
      } else {
        newState = {
          ...state,
          errorMessage: [...state.errorMessage, 'parameter was not found in user update status loading '],
        }
      }
      return newState
    }
    default: {
      return state
    }
  }
}

export default reducer
