import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Loading from '../../components/Loading'
import { getUser } from '../../redux/selectors/user.selectors'

function AuthRoute(props) {
  const {
    layout: Layout,
    page: Component,
    loginRequired: isLoginRequired,
  } = props

  const user = useSelector(getUser)

  let output = ''

  if (user.loading) {
    output = <Loading />
  } else if (!user.isLogin && isLoginRequired) {
    output = <Redirect to="/login" />
  } else if (user.isLogin && !isLoginRequired) {
    output = <Redirect to="/" />
  } else if ((user.isLogin && isLoginRequired) || (!user.isLogin && !isLoginRequired)) {
    output = <Layout><Component {...props} /></Layout>
  }

  return (
    <Route
      render={() => (
        output
      )}
    />
  )
}

AuthRoute.propTypes = {
  layout: PropTypes.func.isRequired,
  page: PropTypes.elementType.isRequired,
  loginRequired: PropTypes.bool.isRequired,
}

export default AuthRoute
