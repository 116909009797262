import React, { useState, useEffect } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import _orderBy from 'lodash/orderBy'
import _includes from 'lodash/includes'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CardActions from '@material-ui/core/CardActions'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { v1 as uuidv1 } from 'uuid'
import imageCompression from 'browser-image-compression'

import firebase from '../../firebase'

const uploadImage = async (projectId, file, getMedia) => {
  const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 4000,
    useWebWorker: true,
  }
  const storageRef = firebase.storage().ref()
  const name = file.name.split('.').slice(0, -1)[0]
  const extension = file.name.split('.').slice(-1)[0]
  const filename = `${_kebabCase(name)}.${extension}`
  const fileRef = storageRef.child(`${projectId}/${uuidv1()}-${filename}`)

  try {
    const compressedFile = await imageCompression(file, options)
    const snapshot = await fileRef.put(compressedFile)
    const downloadURL = snapshot.ref.getDownloadURL()
    getMedia()
    return downloadURL
  } catch (error) {
    console.log(error)
    return ''
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
  },
  modalBox: {
    display: 'flex',
  },
  cardMedia: {
    height: '150px',
    width: '100%',
    '&:hover': {
      opacity: '0.8',
    },
    borderRadius: '4px',
  },
  imgDatail: {
    color: '#aeaeae',
    fontSize: '13px',
  },
  detailBox: {
    marginBottom: '8px',
  },
  videoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    width: '100%',
    height: '100%',
  },
  modalCardMedia: {
    maxHeight: '600px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '400px',
    },
    objectFit: 'contain',
  },
  mobileNav: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    position: 'fixed',
    width: 'calc(100% - 60px)',
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
  },
}))
function MediaPage(props) {
  const classes = useStyles()
  const { computedMatch } = props
  const projectId = _get(computedMatch, 'params.projectId')
  const [imageLists, setImageLists] = useState([])
  const [modalImg, setModalImg] = useState({})
  const [open, setOpen] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const storageRef = firebase.storage().ref(`${projectId}`)

  const getMedia = async () => {
    try {
      const response = await storageRef.listAll()
      const imgList = []
      response.items.forEach(async (data) => {
        imgList.push(
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve) => {
            const url = await data.getDownloadURL()
            const metadata = await data.getMetadata()
            resolve({
              url,
              timeCreated: metadata?.timeCreated,
              fileType: metadata?.contentType,
              fileName: metadata?.name,
            })
          }),
        )
      })
      const result = await Promise.all(imgList)
      const orderResult = _orderBy(result, 'timeCreated', 'desc')
      setImageLists(orderResult)
    } catch (error) {
      console.debug(error, 'get')
      setImageLists([])
    }
  }

  useEffect(() => {
    getMedia()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpload = async (event) => {
    const file = event.target.files[0]
    setLoading(true)
    try {
      await uploadImage(projectId, file, getMedia)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleModal = (image, timeCreated, fileName, fileType) => {
    setModalImg({
      image,
      timeCreated,
      fileName,
      fileType,
    })
    setOpen(true)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      const storage = firebase.storage()
      const fileRef = storage.refFromURL(modalImg?.image)
      await fileRef.delete()
      await getMedia()
      setDialog(false)
      setOpen(false)
      setLoading(false)
    } catch (error) {
      console.debug(error, 'delete')
    }
  }

  return (
    <Box>
      <Card>
        <Box
          bgcolor="#fff"
          margin={3}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h4"
          >
            Media
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
            alignItems={{ xs: 'stretch', md: 'center' }}
          >
            <CardActions>
              <label htmlFor={`${projectId}`}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id={_camelCase(`${projectId}`)}
                  name={_camelCase(`${projectId}`)}
                  onChange={handleUpload}
                />
                <Button
                  color="primary"
                  htmlFor={`${projectId}`}
                  variant="contained"
                  component="div"
                  disabled={loading}
                  endIcon={<CloudUploadIcon />}
                >
                  Upload New Picture
                </Button>
              </label>
            </CardActions>
          </Box>
        </Box>
        <Divider light />
        <Box
          p={3}
          height="100%"
        >
          <Grid container spacing={2}>
            {_map(imageLists, (img, i) => (
              <Grid key={i} item xs={6} sm={4} md={3} lg={2}>
                {_includes(img.fileType, 'image') ? (
                  <CardActionArea
                    onClick={() => handleModal(
                      img?.url,
                      img?.timeCreated,
                      img?.fileName,
                      img?.fileType,
                    )}
                  >
                    <CardMedia
                      image={img?.url}
                      component="img"
                      className={classes.cardMedia}
                    />
                  </CardActionArea>
                ) : (
                  <Box className={classes.videoBox}>
                    <Typography variant="body1">VIDEO</Typography>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Card>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card
            className={classes.modalBox}
          >
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <Box style={{ backgroundColor: 'black' }}>
                  <Box className={classes.mobileNav}>
                    <IconButton
                      color="secondary"
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <CardMedia
                    image={modalImg?.image}
                    component="img"
                    className={classes.modalCardMedia}
                  />
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    width="100%"
                    justifyContent="flex-end"
                    display={{ xs: 'none', md: 'flex' }}
                  >
                    <IconButton onClick={() => setOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box padding={3}>
                    <Box mb={3}>
                      <Box className={classes.detailBox}>
                        <Typography variant="body2" className={classes.imgDatail}>File name</Typography>
                        <Typography variant="body1">
                          {` ${modalImg?.fileName}`}
                        </Typography>
                      </Box>
                      <Box className={classes.detailBox}>
                        <Typography variant="body2" className={classes.imgDatail}>File Type</Typography>
                        <Typography variant="body1">
                          {`${modalImg?.fileType}`}
                        </Typography>
                      </Box>
                      <Box className={classes.detailBox}>
                        <Typography variant="body2" className={classes.imgDatail}>Uploaded on</Typography>
                        <Typography variant="body1">
                          {`${new Date(modalImg?.timeCreated).toDateString()}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider light />
                    <Box mt={3}>
                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => setDialog(true)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                  <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={dialog}
                    onClose={() => setDialog(false)}
                  >
                    <DialogTitle disableTypography>Are you sure to delete?</DialogTitle>
                    <DialogActions>
                      <Button
                        size="small"
                        style={{ marginRight: '8px' }}
                        onClick={() => setDialog(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleDelete}
                      >
                        delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Fade>
      </Modal>
    </Box>
  )
}

export default MediaPage

MediaPage.propTypes = {
  computedMatch: PropTypes.shape({}),
}
MediaPage.defaultProps = {
  computedMatch: {},
}
