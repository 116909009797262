// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const { REACT_APP_BUILD_LEVEL } = process.env

// Initialize Firebase
let firebaseConfig = {}
if (REACT_APP_BUILD_LEVEL === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyCP5z5ZFYKK1s_r5ikL2ROube84KISmpFA',
    authDomain: 'foxbith-cms.firebaseapp.com',
    databaseURL: 'https://foxbith-cms.firebaseio.com',
    projectId: 'foxbith-cms',
    storageBucket: 'foxbith-cms.appspot.com',
    messagingSenderId: '1045585209085',
    appId: '1:1045585209085:web:0258b7174761344d568094',
    measurementId: 'G-P16ZV3XEJW',
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyDUIr7wdyLOKZVcLGMFVvAppJ2JJqYpDog',
    authDomain: 'foxbith-cms-dev.firebaseapp.com',
    projectId: 'foxbith-cms-dev',
    storageBucket: 'foxbith-cms-dev.appspot.com',
    messagingSenderId: '919954140766',
    appId: '1:919954140766:web:f42151bb9e4253e5bd8ecf',
    measurementId: 'G-LS9DHQ8WYZ',
  }
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default firebase
