import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _camelCase from 'lodash/camelCase'
import _isArray from 'lodash/isArray'
import _startCase from 'lodash/startCase'
import _capitalize from 'lodash/capitalize'
import _toString from 'lodash/toString'
import _size from 'lodash/size'
import _head from 'lodash/head'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { withStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import {
  format, addDays,
  fromUnixTime,
} from 'date-fns'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Link as LinkDom, useHistory, Link } from 'react-router-dom'
import _upperFirst from 'lodash/upperFirst'
import { Typography, Box } from '@material-ui/core'
import _kebabCase from 'lodash/kebabCase'
import { getUser } from '../../redux/selectors/user.selectors'
import { handleDeletePage } from '../../redux/actions/createform.action'
import DateRangePicker from '../../components/DateRangePicker'
import MyTable from '../../components/Table'
import s from './ListTable.module.scss'
import firebase from '../../firebase'
import AlertBar from '../../components/NoticeMessage/SnackBar'
import {
  getList,
  getRow,
  getLoadingList,
  getLoadingRow,
  getAllList,
} from '../../redux/selectors/list.selectors'
import {
  getListData,
  getRowData,
  clearVisibleData,
} from '../../redux/actions/list.action'

const styles = (theme) => ({
  textField: {
    [theme.breakpoints.down('sm')]: {
      height: '48px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '38px',
    },
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  box: {
    width: '90vw',
  },
})

function rowTransform(snapshots) {
  const newRowData = []
  snapshots.forEach((data) => {
    const newData = data.data()
    newData.uid = data.id
    newRowData.push(newData)
  })
  return newRowData
}

function isValidDate(d) {
  return JSON.stringify(d) !== 'null'
}

function ListTable(props) {
  const pathListId = _get(props, 'computedMatch.params.listId')
  const pathProject = _get(props, 'computedMatch.params.projectId')
  const history = useHistory()
  const dispatch = useDispatch()
  const listData = useSelector(getList)
  const rowData = useSelector(getRow)
  const loadingList = useSelector(getLoadingList)
  const loadingRow = useSelector(getLoadingRow)
  const allList = useSelector(getAllList)
  const user = useSelector(getUser)
  const [tableLength, setTableLength] = React.useState(0)
  const [language, setLanguage] = React.useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const [csv, setCsv] = React.useState([])
  const FILE_NAME_DATA = `${pathProject}-${pathListId}.csv`
  const open = Boolean(anchorEl)
  // Table
  const [loading, setLoading] = useState(true)
  const [showLoadmore, setShowLoadmore] = useState(false)
  const [lastSnap, setLastSnap] = useState(null)

  const [date, setDateObj] = useState({
    start: null,
    end: null,
    error: true,
  })

  let filterList = []

  listData.map((data) => {
    if (_camelCase(data.name) === _camelCase(pathListId)) {
      filterList = data
    }
    return filterList
  })

  const handleChangeDate = (data) => {
    setDateObj(data)
    if (isValidDate(data.start) && isValidDate(data.end)) {
      setShowLoadmore(false)
    }
  }

  const queryData = async (collectionName, dateStart, dateEnd) => {
    setLoading(true)
    setShowLoadmore(false)
    let queryPrefix = firebase.firestore()
      .collection(collectionName).doc(_camelCase(pathProject)).collection(_camelCase(pathListId))
    if (isValidDate(dateStart) && isValidDate(dateEnd)) {
      queryPrefix = queryPrefix
        .where('createdAt', '>=', addDays(new Date(dateStart), 1))
        .where('createdAt', '<=', addDays(new Date(dateEnd), 1))
        .orderBy('createdAt', 'desc')
    } else {
      queryPrefix = queryPrefix.orderBy('createdAt', 'desc').limit(25)
    }
    const result = await queryPrefix.get()
    if (result.docs.length >= 25 && !(isValidDate(dateStart) && isValidDate(dateEnd))) {
      setShowLoadmore(true)
    } else {
      setShowLoadmore(false)
    }
    setLastSnap(result.docs[result.docs.length - 1])

    const output = rowTransform(result)
    return output
  }

  const handleLoadmore = async () => {
    setLoading(true)
    const collectionName = filterList.isPublic ? 'publicData' : 'data'
    const queryPrefix = firebase.firestore()
      .collection(collectionName).doc(_camelCase(pathProject)).collection(_camelCase(pathListId))

    const result = await queryPrefix.orderBy('createdAt', 'desc')
      .startAfter(lastSnap).limit(25).get()

    if (result.docs.length >= 25) {
      setShowLoadmore(true)
      setLastSnap(result.docs[result.docs.length - 1])
    } else {
      setShowLoadmore(false)
    }

    const output = rowTransform(result)
    dispatch(getRowData([...rowData, ...output], []))
    setLoading(false)
  }

  const getTableData = async () => {
    setLoading(true)

    try {
      const data = await firebase.firestore().collection('projects').doc(_camelCase(pathProject)).get()
      const allLanguage = _get(data.data(), 'schema.lang')
      const schemaList = _get(data.data(), 'schema.list')

      dispatch(getListData(schemaList))
      setLanguage(_head(allLanguage))
      dispatch(clearVisibleData())
      const csvArray = []
      const pushRow = await queryData(filterList.isPublic ? 'publicData' : 'data', date.start, date.end)

      if (!_isEmpty(filterList)) {
        pushRow.map((sub, index) => {
          // updatedAt field
          csvArray[index] = {
            ...csvArray[index],
            createdAt: format(fromUnixTime(sub.createdAt.seconds), 'yyyy-MM-dd HH:mm:ss'),
          }
          filterList.fields.map((fieldType) => {
            const fieldLabel = _camelCase(fieldType.label)

            if (fieldType.type === 'simtext' || fieldType.type === 'richtext') {
              for (let i = 0; i < allLanguage.length; i += 1) {
                const arrKey = `${fieldLabel}${_upperFirst(allLanguage[i])}`
                const arrValue = _get(sub, `${fieldLabel}.${allLanguage[i]}`)
                csvArray[index] = {
                  ...csvArray[index],
                  [arrKey]: arrValue,
                }
              }
            } else if (fieldType.type === 'geoPoint') {
              csvArray[index] = {
                ...csvArray[index],
                [`${fieldLabel}Lat`]: _get(sub, `${fieldLabel}.lat`),
                [`${fieldLabel}Lng`]: _get(sub, `${fieldLabel}.lng`),
              }
            } else if (fieldType.type === 'boolean') {
              csvArray[index] = {
                ...csvArray[index],
                [fieldLabel]: _upperFirst(_toString(_get(sub, `${fieldLabel}`))),
              }
            } else {
              csvArray[index] = {
                ...csvArray[index],
                [fieldLabel]: _get(sub, `${fieldLabel}`),
              }
            }
            return true
          })
          return csvArray
        })
      }
      setCsv(csvArray)
      setTableLength(_size(pushRow))
      dispatch(getRowData(pushRow, []))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenPopup = () => {
    setOpenPopup(true)
    setAnchorEl(null)
  }

  const handleClosePopup = () => {
    setOpenPopup(false)
    setAnchorEl(null)
  }
  const handleDelete = () => {
    dispatch(handleDeletePage(pathProject, pathListId, history, 'list'))
  }
  // update list collection
  useEffect(() => {
    getTableData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList.isPublic])

  // using filter in the page
  useEffect(() => {
    getTableData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.end])

  // when changing to the new page
  useEffect(() => {
    setDateObj({
      start: null,
      end: null,
      error: true,
    })
    getTableData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathListId, pathProject, allList.deleteDummy])

  return (
    <>
      <AlertBar />
      <Typography variant="h4">
        {_capitalize(_startCase(pathListId))}
      </Typography>
      <Box mb={2} className={s.buttonCreate}>
        <Box className={s.btIcon}>
          <Box className={s.boxItem}>
            <CSVLink data={csv} filename={FILE_NAME_DATA}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<SaveAltIcon />}
              >
                Export
              </Button>
            </CSVLink>
          </Box>
          <Link
            to={`/project/${pathProject}/list/${_kebabCase(pathListId)}/create`}
            style={{ textDecoration: 'none' }}
            className={s.button}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<AddCircleOutlineIcon />}
              fullWidth
            >
              Add new Product
            </Button>
          </Link>
          {user.userData.type === 'foxbith' && (
            <Box className={s.moreVertIcon}>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >

                <List>
                  <LinkDom
                    to={`/project/${pathProject}/list/${_kebabCase(pathListId)}/edit`}
                    style={{ color: 'black' }}
                  >
                    <ListItem
                      button
                    >
                      Edit
                    </ListItem>
                  </LinkDom>
                  <ListItem
                    button
                    onClick={handleOpenPopup}
                  >
                    Delete
                  </ListItem>
                </List>
              </Popover>
              <Dialog
                open={openPopup}
                onClose={handleClosePopup}
              >
                <DialogTitle>Are you sure you want to delete this item ?</DialogTitle>
                <DialogActions style={{ padding: '16px 24px' }}>
                  <Button onClick={handleClosePopup} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleDelete} color="primary" autoFocus disabled={loading} variant="contained">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </Box>

      </Box>
      <Box mb={3} display="flex" className={s.box}>
        <Box className={s.boxItem}>
          <DateRangePicker
            onChange={handleChangeDate}
            value={date}
          />
        </Box>
        <Box className={s.lBox}>
          <Typography className={s.numItem}>
            {`${tableLength} Item`}
          </Typography>
        </Box>
      </Box>
      <Box className={s.boxtable}>
        <MyTable
          columns={loading ? [] : filterList.fields}
          isLoading={loading || loadingList || loadingRow || !_isArray(rowData)}
          pathListId={_camelCase(pathListId)}
          pathListProject={_camelCase(pathProject)}
          publicProject={filterList.isPublic}
          language={language}
          rows={loading ? [] : rowData}
          onLoadmore={handleLoadmore}
          showLoadmore={showLoadmore}
        />
      </Box>
    </>
  )
}

export default withStyles(styles)(ListTable)
