import _get from 'lodash/get'
import {
  GET_PAGE,
  UPDATE_PAGE,
  UPDATE_LOADING_STATUS,
  ERROR_MESSAGE,
} from '../actions/page.actions'

const initialState = {
  loading: true,
  pageCurrent: [],
  errorMessage: [],
  timeStamp: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAGE: {
      // TODO: controller get data mork
      return {
        ...state,
        pageCurrent: action.payload.initDataPage,
        loading: false,
      }
    }
    case UPDATE_PAGE: {
      const checkValueNewPage = !!_get(action, 'newPage')
      let newState = {}
      if (checkValueNewPage) {
        newState = {
          ...state,
          pageCurrent: action.payload.newPage,
          timeStamp: action.payload.updateTimeStamp,
        }
      } else {
        newState = {
          ...state,
          errorMessage: [...state.errorMessage, 'Can not update page'],
        }
      }
      return newState
    }
    case UPDATE_LOADING_STATUS: {
      return {
        ...state,
        loading: action.payload.status,
      }
    }
    case ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: !_get(action, 'payload.message') ? state.errorMessage : [...state.errorMessage, action.payload.message],
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
