/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import {
  useSelector,
  useDispatch,
} from 'react-redux'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _kebabCase from 'lodash/kebabCase'
import _isObject from 'lodash/isObject'

import CardProject from '../../components/CardProject'
import CardCreateProject from '../../components/CardCreateProject'
import Loading from '../../components/Loading'
import AlertBar from '../../components/NoticeMessage/SnackBar'
import { getUser } from '../../redux/selectors/user.selectors'
import * as projectAction from '../../redux/actions/project.actions'
import FirebaseProject from '../../utils/FirebaseProject/FirebaseProject'

function Content() {
  const user = useSelector(getUser)
  const userType = _get(user, 'userData.type')
  const dispatch = useDispatch()
  const [activeProjects, setActiveProjects] = useState([])
  const [archiveProjects, setArchiveProjects] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getProjectFromFirebase = async () => {
      setLoading(true)
      const docProjectID = userType === 'enduser' ? _get(user, 'userData.projectId', '') : _get(user, 'userData.companyId', '')
      try {
        const activeSnapshot = await FirebaseProject.getProject(userType, docProjectID)
        const activeProjectArr = []
        for await (const data of activeSnapshot) {
          const objData = data.data()
          let companyId = ''
          if (_isObject(objData.companyId)) {
            companyId = await objData.companyId.get()
            companyId = {
              ...companyId.data(),
              uid: companyId.id.trim(),
            }
          }
          activeProjectArr.push({
            uid: data.id.trim(),
            ...objData,
            companyId,
          })
        }

        const archiveProjectArr = []
        if (userType === 'foxbith') {
          const archiveSnapshot = await FirebaseProject.getProject(userType, docProjectID, false)
          for await (const data of archiveSnapshot) {
            const objData = data.data()
            let companyId = ''
            if (_isObject(objData.companyId)) {
              companyId = await objData.companyId.get()
              companyId = {
                ...companyId.data(),
                uid: companyId.id.trim(),
              }
            }
            archiveProjectArr.push({
              uid: data.id.trim(),
              ...objData,
              companyId,
            })
          }
        }

        setActiveProjects(activeProjectArr)
        setArchiveProjects(archiveProjectArr)
      } catch (error) {
        console.log(error)
        dispatch(projectAction.getErrorMessageProject(error))
      }
      setLoading(false)
    }

    if (userType) {
      getProjectFromFirebase()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType])

  return (
    <>
      { loading
        ? <Loading />
        : (
          <>
            { _isEmpty(activeProjects)
              ? (
                <Route>
                  <Redirect to="/no-project" />
                </Route>
              )
              : (
                <>
                  { user.userData.type === 'enduser'
                    ? (
                      <Route>
                        <Redirect to={{ pathname: `/project/${_kebabCase(activeProjects[0].uid)}` }} />
                      </Route>
                    )
                    : (
                      <Container maxWidth="md">
                        <AlertBar />
                        <Box mb={3}>
                          <Typography
                            variant="h4"
                            color="secondary"
                            style={{ fontWeight: 800 }}
                          >
                            All Projects
                          </Typography>
                        </Box>
                        <Grid container spacing={4}>
                          {_get(user, 'userData.type') === 'foxbith' && (
                            <Grid item xs={12} md={4}>
                              <CardCreateProject />
                            </Grid>
                          )}
                          {activeProjects.map((item, i) => (
                            <Grid key={i} item xs={12} md={4}>
                              <CardProject
                                data={item}
                                userType={_get(user, 'userData.type')}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        {(_get(user, 'userData.type') === 'foxbith' && archiveProjects.length > 0) && (
                          <>
                            <Box mb={3} mt={10}>
                              <Typography
                                color="textPrimary"
                                variant="h4"
                                style={{ fontWeight: 800 }}
                              >
                                Archived Projects
                              </Typography>
                            </Box>
                            <Grid container spacing={4}>
                              {archiveProjects.map((item, i) => (
                                <Grid key={i} item xs={12} md={4}>
                                  <CardProject
                                    disabled
                                    data={item}
                                    userType={_get(user, 'userData.type')}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      </Container>
                    )}
                </>
              )}
          </>
        )}
    </>
  )
}

export default Content
