import React, { useState, useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import imageCompression from 'browser-image-compression'

import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _toLower from 'lodash/toLower'
import _includes from 'lodash/includes'
import _orderBy from 'lodash/orderBy'
import _kebabCase from 'lodash/kebabCase'
import _camelCase from 'lodash/camelCase'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import TextFeild from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CardMedia from '@material-ui/core/CardMedia'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import AllCategories from '../../components/Categories'
import * as alertAction from '../../redux/actions/alert.actions'
import AlertBar from '../../components/NoticeMessage/SnackBar'
import firebase from '../../firebase'

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px',
    height: '100%',
    minHeight: '400px',
    maxHeight: '800px',
  },
  gridContainer: {
    width: '100%',
    height: '100%',
  },
  centerBox: {
    height: '100%',
    padding: '16px',
  },
  typography: {
    marginBottom: '16px',
  },
  textfield: {
    marginBottom: '16px',
  },
  helpertext: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 0,
  },
}))

export default function TagList(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { computedMatch } = props

  const [image, setImage] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [delCategory, setDelCategory] = useState('')
  const [duplicate, setDuplicate] = useState(false)
  const [duplicateEdit, setDuplicateEdit] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const projectId = _get(computedMatch, 'params.projectId')
  const database = firebase.firestore()
  const categoriesRef = database.collection('data').doc(projectId).collection('categories')

  const handleTextField = (value, type) => {
    switch (type) {
      case 'Name':
        setName(_toLower(value))
        break
      case 'Description':
        setDesc(value)
        break
      default:
        break
    }
  }

  const getCategories = async () => {
    const categories = []
    setLoading(true)
    try {
      const response = await categoriesRef.where('listId', '==', _kebabCase(_get(computedMatch, 'params.listId')))
        .get()
      response.docs.forEach((res) => {
        categories.push({
          id: res?.id,
          name: res?.data().name,
          createdAt: res?.data().createdAt,
          description: res?.data().description,
          ...res?.data(),
        })
      })
      const sortedCategories = _orderBy(categories, 'createdAt', 'desc')
      setCategory(sortedCategories)
    } catch (error) {
      setCategory([])
      console.log('get categories error!', error)
    } finally {
      setLoading(false)
    }
  }

  const addCategory = async () => {
    setLoading(true)
    try {
      await categoriesRef.add({
        name,
        description: desc,
        image,
        listId: _get(computedMatch, 'params.listId'),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true })
      await getCategories()
      setName('')
      setDesc('')
      setImage('')
    } catch (error) {
      console.log('add category error!', error)
    } finally {
      setLoading(false)
    }
  }

  const deleteCategory = async () => {
    setLoading(true)
    try {
      await categoriesRef.doc(delCategory).delete()
    } catch (error) {
      console.log('delete category error!', error)
    } finally {
      setTimeout(() => {
        getCategories()
      }, 800)
    }
  }

  const editCategory = async (editCateData) => {
    setLoading(true)
    try {
      const data = {
        name: editCateData?.name,
        description: editCateData?.desc,
        image: editCateData?.image,
      }
      await categoriesRef.doc(editCateData?.id).update(data)
      setTimeout(() => {
        setOpenEditDialog(false)
        getCategories()
      }, 800)
    } catch (error) {
      console.log('Edit category error!', error)
      dispatch(alertAction.showMessage('error', 'Edit category error.'))
    }
  }

  const handleAddCategory = async () => {
    const categoryName = category.map((value) => value?.name)
    if (_includes(categoryName, `${name}`)) {
      setDuplicate(true)
    } else {
      addCategory()
      setOpenEditDialog(false)
    }
  }

  const handleEditCategory = async (editCateData) => {
    const categoryName = category.map((value) => value?.name)
    const selectedCategoryName = _filter(
      categoryName,
      (item) => item !== editCateData?.originalName,
    )
    if (_includes(selectedCategoryName, `${editCateData?.name}`)) {
      setDuplicateEdit(true)
    } else {
      editCategory(editCateData)
    }
  }

  // rerender when Tags Pages Changes
  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_get(computedMatch, 'params.listId')])

  const uploadImage = async (projectId1, file) => {
    const options = {
      maxSizeMB: 10,
      maxWidthOrHeight: 4000,
      useWebWorker: true,
    }
    const storageRef1 = firebase.storage().ref()
    const name1 = file.name.split('.').slice(0, -1)[0]
    const extension = file.name.split('.').slice(-1)[0]
    const filename = `${_kebabCase(name1)}.${extension}`
    const fileRef = storageRef1.child(`${projectId1}/${uuidv1()}-${filename}`)

    try {
      const compressedFile = await imageCompression(file, options)
      const snapshot = await fileRef.put(compressedFile)
      const downloadURL = await snapshot.ref.getDownloadURL()
      return downloadURL
    } catch (error) {
      return ''
    }
  }

  const handleUpload = async (event) => {
    const file = event?.target?.files[0]
    setLoading(true)
    try {
      const response = await uploadImage(projectId, file)
      setImage(response)
    } catch (error) {
      setImage('')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <AlertBar />
      <Typography variant="h4" className={classes.typography}>Tags</Typography>
      <Card className={classes.card}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item sm={4} md={5}>
            <Box className={classes.centerBox}>
              <Typography
                variant="h6"
                className={classes.typography}
              >
                Add Tags
              </Typography>
              <TextFeild
                fullWidth
                size="small"
                label="Name*"
                value={name}
                error={duplicate}
                variant="outlined"
                className={classes.textfield}
                // inputProps={{ maxLength: 20 }}
                FormHelperTextProps={{ className: classes.helpertext }}
                onChange={(event) => handleTextField(event.target.value, 'Name')}
                // helperText={duplicate ? 'Already exists' : `${name.length}/20 Characters`}
              />
              <TextFeild
                rows={3}
                multiline
                fullWidth
                value={desc}
                size="small"
                label="Description (Optional)"
                variant="outlined"
                className={classes.textfield}
                // inputProps={{ maxLength: 50 }}
                // helperText={`${desc.length}/50 Characters`}
                FormHelperTextProps={{ className: classes.helpertext }}
                onChange={(event) => handleTextField(event.target.value, 'Description')}
              />
              <Box display="flex" justifyContent="space-between">
                <CardActions style={{ padding: 0 }}>
                  <label htmlFor={`${projectId}`}>
                    <input
                      disabled={image}
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      id={_camelCase(`${projectId}`)}
                      name={_camelCase(`${projectId}`)}
                      onChange={handleUpload}
                    />
                    <Button
                      color="primary"
                      htmlFor={`${projectId}`}
                      variant="contained"
                      component="div"
                      disabled={loading || image}
                      endIcon={<CloudUploadIcon />}
                    >
                      Upload New Picture
                    </Button>
                  </label>
                </CardActions>
                {image && (
                  <IconButton
                    disabled={loading}
                    onClick={() => setImage('')}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              {image && (
                <Box mt={2}>
                  <CardMedia component="img" src={image} />
                </Box>
              )}
              <Box mt={3}>
                <Button
                  size="small"
                  color="primary"
                  disabled={!name || loading}
                  variant="contained"
                  onClick={handleAddCategory}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Grid>
          <AllCategories
            projectId={projectId}
            categories={category}
            setDelCategory={setDelCategory}
            loading={loading}
            setLoading={setLoading}
            openEditDialog={openEditDialog}
            setOpenEditDialog={setOpenEditDialog}
            handleEditCategory={handleEditCategory}
            handleDelete={deleteCategory}
            duplicateEdit={duplicateEdit}
            setDuplicateEdit={setDuplicateEdit}
          />
        </Grid>
      </Card>
    </Box>
  )
}

TagList.propTypes = {
  computedMatch: PropTypes.shape({}),
}
TagList.defaultProps = {
  computedMatch: {},
}
