import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import _map from 'lodash/map'
import _size from 'lodash/size'

import Header from './Header'
import LoadingRow from './LoadingRow'
import Row from './Row'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: 4,
    overflow: 'hidden',
  },
}))

function EnhancedTable(props) {
  const classes = useStyles()
  const {
    columns,
    rows,
    actions,
    isLoading,
    handleRequestSort,
    orderBy,
    order,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    numberOfDocs,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props

  const loadingMock = [...Array(10).keys()]

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table component="div">
          <Header
            classes={classes}
            headCells={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            isLoading={false}
          />
          <TableBody component="div">
            {isLoading ? (
              <>
                {
                  _map(loadingMock, (row, i) => (
                    <LoadingRow
                      columns={columns}
                      key={i}
                      haveAction={_size(actions) > 0}
                    />
                  ))
                }
              </>
            ) : (
              <>
                {
                  _size(rows) > 0 ? (
                    _map(rows, (row, index) => (
                      <Row
                        key={index}
                        uid={row.uid}
                        columns={columns}
                        row={row}
                        actions={actions}
                        rowIndex={index}
                      />
                    ))
                  ) : (
                    <TableRow style={{ height: 80 }} component="div">
                      <TableCell
                        colSpan={_size(columns) + 1}
                        style={{ textAlign: 'center' }}
                        component="div"
                      >
                        <Typography variant="body1">
                          No data available in table.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(!isLoading && _size(rows) > 0) && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={numberOfDocs}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  handleRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.any),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  numberOfDocs: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
}

EnhancedTable.defaultProps = {
  isLoading: true,
  rowsPerPageOptions: [50, 100, 250],
}

export default EnhancedTable
