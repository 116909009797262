import firebase from 'firebase/app'
import _camelCase from 'lodash/camelCase'
import { clear } from './alert.actions'
import * as alertAction from './alert.actions'

const _ = require('lodash')

export const LOAD_NEW_FORM = 'LOAD_NEW_FORM'
export const SET_NEW_FORM = 'SET_NEW_FORM'
export const SET_NEW_DATA_FORM = 'SET_NEW_DATA_FORM'
export const ADD_CHANGE = 'ADD_CHANGE'
export const SUBMIT_DATA = 'SUBMIT_DATA'
export const CHANGE_STRING_TYPE = 'CHANGE_STRING_TYPE'
export const CHANGE_NUMBER_TYPE = 'CHANGE_NUMBER_TYPE'
export const CHANGE_OBJECT_TYPE = 'CHANGE_OBJECT_TYPE'
export const CHANGE_ARRAY_TYPE = 'CHANGE_ARRAY_TYPE'
export const CHANGE_BOOLEAN_TYPE = 'CHANGE_BOOLEAN_TYPE'
export const CHANGE_OBJECT_LNG_TYPE = 'CHANGE_OBJECT_LNG_TYPE'
export const CLEAR_FORM = 'CLEAR_FORM'
export const GET_EDIT_DATA = 'GET_EDIT_DATA'

export function loadNewForm() {
  return {
    type: LOAD_NEW_FORM,
  }
}

export function changeArrayType(selectedPage, objectPage, newValue) {
  return {
    type: CHANGE_ARRAY_TYPE,
    payload: {
      selectedPage,
      objectPage,
      newValue,
    },
  }
}

export function getForm(selectedId, selectedPage) {
  let formPage
  return (dispatch) => {
    dispatch(loadNewForm())
    firebase.firestore().collection('projects').doc(selectedId).get()
      .then((data) => {
        const selectedData = data.data().schema
        _.map(selectedData.page, (page, i) => {
          if (_camelCase(selectedData.page[i].name) === selectedPage) {
            _.map(selectedData.page[i].fields, (fields, j) => {
              const selectedDataPage = selectedData.page[i].fields[j].label
              selectedData.page[i].fields[j].label = _camelCase(selectedDataPage)
            })
            formPage = page.fields
            dispatch({ type: 'SET_NEW_FORM', payload: { formPage } })
          }
        })
      })
  }
}

export function getList(selectedPage) {
  let formPage
  return (dispatch) => {
    dispatch(loadNewForm())
    firebase.firestore().collection('projects').doc(selectedPage).get()
      .then((data) => {
        formPage = data.data()
        dispatch({ type: 'SET_NEW_FORM', payload: { formPage } })
      })
  }
}

export function getListData(selectedPage, listId, uid) {
  return (dispatch) => {
    dispatch(loadNewForm())
    firebase.firestore().collection('data').doc(selectedPage).collection(listId)
      .doc(uid)
      .get()
      .then((result) => {
        let data = []
        try {
          data = result.data()
        } catch (TypeError) {
          result.forEach((dataPage) => {
            data.push(dataPage.data())
            dispatch({ type: 'SET_NEW_FORM', payload: { data } })
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function getDataForm(selectedProject) {
  return (dispatch) => {
    dispatch(loadNewForm())
    firebase.firestore().collection('data').doc(selectedProject).get()
      .then((data) => {
        const formData = data.data()?.pages
        dispatch({
          type: 'SET_NEW_DATA_FORM',
          payload: {
            formData,
          },
        })
      })
  }
}

export function setNewDataForm(formData) {
  return {
    type: SET_NEW_DATA_FORM,
    payload: { formData },
  }
}

export function changeStringType(selectedPage, objectPage, newValue) {
  return {
    type: CHANGE_STRING_TYPE,
    payload: {
      selectedPage,
      objectPage,
      newValue,
    },
  }
}

export function changeNumberType(selectedPage, objectPage, newValue) {
  return {
    type: CHANGE_NUMBER_TYPE,
    payload: {
      selectedPage,
      objectPage,
      newValue,
    },
  }
}

export function changeBooleanType(selectedPage, objectPage, newValue) {
  return {
    type: CHANGE_BOOLEAN_TYPE,
    payload: {
      selectedPage,
      objectPage,
      newValue,
    },
  }
}

export function changeObjectType(selectedPage, objectPage, newValue) {
  return {
    type: CHANGE_OBJECT_TYPE,
    payload: {
      selectedPage,
      objectPage,
      newValue,
    },
  }
}

export function submitData(submData, projectId, cb) {
  return () => {
    firebase.firestore()
      .collection('data')
      .doc(projectId)
      .set({
        pages: submData,
      }, { merge: true })
      .then(() => {
        cb('success', 'Data has been saved successfully.')
      })
      .catch(() => {
        cb('error', 'Data has been saved failed.')
      })
  }
}

export function submitListData(inputValue, projectId, listId, isPublic, history) {
  return (dispatch) => {
    const dataCollection = isPublic ? 'publicData' : 'data'
    const defaultUri = _.kebabCase(_.get(inputValue, 'title.en')?.replace(/[0-9]/g, ''))

    // get all uri in jorunal lists
    firebase.firestore().collection('data').doc(projectId).collection(listId)
      .get()
      .then((snapshots) => {
        const journalList = []
        snapshots.forEach((doc) => {
          let dataObj = {}
          dataObj = doc.data()
          dataObj.uid = doc.id
          journalList.push(dataObj)
        })

        // recursive
        let i = 2
        let newTarget
        function recurse(allUri, targetUri) {
          if (_.includes(allUri, targetUri)) {
            newTarget = _.kebabCase(defaultUri + i)
            i += 1
            recurse(allUri, newTarget)
          }
        }

        if (!_.isEmpty(journalList)) {
          const dupJournalsTitle = _.filter(journalList,
            (journal) => _.includes(_.get(journal, 'uri'), defaultUri))
          const dupUri = _.map(dupJournalsTitle, (item) => _.get(item, 'uri'))

          recurse(dupUri, defaultUri)
        }

        const uri = newTarget || defaultUri

        firebase.firestore()
          .collection(dataCollection)
          .doc(_camelCase(projectId))
          .collection(_camelCase(listId))
          .add({
            ...inputValue,
            uri,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            dispatch(alertAction.showMessage('success', 'Data has been saved successfully.'))
            setTimeout(() => {
              history.replace(`/project/${projectId}/list/${listId}`)
              dispatch(clear())
            }, 1000)
          })
          .catch((err) => {
            dispatch(alertAction.showMessage('error', err.message))
          })
      })
      .catch((err) => {
        dispatch(alertAction.showMessage('error', err.message))
      })
  }
}

export function editListData(inputValue, projectId, listId, isPublic, uid, history) {
  return (dispatch) => {
    const collectionName = isPublic ? 'publicData' : 'data'
    firebase.firestore()
      .collection(collectionName)
      .doc(_camelCase(projectId))
      .collection(_camelCase(listId))
      .doc(uid)
      .set({
        ...inputValue,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true })
      .then(() => {
        dispatch(alertAction.showMessage('success', 'Data has been saved successfully.'))
        setTimeout(() => {
          history.replace(`/project/${projectId}/list/${listId}`)
          dispatch(clear())
        }, 1000)
      })
      .catch((err) => {
        dispatch(alertAction.showMessage('error', err.message))
      })
  }
}

export function changeValueIteratorObject(selectedPage, objectPage, newValue) {
  return {
    type: CHANGE_OBJECT_LNG_TYPE,
    payload: {
      selectedPage,
      objectPage,
      newValue,
    },
  }
}

export function clearForm() {
  return {
    type: CLEAR_FORM,
  }
}

export function getEditData(projectId, listId, uid, isPublic) {
  return async (dispatch) => {
    dispatch(loadNewForm())
    try {
      const collectionName = isPublic ? 'publicData' : 'data'
      const result = await firebase.firestore().collection(collectionName)
        .doc(projectId).collection(listId)
        .doc(uid)
        .get()
      dispatch({
        type: 'SET_NEW_DATA_FORM',
        payload: { formData: { [listId]: result.data() } },
      })
    } catch (error) {
      dispatch({ type: 'ERROR_MESSAGE', payload: { errorMessage: 'not fount edit data' } })
    }
  }
}

export function getShemaList(projectId, selectedPage) {
  let formPage
  return async (dispatch) => {
    dispatch(loadNewForm())
    try {
      const result = await firebase.firestore().collection('projects').doc(projectId).get()
      const selectedData = result.data().schema
      _.map(selectedData.list, (list, i) => {
        formPage = list
        if (_camelCase(selectedData.list[i].name) === selectedPage) {
          _.map(formPage.fields, (fields, j) => {
            formPage.fields[j].label = _camelCase(fields.label)
          })
          dispatch({ type: 'SET_NEW_FORM', payload: { formPage: formPage.fields, isPublic: formPage.isPublic } })
        }
      })
    } catch (error) {
      dispatch({ type: 'ERROR_MESSAGE', payload: { errorMessage: 'not fount shema list' } })
    }
  }
}
