import {
  SETDATA_FORM, SETEDIT_FORM, RESET_FORM, LOADING, STORE_DATA,
  LIST_DATA, ARR_STORE_DATA, DATA_DOC_ID,
} from '../actions/createform.action'

const initialState = {
  type: [
    {
      type: 'page',
      pl: [
        {
          name: '',
          isPublic: false,
          isShowTags: false,
          fields: [],
        },
      ],
    },
  ],
  isUpdate: false,
  index: null,
  loading: false,
  listData: [],
  dataDocId: [],
  storeData: [],
  arrObjStore: [],
}

export default function createformreducer(state = initialState, action) {
  switch (action.type) {
    case SETDATA_FORM:
      return {
        ...state,
        type: action.payload.data,
      }
    case SETEDIT_FORM:
      return {
        ...state,
        type: [{ type: action.payload.type, pl: [action.payload.data] }],
        isUpdate: true,
        index: action.payload.index,
      }
    case RESET_FORM:
      return {
        ...state,
        type: [
          {
            type: 'page',
            pl: [
              {
                name: '',
                isPublic: false,
                isShowTags: false,
                fields: [],
              },
            ],
          },
        ],
        isUpdate: false,
        index: null,
        loading: false,
        listData: [],
        dataDocId: [],
        storeData: [],
        arrObjStore: [],
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }
    case STORE_DATA:
      return {
        ...state,
        storeData: action.payload.data,
      }
    case LIST_DATA:
      return {
        ...state,
        listData: action.payload.data,
      }
    case ARR_STORE_DATA:
      return {
        ...state,
        arrObjStore: action.payload.data,
      }
    case DATA_DOC_ID:
      return {
        ...state,
        dataDocId: action.payload.data,
      }
    default:
      return state
  }
}
